export const getEuroFormat = (price, withUnit = true) => {
    if (price === null || price === undefined) return ''
    let priceFormated = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Math.round(price) / 100)
    priceFormated = priceFormated.replaceAll('\u202F', '\u00a0')
    if (!withUnit) {
        priceFormated = priceFormated.replaceAll('€', '')
    }
    return priceFormated
}

export const getPercentFormat = (value) => {
    let valueFormated = parseFloat((value * 100).toFixed(4)) + '%'
    return valueFormated
}

export const getEuroFormatFromEuro = (price, withUnit = true) => {
    let priceFormated = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Math.round(price * 100) / 100)
    priceFormated = priceFormated.replaceAll('\u202F', '\u00a0')
    if (!withUnit) {
        priceFormated = priceFormated.replaceAll('€', '')
    }
    return priceFormated
}
export const getEuroFormatFromCurrency = (price, currency) => {
    currency = currency?? 'EUR'
    let priceFormated = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: currency }).format(Math.round(price))
    priceFormated = priceFormated.replaceAll('\u202F', '\u00a0')
    return priceFormated
}
