import {formatFromApi as serviceFormatFromApi, formatToApi as serviceFormatToApi} from '@/utils/ServiceUtil'

export const formatFromApi = (declarationFacturationI) => {
    let declarationFacturation = JSON.parse(JSON.stringify(declarationFacturationI))
    if (declarationFacturation?.services?.length > 0) {
        declarationFacturation.services = declarationFacturation.services.map((service) => serviceFormatFromApi(service))
    }
    return declarationFacturation
}

export const formatToApi = (declarationFacturationI) => {
    let declarationFacturation = JSON.parse(JSON.stringify(declarationFacturationI))
    if (declarationFacturation?.services?.length > 0) {
        declarationFacturation.services = declarationFacturation.services.map((service) => serviceFormatToApi(service))
    }
    return declarationFacturation
}
