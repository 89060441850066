import axios from '@/plugins/axios'
import { formatFromApi, formatToApi } from '@/utils/NDFUtil'

export default {
  namespaced: true,

  state: {
    ndf: undefined,
    ndfs: []
  },
  getters: {
    getNDF (state) {
      return state.ndf
    },
    getNDFS (state) {
      return state.ndfs
    }
  },
  mutations: {
    SET_NDF(state, value) {
      state.ndf = value
    },
    SET_NDFS(state, value) {
      state.ndfs = value

    }
  },
  actions: {
    // eslint-disable-next-line
    async getAllNDFS({ commit }, { range }) {
      return new Promise((resolve, reject) => {
        axios.api.get(`/ndf?range=${JSON.stringify(range)}`).then(res => {
          let results = res.data.map((ndf) => formatFromApi(ndf))
          commit('SET_NDFS', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getNdfToValidate ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.api.get('/ndf/to-validate').then(res => {
          let results = res.data.map((ndf) => formatFromApi(ndf))
          commit('SET_NDFS', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getAllNDFSByIndependent({ commit }, independentId) {
      return new Promise((resolve, reject) => {
        axios.api.get('/ndf/independent/' + independentId).then(res => {
          let results = res.data.map((ndf) => formatFromApi(ndf))
          commit('SET_NDFS', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createNDF({ commit }, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.post('/ndf', data).then(res => {
          let results = formatFromApi(res.data)
          commit('SET_NDF', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateNDF({ commit }, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.put('/ndf/' + data.id, data).then(res => {
          let results = formatFromApi(res.data)
          commit('SET_NDF', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async validateNDF({ commit }, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.post('/ndf/' + data.id + '/validate', data).then(res => {
          let results = formatFromApi(res.data)
          commit('SET_NDF', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    }
  },
}
