export const formatFromApi = (clientI) => {
    let client = JSON.parse(JSON.stringify(clientI))

    if (client.capital) {
        client.capital = client.capital / 100
    }

    return client
}

export const formatToApi = (clientI) => {
    let client = JSON.parse(JSON.stringify(clientI))
    if (client.capital) {
        client.capital = Math.round(client.capital * 100)
    }

    return client
}
