import axios from '@/plugins/axios'
import { formatFromApi, formatToApi } from '@/utils/BankaccountUtil'

export default {
  namespaced: true,

  state: {
    bankaccount: [],
    bankaccounts: []
  },
  getters: {
    getBankaccounts (state) {
      return state.bankaccounts
    },
    getBankaccount (state) {
      return state.bankaccount
    },
  },
  mutations: {
    SET_BANKACCOUNTS(state, value) {
      state.bankaccounts = value
    },
    SET_BANKACCOUNT(state, value) {
      state.bankaccount = value
    }
  },
  actions: {
    // eslint-disable-next-line
    async getAllBankaccountsByIndependent({ commit }, independentId) {
      return new Promise((resolve, reject) => {
        axios.api.get('/bankAccount/independent/' + independentId).then(res => {
          let results = res.data.map((ndf) => formatFromApi(ndf))
          commit('SET_BANKACCOUNTS', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getAllBankaccountsByClient({ commit }, clientId) {
      return new Promise((resolve, reject) => {
        axios.api.get('/bankAccount/client/' + clientId).then(res => {
          let results = res.data.map((ndf) => formatFromApi(ndf))
          commit('SET_BANKACCOUNTS', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getAllBankaccounts({ commit }) {
      return new Promise((resolve, reject) => {
        axios.api.get('/bankAccount').then(res => {
          let results = res.data.map((ndf) => formatFromApi(ndf))
          commit('SET_BANKACCOUNTS', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getAllSettingsBankaccounts({ commit }) {
      return new Promise((resolve, reject) => {
        axios.api.get('/bankAccount/settings/all').then(res => {
          let results = res.data.map((ndf) => formatFromApi(ndf))
          commit('SET_BANKACCOUNTS', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createBankaccount({ commit }, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.post('/bankAccount', data).then(res => {
          let results = formatFromApi(res.data)
          commit('SET_BANKACCOUNT', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteBankaccount(_, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.delete('/bankAccount/' + data.id).then(res => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateBankaccount({ commit }, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.put('/bankAccount/' + data.id, data).then(res => {
          let results = formatFromApi(res.data)
          commit('SET_BANKACCOUNT', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
