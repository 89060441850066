<template>
  <div id="app-sidebar-10" class="layout-sidebar">
    <div class="flex h-full">
      <div class="flex flex-column h-full flex-shrink-0 select-none" @mouseenter="hover=true" @mouseleave="hover=false">
        <div class="overflow-y-auto">
          <ul class="list-none py-3 px-0 m-0">
            <li class="mb-2 flex justify-content-start align-items-center flex-row cursor-pointer" v-for="(item, index) in itemMenu" :key="index" @click="selectMenu(item)">
              <a v-tooltip.right="{ value:item.label }" class="flex align-items-center cursor-pointer py-3 mx-0 justify-content-center hover:bg-white-alpha-50 text-indigo-100 hover:text-indigo-50 transition-duration-150 transition-colors p-ripple triangle-ripple"
                 :class="{'bg-gray-50 hover:bg-gray-50': menuSelected?.name === item.root}" style="width: 75px; height: 75px">
                <span :class="{'text-primary': menuSelected?.name === item.root}"><i :class="item.icon"></i></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <SidebarContent />
    </div>
    <div class="flex h-full fixed" v-if="hover" style="left: 75px">
      <div class="flex flex-column h-full bg-indigo-900 flex-shrink-0 select-none" @mouseenter="hover=true" @mouseleave="hover=false">
        <div class="flex align-items-center justify-content-center flex-shrink-0" style="height:60px">
        </div>
        <div class="absolute bottom-0">
          <div class="mb-2 flex justify-content-start align-items-center flex-row" style="width: calc(100% - 1.5rem)">
            <a style="width: 0px; height: 50px"></a>
            <span v-if="hover" class="text-white px-2 cursor-pointer uppercase bold" @click="logout"> déconnexion </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationItems from '@/config/browser-navigation-items'
import {mapState, mapGetters} from 'vuex'
import NavigationItemsIndependent from "@/config/browser-navigation-items-independent";
import SidebarContent from '@/components/SidebarContent'
import {hasAccess} from "@/utils/Misc";
import logoutMixin from "@/mixins/logoutMixin";

export default {
  mixins: [logoutMixin],
  components: {
    SidebarContent,
  },
  computed: {
    ...mapState({
      // menuSelected: state => state.sidebar.selected,
      constants: state => state.constants,
      // activeItem: state => state.sidebar.activeItem
    }),
    ...mapGetters({
      me: 'auth/getMe'
    }),
    menuSelected () {
      return this.$route.matched[0]
    },
    itemMenu () {
      let items
      if (this.me?.role?.name === this.constants.ROLES.INDEPENDENT) {
        items = NavigationItemsIndependent
      } else {
        items = NavigationItems
      }
      const roleItems = []
      items.forEach(i => {
        if (hasAccess(i.to, false)) roleItems.push(i)
      })
      // check access to regul
      return roleItems
    }
  },
  data () {
    return {
      currentMenu: null,
      hover: false
    }
  },
  methods: {
    async selectMenu (item) {
      if (!item.name) return
      if (item.to) {
        await this.$store.dispatch('sidebar/changeMenu', item.name)
        this.$router.push({ name: item.to })
      }
    }
  }
}
</script>

<style scoped>
.svg-inline--fa {
  font-size: 1.6em !important;
 }
.triangle-ripple {
  /*clip-path: polygon(0% 0%, 100% 50%, 0% 100%);*/
  clip-path: circle(40%);
}
</style>
