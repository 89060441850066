import axios from '@/plugins/axios'
import { formatFromApi } from '@/utils/DirectusUsersUtil'

export default {
  namespaced: true,

  state: {
    users: [],
    roles: []
  },
  mutations: {
    SET_USERS(state, value) {
      state.users = value
    },
    SET_ROLES(state, value) {
      state.roles = value
    }
  },
  getters: {
    GET_USERS(state) {
      return state.users
    },
    GET_ROLES(state) {
      return state.roles
    }
  },
  actions: {
    // eslint-disable-next-line
    async getAllUsers({ commit }) {
      return new Promise((resolve, reject) => {
        axios.api.get('/directus-users').then(res => {
          let results = res.data.map((c) => formatFromApi(c))
          commit('SET_USERS', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getAllRoles({ commit }) {
      return new Promise((resolve, reject) => {
        axios.api.get('/directus-users/roles').then(res => {
          let results = res.data
          commit('SET_ROLES', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createUser(_, data) {
      return new Promise((resolve, reject) => {
        axios.api.post('/directus-users', data).then(res => {
          let results = res.data
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateUser(_, data) {
      return new Promise((resolve, reject) => {
        axios.api.put('/directus-users', data).then(res => {
          let results = res.data
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteUser(_, data) {
      return new Promise((resolve, reject) => {
        axios.api.delete('/directus-users/' + data.id).then(res => {
          let results = res.data
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  }
}
