// Vue
// import Vue from "vue";
import { createI18n } from "vue3-i18n";

// Data
import languages from "@/i18n/languages.json";
import fr from "@/lang/fr";
import en from "@/lang/en";
import { app } from "@/main";

export function initI18n(lang = null) {
    const globalLanguages = { fr, en };

    const hasDocument = typeof document !== "undefined";
    const loadedLanguages = ["fr", 'en'];
    const fallbackLocale = 'fr'

    let locale = fallbackLocale;
    if (lang !== null && lang !== "fr") {
        locale = lang;
        loadLanguageAsync(lang);
    } else if (hasDocument) {
        locale = document.documentElement.lang;
    }

    if (locale === null || locale === '') {
        locale = fallbackLocale
    }

    const i18n = createI18n({
        fallbackLocale,
        locale,
        messages: { fr, en },
        silentFallbackWarn: true
    });

    function setI18nLanguage(lang) {
        i18n.locale = lang;
        return lang;
    }

    // eslint-disable-next-line no-unused-vars
    function loadLanguageAsync(lang) {
        lang = (localStorage.getItem('lang') && loadedLanguages.includes(localStorage.getItem('lang'))) ?
            localStorage.getItem('lang') : lang;
        if (!loadedLanguages.includes(lang)) {
            const { locale } =
            languages.find(l => lang === l.alternate || lang === l.locale) || {};

            if (!locale) return Promise.reject(new Error("Language not found"));

            return import(
                /* webpackChunkName: "lang-[request]" */
                `@/lang/${locale}`
                )
                .then(msgs => {
                    loadedLanguages.push(lang);
                    globalLanguages[lang] = msgs.default;
                    app.$i18n.setLocale(lang);
                    return Promise.resolve(setI18nLanguage(lang));
                })
                .catch(err => {
                    throw err;
                });
        }
        app.$i18n.setLocale(lang);
        return Promise.resolve(setI18nLanguage(lang));
    }

    return {
        i18n,
        loadLanguageAsync
    };
}
