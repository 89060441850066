import {formatPhoneNumber} from "@/utils/Misc";

export const formatFromApi = (contactI) => {
    let contact = JSON.parse(JSON.stringify(contactI))

    let contactPhone = contact.phone
    if (contactPhone) {
        contact.phone = formatPhoneNumber(contactPhone)
    }

    return contact
}

export const formatToApi = (contactI) => {
    let contact = JSON.parse(JSON.stringify(contactI))

    let contactPhone = contact.phone
    if (contactPhone) {
        contact.phone = contactPhone.replaceAll('-', '')
        if (contact.phone.length !== 10) {
            contact.phone = undefined
        }
    }

    return contact
}
