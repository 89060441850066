import {setDateWithoutHours} from "@/utils/Date";

export const formatFromApi = (ndfI) => {
    let ndf = JSON.parse(JSON.stringify(ndfI))
    ndf.date = new Date(ndf.date)
    ndf.dateValidation = ndf.dateValidation != null ? new Date(ndf.dateValidation) : null
    ndf.dateRemboursement = ndf.dateRemboursement != null ? new Date(ndf.dateRemboursement) : null
    ndf.month = ndf.date.getMonth() + 1
    ndf.year = ndf.date.getFullYear()
    ndf.remboursement = ndf.adminStatus === 'refunded'
    ndf.combinedStatus = ndf.independentStatus === 'draft' || ndf.adminStatus === 'validate' ? ndf.independentStatus : ndf.adminStatus
    if (ndf.independent) {
        ndf.independent.cagnotte = ndf?.independent?.previsionnel
    }
    if (ndf.depenses.length !== 0) {
        ndf.nbDepenses = ndf.depenses.length
        ndf.ttc = ndf.depenses.reduce((total, arg) => total + arg.ttc, 0)
        ndf.depenses.forEach((depense) => {
            depense.date = new Date(depense.date)
            depense.ttc = depense.ttc / 100
            depense.ht = depense.ht / 100
            depense.tva = depense.tva / 100
            depense.item?.forEach((sousdepense) => {
                sousdepense.ttc = sousdepense.ttc / 100
                sousdepense.ht = sousdepense.ht / 100
                sousdepense.tva = sousdepense.tva / 100
            })
        })
    } else if (ndf.ikm.length !== 0) {
        ndf.nbDepenses = ndf.ikm.length
        ndf.ttc = ndf.ikm.reduce((total, arg) => total + arg.price, 0)
        ndf.ikm.forEach((ik) => {
            ik.dates = ik.dates.map(d => new Date(d))
            ik.distanceTotale = ik.distanceTrajet * ik.nombreTrajets
            ik.price = ik.price / 100
        })
    }
    return ndf
}

export const formatToApi = (ndfI) => {
    let ndf = JSON.parse(JSON.stringify(ndfI))
    if (ndf.date) {
        ndf.date = setDateWithoutHours(ndf.date)
    }
    if (ndf.dateValidation) {
        ndf.dateValidation = ndf.dateValidation != null ? setDateWithoutHours(ndf.dateValidation) : null
    }
    if (ndf.dateRemboursement) {
        ndf.dateRemboursement = ndf.dateRemboursement != null ? setDateWithoutHours(ndf.dateRemboursement) : null
    }

    if (ndf?.depenses && ndf?.depenses?.length !== 0) {
        ndf?.depenses.forEach((depense) => {
            depense.date = setDateWithoutHours(depense.date)
        })
    } else if (ndf?.ikm && ndf?.ikm?.length !== 0) {
        ndf.ikm.forEach((ik) => {
            if (ik?.dates) {
                ik.dates = ik.dates.map(d => setDateWithoutHours(d))
            }
        })
    }
    return ndf
}
