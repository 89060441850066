<template>
  <div class="flex flex-column">
    <div class="flex flex-row align-items-center justify-content-center" :class="(v$.localNegotiatedRateValue.effectiveDate.$dirty && v$.localNegotiatedRateValue.effectiveDate.$errors.length)
        || (v$.localNegotiatedRateValue.costOnSite.$dirty && v$.localNegotiatedRateValue.costOnSite.$errors.length) || (v$.localNegotiatedRateValue.costOnRemote.$dirty && v$.localNegotiatedRateValue.costOnRemote.$errors.length)
      ? 'mb-1' : ''">
      <Calendar ref="calendarRateInput" id="effectiveDate" v-model="v$.localNegotiatedRateValue.effectiveDate.$model"
                :showIcon="true" class="mr-2 w-3" :date-format="getDateFormat" :view="getDateView"
                :class="{'p-invalid':v$.localNegotiatedRateValue.effectiveDate.$errors.length}"
                :disabled="disableEffectiveDate || disableNegotiatedRateInputs" :min-date="getMinDate"
                :disabled-dates="disabledDates"
                @blur="updateNegotiatedRateValue"/>

      <InputNumber id="costOnSite" v-model="v$.localNegotiatedRateValue.costOnSite.$model" class="mr-2 w-3" :min="0"
                   locale="fr-FR" mode="currency" currency="EUR" highlightOnFocus
                   :class="{'p-invalid':v$.localNegotiatedRateValue.costOnSite.$errors.length}"
                   :disabled="disableNegotiatedRateInputs"
                   @blur="updateNegotiatedRateValue"/>

      <InputNumber v-if="mission?.jsonCost?.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE || mission?.jsonCost?.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE"
                   id="costOnRemote" v-model="v$.localNegotiatedRateValue.costOnRemote.$model" class="mr-2 w-3" :min="0"
                   locale="fr-FR" mode="currency" currency="EUR" highlightOnFocus
                   :class="{'p-invalid':v$.localNegotiatedRateValue.costOnRemote.$errors.length}" :disabled="disableNegotiatedRateInputs"
                   @blur="updateNegotiatedRateValue"/>

      <i class="pi pi-times-circle cursor-pointer text-red-300 w-1"
         style="font-size: 1.5rem" :style="disableEffectiveDate || disableNegotiatedRateInputs ? 'visibility: hidden' : ''"
         @click="removeNegotiatedRateValue"/>
    </div>
    <div
        v-if="v$.localNegotiatedRateValue.effectiveDate.$errors.length || v$.localNegotiatedRateValue.costOnSite.$errors.length || v$.localNegotiatedRateValue.costOnRemote.$errors.length"
        class="mb-1">
        <span
            v-for="(error, index) of [...v$.localNegotiatedRateValue.effectiveDate.$errors, ...v$.localNegotiatedRateValue.costOnSite.$errors, ...v$.localNegotiatedRateValue.costOnRemote.$errors]"
            :key="index">
          <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
        </span>
    </div>
  </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {mapState} from "vuex";
import {helpers, required} from "@vuelidate/validators";
import {invalidMonthFormat} from "@/utils/NegotiatedRateValueUtils";

export default {
  name: "NegotiatedRateInputs",
  setup: () => ({v$: useVuelidate()}),
  emits: ['deleteNegotiatedRateValue', 'updateNegotiatedRateValue', 'addInvalidMonth', 'deleteInvalidMonth'],
  props: {
    mission: {
      type: Object,
      required: true
    },
    negotiatedRateValue: {
      type: Object,
      required: true
    },
    invalidMonths: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      localNegotiatedRateValue: this.negotiatedRateValue
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
      service: state => state.service,
    }),
    isMissionMonthlyFixedPrice () {
      return this.mission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE
          && this.mission.jsonCost.forfaitFacturationMode === this.constants.INVOICE_TYPE.MONTHLY
    },
    getDateView () {
      if (this.isMissionMonthlyFixedPrice) {
        return 'month'
      }
      return 'date'
    },
    getDateFormat () {
      if (this.isMissionMonthlyFixedPrice) {
        return 'mm/yy'
      }
      return 'dd/mm/yy'
    },
    disableEffectiveDate () {
      if (!this.localNegotiatedRateValue.effectiveDate || !this.mission.jsonMission.startingDate) return false
      const missionStartingDate = new Date(this.mission.jsonMission.startingDate).toISOString()
      const formattedEffectiveDate = new Date(this.localNegotiatedRateValue.effectiveDate).toISOString()
      return formattedEffectiveDate === missionStartingDate || !!this.localNegotiatedRateValue.service_id;
    },
    disableNegotiatedRateInputs () {
      if (!this.service.services || !this.localNegotiatedRateValue?.effectiveDate
          || !this.mission.jsonMission.startingDate || !this.mission.id) return false
      // todo: is it superior or equal or just equal ?
      const foundValidatedServiceAtEffectiveDate = this.service.services.findIndex(service =>
          service?.month >= new Date(this.localNegotiatedRateValue?.effectiveDate).getMonth() + 1
          && service?.year >= new Date(this.localNegotiatedRateValue?.effectiveDate).getFullYear())
      return foundValidatedServiceAtEffectiveDate !== -1 || !!this.localNegotiatedRateValue.service_id;
    },
    getMinDate () {
      if (!this.mission.jsonMission.startingDate) return null
      const missionStartingDateISO = new Date(JSON.parse(JSON.stringify(this.mission.jsonMission.startingDate))).toISOString()
      const currentDateISO = new Date().toISOString()
      const missionStartingDate = new Date(missionStartingDateISO)
      const currentDate = new Date(currentDateISO)

      let minDate
      // take the further date to define the min date
      if (currentDateISO < missionStartingDateISO || (currentDate.getMonth() === missionStartingDate.getMonth()
          && currentDate.getFullYear() === missionStartingDate.getFullYear())) {
        minDate = missionStartingDate
      } else {
        // take the last day of the previous month because we add +1 to the Date in the end
        const date = new Date()
        date.setDate(0)
        date.setMonth(currentDate.getMonth() <= 2 ? 11 - (Math.abs(currentDate.getMonth() - 2)) : currentDate.getMonth() - 2)
        date.setFullYear(currentDate.getMonth() <= 2 ? currentDate.getFullYear() - 1 : currentDate.getFullYear())
        minDate = date
      }
      minDate.setUTCDate(minDate.getUTCDate() + 1);
      return minDate
    },
    disabledDates () {
      // disable all the existing date to prevent having multiple negotiatedRate on the same date
      if (!this.mission.jsonCost?.negotiatedRateValues || this.mission.jsonCost?.negotiatedRateValues?.length === 0) return []
      return this.mission.jsonCost.negotiatedRateValues
          .filter((negotiatedRateValue) => negotiatedRateValue.unit === this.mission.jsonCost.negotiatedRateUnit)
          .map((negotiatedRateValue) => new Date(negotiatedRateValue.effectiveDate))
    }
  },
  validations() {
    // const costOnRemoteErrorMessage = 'Tarif en télétravail'
    const costOnSiteErrorMessage = this.mission?.jsonCost?.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE ? 'Tarif de la mission' : 'Tarif sur site'
    const isCostRemoteExist = this.mission?.jsonCost?.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE || this.mission?.jsonCost?.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE
    const atLeastOneCostIsValid = this.localNegotiatedRateValue.costOnSite !== null || this.localNegotiatedRateValue.costOnRemote !== null
    const form = {
      localNegotiatedRateValue: {
        effectiveDate: {required: helpers.withMessage('Date de mise en effet obligatoire', required)},
        costOnSite: {},
        costOnRemote: {}
      }
    }

    if ((isCostRemoteExist && !atLeastOneCostIsValid) || !isCostRemoteExist) {
      form.localNegotiatedRateValue.costOnSite = {required: helpers.withMessage( isCostRemoteExist ? 'Il est obligatoire de remplir au moins un des deux tarifs' : costOnSiteErrorMessage + ' obligatoire', required)}
    }

    if (this.isMissionMonthlyFixedPrice) {
      form.localNegotiatedRateValue.effectiveDate = {
        required: helpers.withMessage('Date de mise en effet obligatoire', required),
        isValidMonth: helpers.withMessage('Ce mois est déjà utilisé pour cette année', this.isValidMonth)
      }
    }

    return form
  },
  methods: {
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        return false
      }
      return true
    },
    removeNegotiatedRateValue() {
      this.$emit('deleteNegotiatedRateValue', this.localNegotiatedRateValue.id)
      this.$emit('deleteInvalidMonth', this.localNegotiatedRateValue.id)
    },
    updateNegotiatedRateValue () {
      this.$emit('updateNegotiatedRateValue', this.localNegotiatedRateValue)
    },
    isValidMonth (date) {
      if (!this.isMissionMonthlyFixedPrice) return

      let isValid = true
      if (!date) {
        isValid = true
      }
      const foundIndex = this.invalidMonths.findIndex(validMonth => validMonth.id !== this.localNegotiatedRateValue.id
          && validMonth.monthFormat === invalidMonthFormat(date))
      if (foundIndex !== -1) {
        isValid = false
      }
      if (isValid) {
        this.$emit('addInvalidMonth', this.localNegotiatedRateValue)
      } else {
        this.$emit('deleteInvalidMonth', this.localNegotiatedRateValue.id)
      }
      return {
        $valid: isValid
      }
    }
  }
}
</script>

<style scoped>

</style>
