import axios from 'axios'
import { formatFromApiAutocompleteEntreprise } from '@/utils/ApigouvUtil'

export default {
  namespaced: true,

  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {
    async autocompleteEntreprise (_, data) {
      return new Promise((resolve, reject) => {
        axios.get(`https://recherche-entreprises.api.gouv.fr/search?q=${data}&limit=30`).then((res) => {
          resolve(formatFromApiAutocompleteEntreprise(res.data));
        }).catch((e) => {
          reject(e);
        })
      });
    },
    async autocompleteAdresse(_, data) {
      return new Promise((resolve, reject) => {
        // &type=housenumber
        axios.get(`https://api-adresse.data.gouv.fr/search/?q=${data}&limit=30`).then((res) => {
          // resolve(formatFromApiAutocompleteAdresse(res.data.features));
          resolve(res.data.features);
        }).catch((e) => {
          reject(e);
        })
      });
    },
    async distanceAdresses(_, data) {
      return new Promise((resolve, reject) => {
        axios.get(`https://wxs.ign.fr/calcul/geoportail/itineraire/rest/1.0.0/route?resource=bdtopo-osrm&start=${data.start[0]},${data.start[1]}&end=${data.end[0]},${data.end[1]}&profile=car&optimization=fastest&getSteps=false&getBbox=false&distanceUnit=kilometer`).then((res) => {
          resolve(res);
        }).catch((e) => {
          reject(e);
        })
      });
    },
    async natureJuridique(_, data) {
      return new Promise((resolve, reject) => {
        axios.get(`https://public.opendatasoft.com/api/records/1.0/search/?dataset=categories-juridiques-insee&q=${data}`).then((res) => {
          resolve(res.data.records);
        }).catch((e) => {
          reject(e);
        })
      });
    },
  }
}
