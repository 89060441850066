import {capitalizeWord, formatPhoneNumber} from "@/utils/Misc";
import {setDateWithoutHours} from "@/utils/Date";

export const formatFromApi = (independentI) => {
    if (typeof independentI !== 'object') return independentI
    let independent = JSON.parse(JSON.stringify(independentI))
    // todo: pre-formatted values, should avoid save formatted data, should format those when it's needed

    if (independent.profil) {
        independent.profil.birthDate = independent.profil.birthDate ? new Date(independent.profil.birthDate) : null
        independent.profil.medicalVisitDate = independent.profil.medicalVisitDate ? new Date(independent.profil.medicalVisitDate) : null
        independent.profil.workContract.startingDate = independent.profil.workContract.startingDate ? new Date(independent.profil.workContract.startingDate) : null
        independent.profil.workContract.endingDate = independent.profil.workContract.endingDate ? new Date(independent.profil.workContract.endingDate) : null
        independent.profil.workContract.baseSalary = independent.profil.workContract?.baseSalary ? independent.profil.workContract.baseSalary / 100 : null
        independent.profil.passport.expirationDate = independent.profil.passport.expirationDate ? new Date(independent.profil.passport.expirationDate) : null
        independent.profil.residencePermit.expirationDate = independent.profil.residencePermit.expirationDate ? new Date(independent.profil.residencePermit.expirationDate) : null
    }

    if (independent.sortie) {
        independent.sortie.date = independent.sortie.date ? new Date(independent.sortie.date) : null
    }

    independent.phone = formatPhoneNumber(independent.phone)

    return independent
}

export const formatDocFromApi = (docs) => {
    if (docs?.length === 0) {
        return []
    }
    docs.forEach((doc) => {
        if (doc?.metadata?.date) {
            const date = doc.metadata.date.split('-')
            let tmp = new Date(date[0], date[1] - 1, 1)
            doc.year = tmp.getFullYear()
            doc.month = capitalizeWord(tmp.toLocaleString('default', { month: 'long' }))
            delete doc.date
        }
    })
    return docs
}

export const formatToApi = (independentI) => {
    let independent = JSON.parse(JSON.stringify(independentI))

    let indepPhone = independent.phone
    if (indepPhone) {
        independent.phone = indepPhone.replaceAll('-', '')
        if (independent.phone.length !== 10) {
            independent.phone = undefined
        }
    }

    if (independent?.profil?.workContract) {
        independent.profil.workContract.baseSalary = Math.round(independent.profil.workContract.baseSalary * 100)
    }

    if (independent?.missions) {
        independent.missions = independent.missions.map(m => m?.id?? m)
    }

    if (independent.profil) {
        independent.profil.birthDate = independent.profil.birthDate ? setDateWithoutHours(independent.profil.birthDate) : null
        independent.profil.medicalVisitDate = independent.profil.medicalVisitDate ? setDateWithoutHours(independent.profil.medicalVisitDate) : null
        independent.profil.workContract.startingDate = independent.profil.workContract.startingDate ? setDateWithoutHours(independent.profil.workContract.startingDate) : null
        independent.profil.workContract.endingDate = independent.profil.workContract.endingDate ? setDateWithoutHours(independent.profil.workContract.endingDate) : null
        independent.profil.passport.expirationDate = independent.profil.passport.expirationDate ? setDateWithoutHours(independent.profil.passport.expirationDate) : null
        independent.profil.residencePermit.expirationDate = independent.profil.residencePermit.expirationDate ? setDateWithoutHours(independent.profil.residencePermit.expirationDate) : null
    }

    if (independent.sortie) {
        independent.sortie.date = independent.sortie.date ? setDateWithoutHours(independent.sortie.date) : null
    }

    return independent
}

export const getDocumentTypes = ({independent, constants, settings, rhView = false}) => {
    if (!independent || !constants || !settings) {
        return []
    }

    // adapt belong the independentRules and settings
    const documentTypes = []
    documentTypes.push(constants.DOCUMENT_TYPE.IDENTITY_CARD_RECTO)
    documentTypes.push(constants.DOCUMENT_TYPE.IDENTITY_CARD_VERSO)
    documentTypes.push(constants.DOCUMENT_TYPE.PROOF_OF_RESIDENCE)
    documentTypes.push(constants.DOCUMENT_TYPE.IBAN_SALARY)
    if (independent?.profil?.workContract?.ibanSalary !== independent?.profil?.workContract?.ibanNdf) {
        documentTypes.push(constants.DOCUMENT_TYPE.IBAN_NDF)
    }
    documentTypes.push(constants.DOCUMENT_TYPE.CPAM_CERTIFICATION)
    if (independent?.profil?.mutuelle?.value === constants.MUTUAL_TYPE.NONE) {
        documentTypes.push(constants.DOCUMENT_TYPE.MUTUAL_PROOF)
    }
    documentTypes.push(constants.DOCUMENT_TYPE.MUTUAL_CERTIFICATION)
    if (independent?.profil?.medicalVisit?.value === constants.MEDICAL_VISIT_TYPE.LT_FIVE) {
        documentTypes.push(constants.DOCUMENT_TYPE.MEDICAL_VISIT)
    }
    documentTypes.push(constants.DOCUMENT_TYPE.CURRICULUM_VITAE)
    if (independent?.profil?.handicap?.exist === 1) {
        documentTypes.push(constants.DOCUMENT_TYPE.AGEFIP_CERTIFICATION)
    }
    // && independent?.profil?.isPassport !== null
    if (independent?.profil?.nationality === constants.NATIONALITY_TYPE.EUROPEAN_UNION
        && independent.profil.isPassport === 1) {
        documentTypes.push(constants.DOCUMENT_TYPE.PASSPORT)
    }
    // && independent?.profil?.isPassport !== null
    if ((independent?.profil?.nationality === constants.NATIONALITY_TYPE.EUROPEAN_UNION
        && independent.profil.isPassport === 0)
        || independent?.profil?.nationality === constants.NATIONALITY_TYPE.EUROPEAN_UNION_OUT) {
        documentTypes.push(constants.DOCUMENT_TYPE.RESIDENT_PERMIT)
    }
    if (!independent.status || independent.status === '') {
        documentTypes.push(constants.DOCUMENT_TYPE.OTHER)
    }
    if (rhView) {
        documentTypes.push(constants.DOCUMENT_TYPE.DPAE)
    }
    return documentTypes
}
