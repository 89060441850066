<template>
  <div v-if="!loading" class="pr-3 pb-5 pt-3 flex flex-column flex-auto">
    <div class="flex gap-3 border-round pt-0  xhl:pt-0 xhl:pr-0">
      <div class="flex flex-column left-cards cards gap-3">
        <OuterCardTitle v-if="me.role.name === constants.ROLES.ADMIN && localMission.status === constants.MISSION_STATUS.PENDING_CONTRACT
         && !missionCreationMode && localMission.metadata" value="Informations fournies par le nouveau porté"
                        overload-class="text-orange-500"/>
        <Card v-if="me.role.name === constants.ROLES.ADMIN && localMission.status === constants.MISSION_STATUS.PENDING_CONTRACT
           && !missionCreationMode && localMission.metadata"
           style="opacity: 0.8">
          <template #content>
            <div class="grid formgrid p-fluid">
              <div class="field mb-3 col-12 md:col-3 ">
                <span class=" text-600 font-medium">Prénom + Nom du contact</span>
              </div>
              <div class="field mb-3 col-12 md:col-9 ">
                <span class="text-lg text-900 font-medium">{{ localMission.metadata.clientContact.fullName }}</span>
              </div>
              <div class="field mb-3 col-12 md:col-3 ">
                <span class=" text-600 font-medium">Téléphone</span>
              </div>
              <div class="field mb-3 col-12 md:col-9 ">
                <span class="text-lg text-900 font-medium">{{ formatPhoneNumber(localMission.metadata.clientContact.phone) }}</span>
              </div>
              <div class="field mb-3 col-12 md:col-3 ">
                <span class=" text-600 font-medium">Email</span>
              </div>
              <div class="field mb-3 col-12 md:col-9 ">
                <span class="text-lg text-900 font-medium">{{ localMission.metadata.clientContact.email }}</span>
              </div>
              <div class="field mb-3 col-12 md:col-3 ">
                <span class=" text-600 font-medium">Raison sociale</span>
              </div>
              <div class="field mb-3 col-12 md:col-9 ">
                <span class="text-lg text-900 font-medium">{{ localMission.metadata.clientContract.name }}</span>
              </div>
              <div class="field mb-3 col-12 md:col-3 ">
                <span class=" text-600 font-medium">SIREN / SIRET</span>
              </div>
              <div class="field mb-3 col-12 md:col-9 ">
                <span class="text-lg text-900 font-medium">{{ localMission.metadata.clientContract.siret }}</span>
              </div>
            </div>
          </template>
        </Card>
        <OuterCardTitle value="Informations sur le client"/>
        <Card>
          <template #content>
            <div class="grid formgrid p-fluid">
              <div v-if="me.role.name === constants.ROLES.ADMIN && isFormEditable" class="field mb-4 col-12 md:col-8  ">
                <label class="font-bold">Nom de la société</label>
                <div class="p-inputgroup flex-1 h-fit text-xs  " >
                  <Button v-if="localMission.clientContract?.id && me.role.name === constants.ROLES.ADMIN && isFormEditable"
                          icon="fa fa-pencil" class="pencil" v-tooltip.bottom="'Modifier les informations du client'" @click="updateClientContract" size="small"/>
                  <AutoComplete
                      v-model="clientContract"
                      :suggestions="filteredClientsContracts"
                      dropdown
                      force-selection
                      field="name"
                      class="text-xs"
                      placeholder="Selectionner un client existant"
                      input-class="no-radius text-sm"
                      dropdown-class="p-button-sm bg-teal"
                      @complete="searchClientContract($event)"
                      @dropdown-click="searchClientContract($event, true)"
                      @item-select="(event) => selectClient(event.value)"
                      @clear="clearClient"
                      @update:modelValue="changeMissionFields"
                  >
                    <template #item="slotProps">
                      <div class="flex flex-column">
                        <p>{{ `Nom: ${slotProps?.item?.name} [${slotProps?.item?.legalStatus}]` }}</p>
                        <p v-if="slotProps?.item?.foreign">{{ `Num identification: ${slotProps?.item?.identificationNumber}` }}</p>
                        <p v-if="!slotProps?.item?.foreign">{{ `Siren: ${formatSiren(slotProps?.item?.siret)}` }}</p>
                        <p v-if="!slotProps?.item?.foreign">{{ `Siret: ${formatSiret(slotProps?.item?.siret)}` }}</p>
                        <Divider class="m-0"/>
                      </div>
                    </template>
                  </AutoComplete>
                </div>
              </div>
              <div v-if="me.role.name === constants.ROLES.ADMIN && isFormEditable" class="field mb-4 col-12 md:col-4">
                <label>&nbsp;</label>
                <Button label="Créer un client"
                        icon="fa fa-plus"  @click="createClientContract" />
              </div>
              <div v-if="localMission?.clientContract?.id" class="grid formgrid p-fluid">
                <div v-if="me.role.name === constants.ROLES.INDEPENDENT" class="field mb-3 col-12 md:col-3 ">
                  <span class=" text-600 font-medium">Raison sociale :</span>
                </div>
                <div v-if="me.role.name === constants.ROLES.INDEPENDENT" class="field mb-3 col-12 md:col-9 ">
                  <span class="text-lg text-900 font-medium">{{ localMission?.clientContract?.name ?? '' }}</span>
                </div>
                <div class="field mb-3 col-12 md:col-3 ">
                  <span class=" text-600 font-medium">Statut juridique :</span>
                </div>
                <div class="field mb-3 col-12 md:col-9 ">
                  <span class="text-lg text-900 font-medium">{{ localMission?.clientContract?.legalStatus ?? '' }}</span>
                </div>
                <div class="field mb-3 col-12 md:col-3">
                  <span class="text-600 font-medium ">Capital :</span>
                </div>
                <div class="field mb-3 col-12 md:col-9">
                  <span class="text-lg text-900 font-medium">{{ !localMission.clientContract?.capital ? '' : (getEuroFormatFromEuro(localMission.clientContract.capital, true)) }}</span>
                </div>
                <div class="field mb-3 col-12 md:col-3">
                  <span class="text-600 font-medium">Adresse :</span>
                </div>
                <div class="field mb-3 col-12 md:col-9">
                  <span class="text-lg text-900 font-medium">{{ localMission?.clientContract?.address ?? '' }}</span>
                </div>
                <div class="field mb-3 col-12 md:col-3">
                  <span class="text-600 font-medium">Code postal : </span>
                </div>
                <div class="field mb-3 col-12 md:col-9">
                  <span class="text-lg text-900 font-medium">{{ localMission?.clientContract?.postalCode ?? '' }}</span>
                </div>
                <div class="field mb-3 col-12 md:col-3">
                  <span class="text-600 font-medium">Ville / Cedex : </span>
                </div>
                <div class="field mb-3 col-12 md:col-9">
                  <div class="field">
                    <span class="text-lg text-900 font-medium mr-2">{{ localMission?.clientContract?.city ?? '' }}</span>
                    <span class="text-lg text-900 font-medium">{{ localMission?.clientContract?.cedex ?? '' }}</span>
                  </div>
                </div>
                <div class="field mb-3 col-12 md:col-3">
                  <span class="text-600 font-medium" >Ville RCS :</span>
                </div>
                <div class="field mb-3 col-12 md:col-9">
                  <span class="text-lg text-900 font-medium">{{ localMission?.clientContract?.rcs_city ?? '' }}</span>
                </div>
                <template v-if="localMission?.clientContract?.foreign">
                  <div class="field mb-3 col-12 md:col-3">
                    <span class="text-600 font-medium">Numéro d'identification :</span>
                  </div>
                  <div class="field mb-3 col-12 md:col-9">
                    <span class="text-lg text-900 font-medium">{{ localMission?.clientContract?.identificationNumber ?? ''}}</span>
                  </div>
                </template>
                <template v-if="!localMission?.clientContract?.foreign" >
                  <div class="field mb-3 col-12 md:col-3">
                    <span class="text-600 font-medium">SIREN :</span>
                  </div>
                  <div class="field mb-3 col-12 md:col-9">
                    <span class="text-lg text-900 font-medium">{{ formatSiren(localMission?.clientContract?.siret) ?? '' }}</span>
                  </div>
                  <div class="field mb-3 col-12 md:col-3">
                    <span class="text-600 font-medium">SIRET :</span>
                  </div>
                  <div class="field mb-3 col-12 md:col-9">
                    <span class="text-lg text-900 font-medium">{{ formatSiret(localMission?.clientContract?.siret) ?? '' }}</span>
                  </div>
                </template>
                <div class="field mb-3 col-12 md:col-3">
                  <span class="text-600 font-medium">Représentant</span>
                </div>
                <div class="field mb-3 col-12 md:col-9">
                  <span class="text-lg text-900 font-medium">{{ localMission?.clientContract?.representing ?? '' }}</span>
                </div>
                <div class="field mb-3 col-12 md:col-3">
                  <span class="text-600 font-medium">Qualité</span>
                </div>
                <div class="field mb-3 col-12 md:col-9">
                  <span class="text-lg text-900 font-medium">{{ localMission?.clientContract?.quality ?? '' }}</span>
                </div>
              </div>
              <div v-else>
                <div class="field mb-3 col-12 md:col-9 ">
                  <span class="text-lg text-900 font-medium">Aucun client défini</span>
                </div>
              </div>
            </div>
            <Divider class="mt-4"/>
            <div class="mt-4">
              <div class="flex flex-row align-items-center justify-content-between">
                <p class="text-xl w-3 m-0 p-0 font-bold line-height-3"
                   :class="{
                      'mb-0': me.role.name === constants.ROLES.ADMIN && localMission?.clientContract?.id && isFormEditable,
                      'mb-3': !(me.role.name === constants.ROLES.ADMIN && localMission?.clientContract?.id && isFormEditable)
                    }">
                  Contact
                </p>
                <div v-if="me.role.name === constants.ROLES.ADMIN && localMission?.clientContract?.id && isFormEditable"
                     class="w-9 flex flex-row align-items-center justify-content-end w-full">
                  <div class="p-inputgroup flex-1">
                    <Button
                        v-tooltip.bottom="'Modifier les informations du contact'"
                        v-if="localMission.contact?.id"
                        icon="fa fa-pencil"
                        class="outlined"
                        @click="updateContact"
                    />
                    <AutoComplete
                        v-model="clientContact"
                        :suggestions="filteredClientsContacts"
                        :field="(value) => `${value.first_name} ${value.last_name}`"
                        placeholder="Selectionner un contact existant"
                        class="p-fluid mr-2 h-auto xxl:w-9"
                        input-class="no-radius"
                        dropdown
                        force-selection
                        @complete="searchContacts($event)"
                        @dropdown-click="searchContacts($event, true)"
                        @item-select="(event) => selectContact(event.value)"
                        @clear="clearContact"
                        @update:modelValue="changeMissionFields"
                    >
                    <template #item="slotProps">
                      <div class="flex flex-column">
                        <p>{{ `Prénom: ${slotProps.item.first_name}` }}</p>
                        <p>{{ `Nom: ${slotProps.item.last_name}` }}</p>
                        <p>{{ `Phone: ${slotProps.item.phone}` }}</p>
                        <p>{{ `Qualité: ${slotProps.item.position}` }}</p>
                        <p>{{ `Email: ${slotProps.item.email}` }}</p>
                        <Divider class="m-0"/>
                      </div>
                    </template>
                  </AutoComplete>
                  </div>
                  <Button
                      v-tooltip.bottom="'Ajouter un nouveau contact'"
                      icon="fa fa-plus"
                      class="mr-0"
                      :class="{'mr-2': localMission.contact?.id}"
                      @click="createClientContact"
                  />

                </div>
              </div>
<!--              todo: the best would be to encapsulate all the logical management of the contact and simply return the selected contact to the mission for a potential update-->
              <contact-info v-if="localMission?.clientContract?.id" :contact="localMission.contact"/>
            </div>
            <Divider class="mt-2"/>
            <div class="mt-4">
              <div class="flex flex-row align-items-center justify-content-between">
                <p class="text-xl w-3 m-0 p-0 font-bold line-height-3"
                   :class="{
                      'mb-0': me.role.name === constants.ROLES.ADMIN && localMission?.clientContract?.id && isFormEditable,
                      'mb-3': !(me.role.name === constants.ROLES.ADMIN && localMission?.clientContract?.id && isFormEditable)
                    }">
                  Signataire
                </p>
                <div v-if="me.role.name === constants.ROLES.ADMIN && localMission?.clientContract?.id && isFormEditable"
                     class="w-9 flex flex-row align-items-center justify-content-end ">
                  <div class="p-inputgroup flex-1">
                    <Button
                        v-tooltip.bottom="'Modifier les informations du signataire'"
                        v-if="localMission.signatory?.id"
                        icon="fa fa-pencil"
                        class="outlined"
                        @click="updateSignatory"
                    />
                    <AutoComplete

                      v-model="clientSignatory"
                      :suggestions="filteredClientsContacts"
                      :field="(value) => `${value.first_name} ${value.last_name}`"
                      placeholder="Selectionner un contact existant"
                      class="mr-2 xxl:w-9"
                      input-class="no-radius"
                      dropdown
                      force-selection
                      @complete="searchContacts($event)"
                      @dropdown-click="searchContacts($event, true)"
                      @item-select="(event) => selectSignatory(event.value)"
                      @clear="clearSignatory"
                      @update:modelValue="changeMissionFields"
                  >
                    <template #item="slotProps">
                      <div class="flex flex-column">
                        <p>{{ `Prénom: ${slotProps.item.first_name}` }}</p>
                        <p>{{ `Nom: ${slotProps.item.last_name}` }}</p>
                        <p>{{ `Phone: ${slotProps.item.phone}` }}</p>
                        <p>{{ `Qualité: ${slotProps.item.position}` }}</p>
                        <p>{{ `Email: ${slotProps.item.email}` }}</p>
                        <Divider class="m-0"/>
                      </div>
                    </template>
                  </AutoComplete>
                  </div>
                    <Button
                        v-tooltip.bottom="'Ajouter un nouveau contact signataire'"
                        icon="fa fa-plus"
                        :class="{'mr-2': localMission.signatory?.id}"
                        @click="createClientSignatoryContact"
                    />
                </div>
              </div>
              <contact-info v-if="localMission?.clientContract?.id" :contact="localMission?.signatory"/>
            </div>
            <Divider class="mt-2"/>
            <div class="mt-4">
              <div class="flex flex-row align-items-center justify-content-between">
                <p class="text-lg w-3 m-0 p-0 font-bold line-height-3"
                   :class="{
                      'mb-0': me.role.name === constants.ROLES.ADMIN && localMission?.clientContract?.id && isFormEditable,
                      'mb-3': !(me.role.name === constants.ROLES.ADMIN && localMission?.clientContract?.id && isFormEditable)
                    }">
                  Contact de facturation
                </p>
                <div v-if="me.role.name === constants.ROLES.ADMIN && localMission?.clientContract?.id && isFormEditable"
                     class="w-9 flex flex-row align-items-center justify-content-end ">
                  <div class="p-inputgroup flex-1">
                    <Button
                        v-tooltip.bottom="'Modifier les informations du contact de facturation'"
                        v-if="localMission.signatory?.id"
                        icon="fa fa-pencil"
                        class="outlined"
                        @click="updateBillingContact"
                    />
                    <AutoComplete
                        v-model="clientBillingContact"
                        :suggestions="filteredClientsContacts"
                        :field="(value) => `${value.first_name} ${value.last_name}`"
                        placeholder="Selectionner un contact existant"
                        class="p-fluid mr-2 h-auto xxl:w-9"
                        input-class="no-radius"
                        dropdown
                        force-selection
                        @complete="searchContacts($event)"
                        @dropdown-click="searchContacts($event, true)"
                        @item-select="(event) => selectBillingContact(event.value)"
                        @clear="clearBillingContact"
                        @update:modelValue="changeMissionFields"
                    >
                      <template #item="slotProps">
                        <div class="flex flex-column">
                          <p>{{ `Prénom: ${slotProps.item.first_name}` }}</p>
                          <p>{{ `Nom: ${slotProps.item.last_name}` }}</p>
                          <p>{{ `Phone: ${slotProps.item.phone}` }}</p>
                          <p>{{ `Qualité: ${slotProps.item.position}` }}</p>
                          <p>{{ `Email: ${slotProps.item.email}` }}</p>
                          <Divider class="m-0"/>
                        </div>
                      </template>
                    </AutoComplete>
                  </div>
                  <Button
                      v-tooltip.bottom="'Ajouter un contact de facturation'"
                      icon="fa fa-plus"
                      :class="{'mr-2': localMission.billingContact?.id}"
                      @click="createClientBillingContact"
                  />
                </div>
              </div>
              <contact-info v-if="localMission?.clientContract?.id" :contact="localMission?.billingContact"/>
            </div>
          </template>
        </Card>
        <OuterCardTitle value="Informations sur le client final"/>
        <Card>
          <template #content>
            <div class="mb-0 flex flex-column">
              <div class="field">
                <label for="finalClientName">Nom de la société du client final</label>
                <div>
                  <InputText id="finalClientName" type="text" v-model="localMission.jsonClientFinal.fullName"
                             :disabled="!isFormEditable" class="w-full"
                             @update:modelValue="(value) => changeMissionFields(value, 'jsonClientFinal.fullName', { isAmendment: true })"/>
                </div>
              </div>
              <div class="field">
                <label for="finalClientAddress">Adresse</label>
                <div>
                  <InputText id="finalClientAddress" type="text" v-model="localMission.jsonClientFinal.address"
                             :disabled="!isFormEditable" class="w-full"
                             @update:modelValue="(value) => changeMissionFields(value, 'jsonClientFinal.address', { isAmendment: true })"/>
                </div>
              </div>
            </div>
          </template>
        </Card>
        <OuterCardTitle value="Commentaire"/>
        <Card>
          <template #content>
            <Textarea v-model="localMission.notes" style="width: 100%; border-radius: 10px" :autoResize="true" rows="5"
                      :disabled="!isFormEditable" @update:modelValue="changeMissionFields"/>
          </template>
        </Card>
      </div>
      <div class="flex flex-column right-cards cards gap-3">
        <OuterCardTitle value="Informations commerciales"/>
        <Card v-if="!missionCreationMode || me.role.name === constants.ROLES.ADMIN">
          <template #content>
            <div class="grid formgrid p-fluid">
              <div class="field mb-3 col-12 md:col-6">
                <label class="font-bold" for="commercial">Chargé commercial</label>
                <div>
                  <Dropdown id="commercial" v-model="localMission.commercial"
                      :options="commercials" :option-label="(val) => val.first_name + ' ' + val.last_name"
                      :disabled="!isFormEditable"
                      @update:modelValue="changeMissionFields">
                    <template #value="slotProps">
                      <span v-if="slotProps.value">
                        {{ slotProps.value?.first_name + ' ' + slotProps.value?.last_name }}
                      </span>
                      <span v-else style="visibility: hidden">hidden content</span>
                    </template>
                  </Dropdown>
                </div>
              </div>
              <div class="field col-12 md:col-12">
                <div  class="flex flex-column gap-2">
                  <label class="font-bold">Type de contrat</label>
                  <div class="flex flex-wrap gap-3">
                    <div class="flex align-items-center">
                      <label class="mr-2" for="internal">Interne</label>
                      <RadioButton v-model="localMission.contractType" name="contract" id="internal" :value="constants.CONTRACT_TYPE.INTERNAL"
                                 :disabled="!isFormEditable" @update:modelValue="changeMissionFields"/>
                    </div>
                    <div class="flex align-items-center">
                      <label class="mr-2" for="contract">Client</label>
                      <RadioButton v-model="localMission.contractType" name="contract" id="contract" :value="constants.CONTRACT_TYPE.CLIENT"
                                 :disabled="!isFormEditable" @update:modelValue="changeMissionFields"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Card>
        <OuterCardTitle value="Détail de la mission"/>
        <Card>
          <template #content>
            <div class="grid formgrid p-fluid">
              <div class="field mb-3 col-12 md:col-6">
                <label for="missionStartingDate">Date initiale</label>
                <Calendar id="missionStartingDate" :showIcon="true" v-model="localMission.jsonMission.startingDate"
                          date-format="dd/mm/yy" :disabled="!isFormEditable" :manualInput="false" class="calendar-custom h-auto"
                          input-class="h-auto" @update:modelValue="startingDateChange"/>
                <!--                    @blur="startingDateChange"                               @date-select="startingDateChange"-->
              </div>
              <div v-if="!missionCreationMode || me.role.name === constants.ROLES.ADMIN" class="field mb-3 col-12 md:col-6">
                <label for="missionInitialDuration">Durée initiale de la mission</label>
                <div>
                  <InputText id="missionInitialDuration" type="text"
                             v-model="localMission.jsonMission.initialDuration" :disabled="!isFormEditable"
                             class="w-full"
                             @update:modelValue="changeMissionFields"/>
                </div>
              </div>
              <div v-if="!missionCreationMode || me.role.name === constants.ROLES.ADMIN" class="field mb-3 col-12 md:col-6">
                <label for="missionInitialEndingDate">Date de fin initiale</label>
                <Calendar id="missionInitialEndingDate" :showIcon="true"
                          v-model="localMission.jsonMission.initialEndingDate" date-format="dd/mm/yy"
                          :disabled="!isFormEditable" :manualInput="false" class="calendar-custom h-auto" input-class="h-auto"
                          @update:modelValue="(value) => changeMissionFields(value, 'jsonMission.initialEndingDate', { isAmendment: true })"
                          @date-select="(value) => changeMissionFields(value, 'jsonMission.initialEndingDate', { isAmendment: true })"/>
              </div>
              <div v-if="!missionCreationMode || me.role.name === constants.ROLES.ADMIN" class="field mb-3 col-12 md:col-6">
                <label for="missionRenewal">Renouvellement</label>
                  <div class="flex mt-2" style="gap: 0.5rem; align-items: center" id="missionRenewal">
                  <label for="missionRenewalYes">Oui</label>
                  <RadioButton id="missionRenewalYes" v-model="localMission.jsonMission.renewal"
                               :value="constants.RENEWAL_TYPE.ON" name="value" :disabled="!isFormEditable"
                               @update:modelValue="(value) => changeMissionFields(value, 'jsonMission.renewal', { isAmendment: true })"/>
                  <label for="missionRenewalNo">Non</label>
                  <RadioButton id="missionRenewalNo" v-model="localMission.jsonMission.renewal"
                               :value="constants.RENEWAL_TYPE.OFF" name="value" :disabled="!isFormEditable"
                               @update:modelValue="(value) => changeMissionFields(value, 'jsonMission.renewal', { isAmendment: true })"/>
                  <label for="missionRenewalAlways">Tacite reconduction</label>
                  <RadioButton id="missionRenewalAlways" v-model="localMission.jsonMission.renewal"
                               :value="constants.RENEWAL_TYPE.AUTOMATIC" name="value" :disabled="!isFormEditable"
                               @update:modelValue="(value) => changeMissionFields(value, 'jsonMission.renewal', { isAmendment: true })"/>
                </div>
              </div>
              <div
                  v-if="(!missionCreationMode || me.role.name === constants.ROLES.ADMIN) && localMission.jsonMission.renewal === constants.RENEWAL_TYPE.ON && localMission.status === constants.MISSION_STATUS.COMPLETE"
                  class="field mb-3 col-12 md:col-6">
                <label for="missionInitialRenewalStartingDate">Date de début de renouvellement</label>
                <Calendar id="missionInitialRenewalStartingDate"
                          v-model="localMission.jsonMission.initialRenewalStartingDate" date-format="dd/mm/yy"
                          :showIcon="true" :disabled="!isFormEditable" class="calendar-custom h-auto" input-class="h-auto"
                          @update:modelValue="(value) => changeMissionFields(value, 'jsonMission.initialRenewalStartingDate', { isAmendment: true })"
                          @date-select="(value) => changeMissionFields(value, 'jsonMission.initialRenewalStartingDate', { isAmendment: true })"/>
              </div>
              <div
                  v-if="(!missionCreationMode || me.role.name === constants.ROLES.ADMIN) && localMission.jsonMission.renewal === constants.RENEWAL_TYPE.ON && localMission.status === constants.MISSION_STATUS.COMPLETE"
                  class="field mb-3 col-12 md:col-6">
                <label for="missionInitialRenewalStartingDate">Date de fin de renouvellement</label>
                <Calendar id="missionInitialRenewalStartingDate"
                          v-model="localMission.jsonMission.renewalEndingDate" date-format="dd/mm/yy"
                          :showIcon="true" :disabled="!isFormEditable" class="calendar-custom h-auto" input-class="h-auto"
                          @update:modelValue="(value) => changeMissionFields(value, 'jsonMission.renewalEndingDate', { isAmendment: true })"
                          @date-select="(value) => changeMissionFields(value, 'jsonMission.renewalEndingDate', { isAmendment: true })"/>
              </div>
              <div v-if="!missionCreationMode || me.role.name === constants.ROLES.ADMIN" class="field mb-3 col-12 md:col-12">
                <label for="missionJobTitle">Intitulé du poste</label>
                <div>
                  <InputText id="missionJobTitle" type="text" v-model="localMission.jsonMission.jobTitle"
                             :disabled="!isFormEditable" class="w-full"
                             @update:modelValue="(value) => changeMissionFields(value, 'jsonMission.jobTitle', { isAmendment: true })"/>
                </div>
              </div>
              <div class="field mb-3 col-12 md:col-12 " v-if="!missionCreationMode || me.role.name === constants.ROLES.ADMIN">
                <label for="expertise">Compétences et qualifications</label>
                <InputText id="expertise" v-model="localMission.independent.competencesDetails"
                           class="w-full" type="text" :disabled="!isFormEditable"
                           @update:modelValue="changeMissionFields"/>
              </div>
              <div class="field mb-3 col-12 md:col-12">
                <label for="client-reference">Référence client</label>
                <div>
                  <InputText id="client-reference" type="text" v-model="localMission.jsonClientFinal.client_reference"
                             class="w-full" :disabled="!isFormEditable" @update:modelValue="changeMissionFields"/>
                </div>
              </div>

              <div v-if="!missionCreationMode || me.role.name === constants.ROLES.ADMIN" class="field mb-3 col-12 md:col-12">
                <label for="missionDescription">Descriptif du poste</label>
                <div>
                  <Textarea id="missionDescription" v-model="localMission.jsonMission.jobDescription"
                            style="width: 100%; border-radius: 10px" :autoResize="true" rows="5" :disabled="!isFormEditable" class="w-full"
                            @update:modelValue="(value) => changeMissionFields(value, 'jsonMission.jobDescription', { isAmendment: true })"/>
                </div>
              </div>

              <div v-if="!missionCreationMode || me.role.name === constants.ROLES.ADMIN" class="field mb-3 col-12 md:col-12">
                <label for="missionTrialPeriod">Période d'essai</label>
                <div>
                  <InputText id="missionTrialPeriod" type="text" v-model="localMission.jsonMission.trialPeriod"
                             :disabled="!isFormEditable" class="w-full"
                             @update:modelValue="changeMissionFields"/>
                </div>
              </div>
              <template v-if="!missionCreationMode || me.role.name === constants.ROLES.ADMIN" class="">
                <label class="font-bold text-lg w-12 mb-3">Délai de préavis</label>
                <div class="field mb-3 col-12 md:col-6">
                  <label for="missionNoticePeriodTrial" >Pendant la période d'essai </label>
                  <InputNumber id="missionNoticePeriodTrial"
                             v-model="localMission.jsonMission.noticePeriod.trial.value"
                             :disabled="!isFormEditable" :min="0"
                             @update:modelValue="(value) => changeMissionFields(value, 'jsonMission.noticePeriod.trial.value', { isAmendment: true })"/>
                </div>
                <div class="field mb-3 col-12 md:col-6">
                  <label >&nbsp;</label>
                  <Dropdown v-model="localMission.jsonMission.noticePeriod.trial.unit"
                            :options="trialPeriodUnits" :disabled="!isFormEditable"
                            @update:modelValue="(value) => changeMissionFields(value, 'jsonMission.noticePeriod.trial.unit', { isAmendment: true })"/>

                </div>
                <div class="field mb-3 col-12 md:col-6">
                  <label for="missionNoticePeriodGlobal" class="w-4">Général </label>
                  <InputNumber id="missionNoticePeriodGlobal"
                               v-model="localMission.jsonMission.noticePeriod.global.value"
                               :min="0" :disabled="!isFormEditable"
                               @update:modelValue="(value) => changeMissionFields(value, 'jsonMission.noticePeriod.global.value', { isAmendment: true })"/>
                </div>
                <div class="field mb-3 col-12 md:col-6">
                  <label >&nbsp;</label>
                  <Dropdown v-model="localMission.jsonMission.noticePeriod.global.unit"
                            :options="trialPeriodUnits" :disabled="!isFormEditable"
                            @update:modelValue="(value) => changeMissionFields(value, 'jsonMission.noticePeriod.global.unit', { isAmendment: true })"/>
                </div>
              </template>
            </div>
          </template>
        </Card>
        <OuterCardTitle value="Conditions financières"/>
        <Card>
          <template #content>
            <div class="grid formgrid p-fluid">
              <div class="field mb-3 col-12 md:col-12">
                <div v-if="!missionCreationMode || me.role.name === constants.ROLES.ADMIN" class="flex align-items-center gap-2">
                  <span class="text-lg font-medium w-3">Tarif de gestion</span>
                  <span class="text-lg font-bold" v-if="!isFetchingManagementFeeValues" :class="lastManagementFeeValuePassed?.value ? 'mr-2' : ''">
                    {{lastManagementFeeValuePassed?.value ? (`${lastManagementFeeValuePassed.value} % ${lastManagementFeeValuePassed?.cap ? `/ ${lastManagementFeeValuePassed.cap} € / ${lastManagementFeeValuePassed?.effectiveDate} ` : ''}`) : '' }}
                  </span>
                  <i v-else class="pi pi-spin pi-spinner" style="font-size: 2rem"/>
                  <Button id="managementFeeValues" v-if="me.role.name === constants.ROLES.ADMIN"
                          v-tooltip.bottom="'Ajouter ou modifier le tarif de gestion'"
                          icon="fa fa-cog" class="p-button-rounded" :loading="isFetchingManagementFeeValues"
                          :disabled="isManagementFeeValuesDisabled" @click="manageManagementFeeValues"/>
                  <div v-if="typeof lastManagementFeeValuePassed?.cap === 'number' && lastManagementFeeValuePassed?.cap === 0"
                       class="flex flex-row align-items-center gap-2 text-orange-400">
                    <i class="pi pi-exclamation-circle text-xl"/>
                    <span>Plafond à zéro</span>
                  </div>
                </div>
              </div>
              <div class="field mb-3 col-12 md:col-12">
                <div class="flex align-items-center gap-2" id="missionTeleworking">
                  <label  class="text-lg font-medium w-3" for="missionTeleworking">Mission en télétravail ? </label>
                  <label class="text-lg font-bold">100 %</label>
                  <RadioButton id="missionTeleworkingYes" name="value" :value="constants.TELEWORKING_TYPE.FULL"
                               v-model="localMission.jsonCost.teleworking" :disabled="!isFormEditable"
                               @update:modelValue="teleworkingTypeChange"/>
                  <label class="text-lg font-bold" for="missionTeleworkingPartial">Partiel</label>
                  <RadioButton id="missionTeleworkingPartial" name="value" :value="constants.TELEWORKING_TYPE.PARTIAL"
                               v-model="localMission.jsonCost.teleworking" :disabled="!isFormEditable"
                               @update:modelValue="teleworkingTypeChange"/>
                  <label class="text-lg font-bold" for="missionTeleworkingNo">0 %</label>
                  <RadioButton id="missionTeleworkingNo" name="value" :value="constants.TELEWORKING_TYPE.NONE"
                               v-model="localMission.jsonCost.teleworking" :disabled="!isFormEditable"
                               @update:modelValue="teleworkingTypeChange"/>

                </div>
              </div>
              <div class="field mb-3 col-12 md:col-3">
                <label class="text-lg font-medium" for="jsonCostNegotiatedRateType">Type de tarification</label>
                <Dropdown id="jsonCostNegotiatedRateType" v-model="localMission.jsonCost.negotiatedRateUnit"
                          :options="constants.data.negotiatedRateUnitOptions" :disabled="!isFormEditable"
                          class="dropdown-units w-full h-auto"
                          @update:modelValue="negotiatedRateUnitChange"/>

              </div>
              <div v-if="localMission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE &&
                   (localMission.status === constants.MISSION_STATUS.DRAFT || localMission.status === constants.MISSION_STATUS.PENDING_CONTRACT || missionCreationMode)"
                   class="field mb-3 col-12 md:col-6">
                <div class="flex align-items-center gap-2">
                  <label>Facturation</label>
                  <label for="forfaitFacturationModeMonth">Mensuel</label>
                  <RadioButton id="forfaitFacturationModeMonth" name="value" :value="constants.INVOICE_TYPE.MONTHLY"
                               v-model="localMission.jsonCost.forfaitFacturationMode" :disabled="!isFormEditable"
                               @update:modelValue="changeInvoiceMode"/>
  <!--                    changeMissionFields-->
                  <label for="forfaitFacturationModeOther">Autre</label>
                  <RadioButton id="forfaitFacturationModeOther" name="value" :value="constants.INVOICE_TYPE.OTHER"
                               v-model="localMission.jsonCost.forfaitFacturationMode" :disabled="!isFormEditable"
                               @update:modelValue="changeInvoiceMode"/>
                </div>
              </div>
              <div class="field mb-3 col-12 md:col-6">
                <label class="text-lg font-medium"  for="jsonCostNegotiatedRate">
                  &nbsp;
                </label>
                <div class="flex align-items-center">
                  <span v-if="!isFetchingNegotiatedRateValues" class="text-lg font-bold mb-0"
                        v-tooltip.bottom="'Sur site / en télétravail'" :class="lastNegotiatedRateValuePassed?.costOnSite ? 'mr-2' : ''">
                    {{lastNegotiatedRateValuePassed?.costOnSite ? `${lastNegotiatedRateValuePassed?.costOnSite} €` : '-' }} {{ lastNegotiatedRateValuePassed?.costOnRemote ? ` / ${lastNegotiatedRateValuePassed?.costOnRemote } €`  : ' / - '}}
                  </span>
                  <i v-else class="ml-3 pi pi-spin pi-spinner" style="font-size: 2rem"/>
                  <Button id="jsonCostNegotiatedRate" v-if="me.role.name === constants.ROLES.ADMIN"
                          v-tooltip.bottom="'Ajouter ou modifier les tarif '"
                          icon="fa fa-cog" class="p-button-rounded" :disabled="isNegotiatedRateValuesDisabled"
                          :loading="isFetchingNegotiatedRateValues"
                          @click="manageNegotiatedRateValues"/>
                </div>
              </div>
              <div v-if="!missionCreationMode || me.role.name === constants.ROLES.ADMIN" class="field mb-3 col-12 md:col-6">
                <label for="jsonCostSettlementPeriod">Délai de règlement</label>
                <InputText id="jsonCostSettlementPeriod" v-model="localMission.jsonCost.settlementPeriod.value"
                             class="inputtext-medium mr-2 " type="text"
                             :disabled="!isFormEditable"
                             @update:modelValue="(value) => changeMissionFields(value, 'jsonCost.settlementPeriod.value', { isAmendment: true })"/>
              </div>
              <div class="field mb-3 col-6 md:col-6">
                <label>Condition de règlement</label>
                <Dropdown v-model="localMission.jsonCost.settlementPeriod.unit"
                          :options="settlementPeriodUnits" :disabled="!isFormEditable" class="dropdown-units w-7 h-auto"
                          @update:modelValue="(value) => changeMissionFields(value, 'jsonCost.settlementPeriod.unit', { isAmendment: true })"/>
              </div>
            <div v-if="!missionCreationMode || me.role.name === constants.ROLES.ADMIN" class="field mb-3 col-6 md:col-12">
              <label for="jsonCostSolvency">Solvabilité du client :</label>
              <div class="flex" style="gap: 0.5rem; align-items: center" id="jsonCostSolvency">
                <label for="jsonCostSolvencyYes" class="font-bold">Oui</label>
                <RadioButton id="jsonCostSolvencyYes" :value="true" name="value"
                             v-model="localMission.jsonCost.solvency.value" :disabled="!isFormEditable"
                             @update:modelValue="changeMissionFields"/>
                <label for="jsonCostSolvencyNo" class="font-bold">Non</label>
                <RadioButton id="jsonCostSolvencyNo" v-model="localMission.jsonCost.solvency.value" name="value"
                             :value="false" :disabled="!isFormEditable" @update:modelValue="changeMissionFields"/>
                <InputText id="jsonCostSolvency" v-model="localMission.jsonCost.solvency.cap"
                           :disabled="!localMission.jsonCost.solvency.value && !isFormEditable"
                           class="inputtext-medium w-full" placeholder="Montant de la garantie de Factor"
                           type="text" @update:modelValue="changeMissionFields"/>
              </div>
            </div>
            <div v-if="!missionCreationMode || me.role.name === constants.ROLES.ADMIN" class="field mb-3 col-6 md:col-6">
              <label for="jsonCostOnCall">Astreintre / Intervention</label>
              <div id="jsonCostOnCall" class="flex" style="gap: 0.5rem;">
                <Checkbox id="jsonCostOnCallCheckbox" type="text" :binary="true"
                          v-model="localMission.jsonCost.onCall" :disabled="!isFormEditable"
                          @update:modelValue="changeMissionFields"/>
                <label for="jsonCostOnCallCheckbox">Barème exceptionnel</label>
              </div>
            </div>
            <div v-if="!missionCreationMode || me.role.name === constants.ROLES.ADMIN" class="field mb-3 col-6 md:col-6">
              <label>Frais de déplacement</label>
              <div class="flex" style="gap: 0.5rem;">
                <Checkbox id="jsonCostTravelFeeCheckbox" type="text" :binary="true"
                          v-model="localMission.jsonCost.travelFee" :disabled="!isFormEditable"
                          @update:modelValue="changeMissionFields"/>
                <label for="jsonCostTravelFeeCheckbox">Barème exceptionnel</label>
              </div>
            </div>
            </div>
          </template>
        </Card>
        <OuterCardTitle value="Apporteur d'affaire"/>
        <Card>
          <template #content>
            <div class="mb-0 flex flex-column">
              <div class="field">
                <label for="providerName">Nom</label>
                <div>
                  <InputText id="providerName" type="text" v-model="localMission.jsonProvider.fullName"
                             class="w-full"
                             :disabled="!isFormEditable" @update:modelValue="changeMissionFields"/>
                </div>
              </div>
              <div class="field">
                <label for="providerPayment">Paiement</label>
                <div class="flex">
                  <InputText id="providerPayment" v-model="localMission.jsonProvider.payment.value" :disabled="!editable"
                            class="inputtext-medium mr-2 w-8" type="text"
                            @update:modelValue="changeMissionFields"/>
                  <Dropdown v-model="localMission.jsonProvider.payment.unit" :options="providerPaymentUnits"
                            class="dropdown-units w-4 h-auto"
                            :disabled="!isFormEditable" @change="changeMissionFields"/>
                </div>
              </div>
            </div>
          </template>
        </Card>
        <ApplicableConditions
            v-if="!missionCreationMode || me.role.name === constants.ROLES.ADMIN"
            :existing-applicable-conditions="localMission.otherAppliedConditions"
            :editable="isFormEditable"
            @updateApplicableConditions="updateOtherAppliedConditions"
        />
      </div>
    </div>
    <dialog-management-fee-values v-if="me.role.name === constants.ROLES.ADMIN" :mission="localMission"
                                  :is-active="openManagementFeeValues"
                                  @closeDialog="closeManagementFeeValuesDialog"
                                  @update-negotiated-rate-values="updateManagementFeeValues"/>
    <!--  todo: this could be adapt to independent too maybe  -->
    <dialog-negotiated-rate-values v-if="me.role.name === constants.ROLES.ADMIN" :mission="localMission"
                                   :is-active="openNegotiatedRateValues"
                                   @closeDialog="closeNegotiatedRateValuesDialog"
                                   @update-negotiated-rate-values="updateNegotiatedRateValues"/>
    <dialog-crud-client
        v-if="me.role.name === constants.ROLES.ADMIN"
        :is-active="openCrudClient"
        :client-id="dialogClientId"
        :client-data="dialogClientData"
        :prospect-metadata="localMission.status !== constants.MISSION_STATUS.COMPLETE ? localMission.metadata : null"
        @closeDialog="resetDialog"
        @hide="resetDialog"
        @submitClient="saveAndSelectClient"
    />
    <dialog-crud-contact
        v-if="me.role.name === constants.ROLES.ADMIN"
        :is-active="openCrudContact"
        :client-id="dialogClientId"
        :client-data="dialogClientData"
        :prospect-metadata="localMission.status !== constants.MISSION_STATUS.COMPLETE ? localMission.metadata : null"
        :contact-type="contactType"
        @closeDialog="resetDialog"
        @hide="resetDialog"
        @submitContact="saveAndSelectClient"
        @saved="updateContacts"
    />
    <dialog-force-starting-date :is-active="openForceStartingDate" @close-dialog="closeForceStartingDateDialog"
                                @validate-starting-date="validateForcedStartingDate"/>
  </div>
  <div v-else class="overflow-hidden flex">
    <ProgressSpinner></ProgressSpinner>
  </div>
</template>

<script>
import UtilsDate, {setDateWithoutHours} from '@/utils/Date'
// import Alert from '@/utils/Alert'
import {mapState} from "vuex";
// import _ from 'lodash'
import DialogCrudClient from "@/components/Client/DialogCrudClient";
import DialogCrudContact from "@/components/Client/DialogCrudContact";
import ApplicableConditions from "@/components/ApplicableConditions.vue";
import {
  disablePComponentsOfCurrentPage,
  formatSiren,
  formatSiret,
  getLastValuePassed,
  hasAccess,
} from "@/utils/Misc";
import DialogNegotiatedRateValues from "@/components/DialogNegotiatedRateValues.vue";
import {formatPhoneNumber} from "@/utils/Misc";
import DialogManagementFeeValues from "@/components/DialogManagementFeeValues.vue";
import DialogForceStartingDate from "@/components/DialogForceStartingDate.vue";
import {getEuroFormatFromEuro} from "@/utils/Money";
import ContactInfo from "@/components/Mission/ContactInfo";
import OuterCardTitle from "@/components/OuterCardTitle";

export default {
  name: "FicheMission",
  emits: ["changeMission", "onMissionFieldChange", "missionPayload", "force-mission-update"],
  props: {
    mission: {
      type: Object,
      default: () => null
    },
    missionsIndependent: {
      type: Array,
      default: () => null
    },
    enableAmendment: {
      type: Boolean,
      default: false
    },
    independentId: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: true
    },
    enableMissionCreation: {
      type: Boolean,
      default: false
    },
    missionCreationMode: {
      type: Boolean,
      default: false
    },
    copyPreviousMission: {
      type: Boolean,
      default: false
    }
  },
  components: {
    OuterCardTitle,
    ContactInfo,
    DialogForceStartingDate,
    DialogManagementFeeValues,
    DialogNegotiatedRateValues,
    ApplicableConditions, DialogCrudContact, DialogCrudClient
  },
  data() {
    return {
      hasAccess: hasAccess,
      loading: true,

      isFetchingNegotiatedRateValues: false,
      isFetchingManagementFeeValues: false,

      filteredClientsContracts: [],
      filteredClientsContacts: [],
      filteredClientsSignatories: [],
      clientContract: null,
      clientContact: null,
      clientSignatory: null,
      clientBillingContact: null,

      openCrudClient: false,
      openCrudContact: false,
      openCrudSignatory: false,

      contactType: null,

      openNegotiatedRateValues: false,
      lastNegotiatedRateValuePassed: null,

      openManagementFeeValues: null,
      lastManagementFeeValuePassed: null,

      openForceStartingDate: false,
      afterValidateForcedStartingDate: () => {},
      previousUpdatedStartingDate: null,

      dialogProspectMetadata: null,
      dialogClientId: null,
      dialogClientData: null,

      localMission: {
        notes: '',
        jsonProvider: {
          name: '',
          payment: {
            value: '',
            unit: '€'
          }
        },
        jsonMission: {
          jobTitle: '',
          jobDescription: '',
          startingDate: null,
          initialDuration: '',
          initialEndingDate: '',
          renewal: '',
          initialRenewalStartingDate: '',
          renewalEndingDate: '',
          trialPeriod: '',
          noticePeriod: {
            trial: {
              value: '',
              unit: 'Jours'
            },
            global: {
              value: '',
              unit: 'Jours'
            }
          }
        },
        jsonCost: {
          negotiatedRateUnit: '',
          negotiatedRateValues: [],
          managementFeeValues: [],
          managementFee: '',
          cap: '',
          negotiatedRate: {
            value: '',
            unit: ''
          },
          forfaitFacturationMode: null,
          teleworking: '',
          settlementPeriod: {
            value: '0',
            unit: 'Jours'
          },
          solvency: {
            value: false,
            cap: ''
          },
          onCall: '',
          travelFee: ''
        },
        jsonClientFinal: {
          fullName: '',
          address: '',
          client_reference: '',
        },
        signatory: null,
        contact: null,
        clientContract: null,
        contractType: '',
        commercial: {
          first_name: '',
          last_name: ''
        },
        // the only that can change for now
        independent: {
          competencesDetails: null
        },
        otherAppliedConditions: []
      },
      cloneMission: {},
      clients: [],
      signatories: [],
      filteredClients: [],
      filteredContacts: [],
      filteredSignatories: [],
      managementFees: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10
      ],
      // todo: TJ Refactor, search for all TJ Refactor and apply the same method for those others hard-coded constants
      settlementPeriodUnits: ['Jours', 'Jours fin de mois', 'A réception'],
      providerPaymentUnits: ['€', '%'],
      trialPeriodUnits: ['Jours', 'Semaines', 'Mois']
    }
  },
  computed: {
    ...mapState({
      commercials: state => state.commercial.commercials,
      me: state => state.auth.me,
      constants: state => state.constants
    }),
    isFormEditable () {
      return this.editable && this.localMission?.status !== this.constants.MISSION_STATUS.ARCHIVED && !this.localMission?.deleted
    },
    isNegotiatedRateValuesDisabled () {
      return !this.isFormEditable
          || (this.localMission.jsonCost.negotiatedRateUnit === this.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE && this.localMission.jsonCost.forfaitFacturationMode === null)
    },
    isManagementFeeValuesDisabled () {
      return !this.isFormEditable
    }
  },
  watch: {
    // todo: should find a solution to update the mission properly on backend mission updates -- to make it work like this need to remove the getCommercial in initMission
    // mission: {
    //   async handler () {
    //     await this.initMission()
    //   },
    //   deep: true
    // },
    loading () {
      if (!this.loading) {
        this.$nextTick(() => {
          if (!hasAccess(this.$route.name, true)) disablePComponentsOfCurrentPage(document)
        })
      }
    }
  },
  async created() {
    await this.initMission()
  },
  methods: {
    getEuroFormatFromEuro,
    formatSiren,
    formatPhoneNumber,
    formatSiret,
    async initMission() {
      this.loading = true
      this.localMission.jsonCost.teleworking = this.constants.TELEWORKING_TYPE.NONE
      this.localMission.contractType = this.constants.CONTRACT_TYPE.CLIENT
      this.localMission.jsonMission.renewal = this.constants.RENEWAL_TYPE.AUTOMATIC

      if (this.missionCreationMode) {
        this.localMission.jsonCost.negotiatedRateUnit = this.constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE
        this.localMission.independent.competencesDetails = this.mission?.independent?.competencesDetails
        if (this.copyPreviousMission) {
          await this.initWithPropsMission(true)
        } else {
          this.localMission.commercial = null
        }
      } else if (this.mission) {
        await this.initWithPropsMission()
      }
      if (this.me.role.name === this.constants.ROLES.INDEPENDENT) {
        if (this.missionCreationMode) {
          this.localMission.clientContract = {}
          this.localMission.contact = {}
          this.localMission.signatory = {}

          this.localMission.metadata = {
            clientContract: {},
            clientContact: {}
          }
          this.loading = false
          return
        }
        // indep can see mission -- pendingContract / pendingSignature / complete
        this.localMission.clientContract = this.localMission.clientContract?.id ? this.localMission.clientContract : (this.localMission.metadata?.clientContract ? this.localMission.metadata?.clientContract : {})
        this.localMission.contact = this.localMission.contact?.id ? this.localMission.contact : (this.localMission.metadata?.clientContact ? this.localMission.metadata?.clientContact : {})
        this.localMission.signatory = this.localMission.signatory?.id ? this.localMission.contact : {}
        this.localMission.billingContact = this.localMission.billingContact?.id ? this.localMission.billingContact : {}

      }
      this.loading = false
    },
    async initWithPropsMission (withoutPricingAndMissionInfo = false) {
      const localMissionDuplicate = JSON.parse(JSON.stringify(this.localMission))
      const localMissionWithoutPricingAndMissionInfo = { jsonCost: localMissionDuplicate.jsonCost, jsonMission: localMissionDuplicate.jsonMission }
      this.localMission = this.mission
      this.cloneMission = JSON.parse(JSON.stringify(this.mission))
      if (this.localMission.jsonMission && !withoutPricingAndMissionInfo) {
        this.localMission.jsonMission.startingDate = !this.localMission.jsonMission.startingDate ? null : UtilsDate.getDate(new Date(this.localMission.jsonMission.startingDate))
        this.localMission.jsonMission.initialEndingDate = !this.localMission.jsonMission.initialEndingDate ? null : UtilsDate.getDate(new Date(this.localMission.jsonMission.initialEndingDate))
        this.localMission.jsonMission.initialRenewalStartingDate = !this.localMission.jsonMission.initialRenewalStartingDate ? null : UtilsDate.getDate(new Date(this.localMission.jsonMission.initialRenewalStartingDate))
        this.localMission.jsonMission.renewalEndingDate = !this.localMission.jsonMission.renewalEndingDate ? null : UtilsDate.getDate(new Date(this.localMission.jsonMission.renewalEndingDate))
      }

      // pre-init some fields in case they are empty -- todo: must be initialized in the back rather than in the front
      this.localMission.contractType = this.localMission.contractType ?? this.constants.CONTRACT_TYPE.CLIENT
      if (!withoutPricingAndMissionInfo) {
        this.localMission.jsonCost.teleworking = this.localMission.jsonCost.teleworking ?? this.constants.TELEWORKING_TYPE.NONE
        this.localMission.jsonMission.renewal = this.localMission.jsonMission.renewal ?? this.constants.RENEWAL_TYPE.AUTOMATIC

        this.localMission.jsonCost.settlementPeriod.unit = this.localMission.jsonCost.settlementPeriod.unit ?? this.settlementPeriodUnits[0]
        this.localMission.jsonCost.settlementPeriod.value = this.localMission.jsonCost.settlementPeriod.value ?? '0'
        this.localMission.jsonCost.solvency.value = this.localMission.jsonCost.solvency.value ?? false
        this.localMission.jsonMission.noticePeriod.trial.unit = this.localMission.jsonMission.noticePeriod.trial.unit ?? this.trialPeriodUnits[0]
        this.localMission.jsonMission.noticePeriod.global.unit = this.localMission.jsonMission.noticePeriod.global.unit ?? this.trialPeriodUnits[0]
        this.localMission.jsonProvider.payment.unit = this.localMission.jsonProvider.payment.unit ?? this.providerPaymentUnits[0]

        const nextCurrentDate = this.getCurrentEffectiveDate()
        this.lastNegotiatedRateValuePassed = this.getLastNegotiatedRateValuePassed({ currentDate: nextCurrentDate })
        this.lastManagementFeeValuePassed = this.getLastManagementFeeValuePassed({ currentDate: nextCurrentDate })
      }

      this.previousUpdatedStartingDate = this.localMission?.jsonMission?.startingDate ? JSON.parse(JSON.stringify(this.localMission.jsonMission.startingDate)) : null


      if (this.me.role.name === this.constants.ROLES.ADMIN) {
        // this.localMission.status === 'pendingContract'
        const isComplete = this.localMission.status === this.constants.MISSION_STATUS.COMPLETE

        this.clientContract = isComplete ? this.localMission.clientContract : (this.localMission.clientContract?.id ? this.localMission.clientContract : null)
        this.clientContact = isComplete ? this.localMission.contact : (this.localMission.contact?.id ? this.localMission.contact : null)
        this.clientSignatory = isComplete ? this.localMission.signatory : (this.localMission.signatory?.id ? this.localMission.signatory : null)
        this.clientBillingContact = isComplete ? this.localMission.billingContact : (this.localMission.billingContact?.id ? this.localMission.billingContact : null)

        this.localMission.clientContract = isComplete ? this.localMission.clientContract : (this.localMission.clientContract?.id ? this.localMission.clientContract : null)
        this.localMission.contact = isComplete ? this.localMission.contact : (this.localMission.contact?.id ? this.localMission.contact : null)
        this.localMission.signatory = isComplete ? this.localMission.signatory : (this.localMission.signatory?.id ? this.localMission.signatory : null)
        this.localMission.billingContact = isComplete ? this.localMission.billingContact : (this.localMission.billingContact?.id ? this.localMission.billingContact : null)

        await this.$store.dispatch('commercial/getAllCommercials')
        if (this.mission.commercial) {
          this.commercials.forEach((c) => {
            if (c.id === this.mission.commercial.id) {
              this.localMission.commercial = c
            }
          })
        }
      }

      if (this.missionCreationMode && withoutPricingAndMissionInfo) {
        // reset by default jsonMission and jsonCost
        this.localMission.id = undefined
        this.localMission.last_document_contract = null
        this.localMission.metadata = null
        this.localMission.declarationsFacturation = null
        this.localMission.CRA = null
        this.localMission.isCommercialValidated = null
        this.localMission.jsonCost = localMissionWithoutPricingAndMissionInfo.jsonCost
        this.localMission.jsonMission = localMissionWithoutPricingAndMissionInfo.jsonMission
      }
    },
    searchClient(val) {
      if (!val.query.trim().length) {
        this.filteredClients = [...this.clients];
      } else {
        this.filteredClients = this.clients.filter((client) => {
          return client.name.toLowerCase().startsWith(val.query.toLowerCase());
        });
      }
    },
    searchContact(val) {
      if (!val.query.trim().length) {
        this.filteredContacts = [...this.contacts];
      } else {
        this.filteredContacts = this.contacts.filter((contact) => {
          return contact.fullName.toLowerCase().startsWith(val.query.toLowerCase());
        });
      }
    },
    changeMission(event, newMission = false) {
      // todo: not fixed, have to put data in vuex and update from parent !
      if (this.me.role.name === this.constants.ROLES.ADMIN) {
        if (newMission) {
          this.$router.push({
            name: 'CreationMissionPorte',
            params: {idMission: event.value.id}
          })
        } else {
          this.$emit('changeMission', event.value.id)
          this.$router.push({
            name: 'FicheMissionPorte',
            params: {idPorte: this.$route.params.idPorte, idMission: event.value.id}
          })
        }
      }
      // Independent only ask for another mission, no need to update the current
      // else if (this.me.role.name === this.constants.ROLES.INDEPENDENT) {
      //   // todo: WON't work for the independent cause it'll retake the same first get mission after the reload
      //   this.$emit('updateMissionsIndepent')
      //   this.$emit('changeMission', event.value.id)
      //   // location.reload()
      // }
    },
    // deleteDueDate(index) {
    //   if (this.localMission.jsonCost.dueDates.length > 1) {
    //     this.localMission.jsonCost.dueDates.splice(index, 1)
    //     // consider this as a change
    //     this.changeMissionFields(this.localMission.jsonCost.dueDates)
    //   } else {
    //     Alert.errorMessage(this, 'deleteLastDueDate', {symbole: '(*)'})
    //   }
    // },
    // addDueDate() {
    //   // don't call changeMissionFields due to a not validate dueDate is fields remains null
    //   this.localMission.jsonCost.dueDates.push({
    //     price: undefined,
    //     date: undefined
    //   })
    // },
    // use option parameter object props as ways to identify if the field needed to trigger specific actions
    // eslint-disable-next-line no-unused-vars
    changeMissionFields(event, fieldPath = null, option = {isAmendment: false}) {
      if (this.me.role.name !== this.constants.ROLES.ADMIN) return
      // if later on we need to prevent the user from getting notify by an automatic dialog that he don't saved his changes even if he return those fields to their original state
      // const isFieldDifferent = _.get(this.cloneMission, fieldPath, null) !== _.get(this.localMission, fieldPath, null)
      // , isDifferent: isFieldDifferent, fieldPath
      const overloadOption = this.checkOptionOverload(option)
      this.$emit('onMissionFieldChange', {mission: this.localMission, fieldOption: overloadOption})
    },
    // list in here all the rules on a changing field
    checkOptionOverload(optionFieldObject) {
      optionFieldObject.isAmendment = this.enableAmendment ? (optionFieldObject.isAmendment ?? false) : false
      return optionFieldObject
    },
    updateOtherAppliedConditions(payload) {
      this.localMission.otherAppliedConditions = payload.applicableConditions
      this.changeMissionFields(payload.applicableConditions)
    },
    sendMissionPayload() {
      // should define this in the back instead of here
      this.localMission.status = this.constants.MISSION_STATUS.PENDING_CONTRACT
      // todo: define a validation in fiche mission for mission creation ? INDEPENDENT && ADMIN
      if (this.me.role.name === this.constants.ROLES.ADMIN) {
        // assume that in admin, always use route params
        this.localMission.independent.id = this.$route.params.idPorte
        this.localMission.commercial = this.localMission?.commercial?.id
      } else if (this.me.role.name === this.constants.ROLES.INDEPENDENT) {
        // todo: check how it'll work for the new TJ system
        // prepare metadata as in prospect process
        this.localMission.metadata = {
          clientContract: {},
          clientContact: {}
        }
        this.localMission.metadata.clientContract = {
          name: this.localMission.clientContract.name,
          siret: this.localMission.clientContract.siret
        }
        this.localMission.metadata.clientContact = {
          fullName: this.localMission.contact.fullName,
          phone: this.localMission.contact.phone,
          email: this.localMission.contact.email,
        }

        this.localMission.independent.id = this.independentId
        this.localMission.commercial = null
        this.localMission.clientContract = null
        this.localMission.contact = null
        this.localMission.signatory = null
      }
      this.$emit('missionPayload', this.localMission)
    },
    async searchClientContract(event, force = false) { // searchs
      if (force) {
        this.filteredClientsContracts = await this.$store.dispatch('client/getAllClients')
        return
      }
      if (event.query.length > 2) {
        this.filteredClientsContracts = await this.$store.dispatch('client/getAllFilteredClients', event.query)
      }
    },
    async searchContacts(event, force = false) {
      if (force) {
        this.filteredClientsContacts = await this.$store.dispatch('contact/getAllContacts', {clientId: this.localMission.clientContract.id})
        return
      }
      if (event.query.length > 2) {
        this.filteredClientsContacts = await this.$store.dispatch('contact/getAllFilteredContacts', {
          queryInput: event.query,
          clientId: this.localMission.clientContract.id
        })
      }
    },
    initDialog() {
      // data recall for the user
      if ((this.localMission.status === this.constants.MISSION_STATUS.DRAFT || this.localMission.status === this.constants.MISSION_STATUS.INCOMPLETE
          || this.localMission.status === this.constants.MISSION_STATUS.PENDING_CONTRACT)) {
        this.dialogProspectMetadata = this.localMission.metadata
      }
      if (this.localMission.clientContract?.id) {
        this.dialogClientId = this.localMission.clientContract?.id
      }
    },
    createClientContract() { // create
      this.initDialog()
      this.openCrudClient = true
    },
    createClientContact() {
      this.initDialog()
      this.contactType = this.constants.CLIENT_TYPES.CONTACT
      this.openCrudContact = true
    },
    createClientSignatoryContact() {
      this.initDialog()
      this.contactType = this.constants.CLIENT_TYPES.SIGNATORY
      this.openCrudContact = true
    },
    createClientBillingContact() {
      this.initDialog()
      this.contactType = this.constants.CLIENT_TYPES.INVOICE
      this.openCrudContact = true
    },
    updateClientContract() { // update
      this.initDialog()
      this.dialogClientData = this.localMission.clientContract
      this.openCrudClient = true
    },
    updateContact() {
      this.initDialog()
      // todo: send signatory within contact
      this.dialogClientData = this.localMission.contact
      this.contactType = this.constants.CLIENT_TYPES.CONTACT
      this.openCrudContact = true
    },
    updateSignatory() {
      this.initDialog()
      this.dialogClientData = this.localMission.signatory
      this.contactType = this.constants.CLIENT_TYPES.SIGNATORY
      this.openCrudContact = true
    },
    updateBillingContact() {
      this.initDialog()
      this.dialogClientData = this.localMission.billingContact
      this.contactType = this.constants.CLIENT_TYPES.INVOICE
      this.openCrudContact = true
    },
    clearClient () {
      this.resetClient()
      this.resetContact()
      this.resetSignatory()
      this.resetBillingContact()
    },
    clearContact () {
      this.resetContact()
    },
    clearSignatory () {
      this.resetSignatory()
    },
    clearBillingContact () {
      this.resetBillingContact()
    },
    async saveAndSelectClient(payload) {
      // also put the id to enable editing on the current selected one
      // update is done on the register button of the fiche mission
      switch (payload.type) {
        case this.constants.CLIENT_TYPES.CONTRACT:
          this.selectClient(payload.clientData)
          this.openCrudClient = false
          break
        case this.constants.CLIENT_TYPES.CONTACT:
          this.selectContact(payload.clientData)
          this.openCrudContact = false
          break
        case this.constants.CLIENT_TYPES.SIGNATORY:
          this.selectSignatory(payload.clientData)
          this.openCrudContact = false
          break
        case this.constants.CLIENT_TYPES.INVOICE:
          this.selectBillingContact(payload.clientData)
          this.openCrudContact = false
          break
      }
    },
    selectClient(payload) {
      const isDifferentClientContract = this.localMission?.clientContract?.id !== payload.id
      this.localMission.clientContract = {}
      this.localMission.clientContract.id = payload.id
      this.localMission.clientContract.foreign = payload.foreign
      this.localMission.clientContract.name = payload.name
      this.localMission.clientContract.legalStatus = payload.legalStatus
      this.localMission.clientContract.capital = payload.capital
      this.localMission.clientContract.address = payload.address
      this.localMission.clientContract.postalCode = payload.postalCode
      this.localMission.clientContract.city = payload.city
      this.localMission.clientContract.cedex = payload.cedex
      this.localMission.clientContract.rcs_city = payload.rcs_city
      this.localMission.clientContract.identificationNumber = payload.identificationNumber
      this.localMission.clientContract.siret = payload.siret
      this.localMission.clientContract.representing = payload.representing
      this.localMission.clientContract.quality = payload.quality
      this.clientContract = this.localMission.clientContract

      if (isDifferentClientContract) {
        this.resetContact()
        this.resetSignatory()
        this.resetBillingContact()
      }
    },
    selectContact(payload) {
      this.localMission.contact = {}
      this.localMission.contact.id = payload.id
      this.localMission.contact.first_name = payload.first_name
      this.localMission.contact.last_name = payload.last_name
      this.localMission.contact.phone = payload.phone
      this.localMission.contact.email = payload.email
      this.localMission.contact.position = payload.position
      this.clientContact = this.localMission.contact
    },
    selectSignatory(payload) {
      this.localMission.signatory = {}
      this.localMission.signatory.id = payload.id
      this.localMission.signatory.first_name = payload.first_name
      this.localMission.signatory.last_name = payload.last_name
      this.localMission.signatory.position = payload.position
      this.localMission.signatory.phone = payload.phone
      this.localMission.signatory.email = payload.email
      this.clientSignatory = this.localMission.signatory
    },
    selectBillingContact(payload) {
      this.localMission.billingContact = {}
      this.localMission.billingContact.id = payload.id
      this.localMission.billingContact.first_name = payload.first_name
      this.localMission.billingContact.last_name = payload.last_name
      this.localMission.billingContact.position = payload.position
      this.localMission.billingContact.phone = payload.phone
      this.localMission.billingContact.email = payload.email
      this.clientBillingContact = this.localMission.billingContact
    },
    resetClient() {
      this.localMission.clientContract = null
      this.clientContract = null
    },
    resetContact() {
      this.localMission.contact = null
      this.clientContact = null
    },
    resetSignatory() {
      this.localMission.signatory = null
      this.clientSignatory = null
    },
    resetBillingContact() {
      this.localMission.billingContact = null
      this.clientBillingContact = null
    },
    updateContacts(payload) {
      // double if in case both are the same
      if (this.localMission?.contact?.id === payload.id) {
        this.selectContact(payload)
      }
      // && payload.id prevent from set both on undefined/null contact
      if (this.localMission?.signatory?.id === payload.id && payload.id) {
        this.selectSignatory(payload)
      }
      if (this.localMission?.billingContact?.id === payload.id && payload.id) {
        this.selectBillingContact(payload)
      }
    },
    resetDialog() {
      this.dialogProspectMetadata = null
      this.dialogClientId = null
      this.dialogClientData = null
      this.openCrudClient = false
      this.openCrudContact = false
      this.openCrudSignatory = false
    },
    manageNegotiatedRateValues() {
      if (!this.isStartingDateSet(this.manageNegotiatedRateValues)) return
      this.openNegotiatedRateValues = true
    },
    manageManagementFeeValues() {
      if (!this.isStartingDateSet(this.manageManagementFeeValues, true))
        return
      this.openManagementFeeValues = true
    },
    // eslint-disable-next-line no-unused-vars
    isStartingDateSet (callback, isManagementFeeField = false) {
      // if (isManagementFeeField && (this.localMission.status === this.constants.MISSION_STATUS.DRAFT || this.localMission.status === this.constants.MISSION_STATUS.INCOMPLETE ||
      //     this.localMission.status === this.constants.MISSION_STATUS.PENDING_CONTRACT))
      //   return true
      if (!this.localMission.jsonMission.startingDate) {
        this.afterValidateForcedStartingDate = callback
        this.openForceStartingDateDialog()
        return false
      }
      return true
    },
    closeNegotiatedRateValuesDialog() {
      this.openNegotiatedRateValues = false
    },
    closeManagementFeeValuesDialog() {
      this.openManagementFeeValues = false
    },
    getCurrentEffectiveDate () {
      const currentDate = setDateWithoutHours(new Date())
      return currentDate > setDateWithoutHours(this.localMission?.jsonMission?.startingDate) ? currentDate : setDateWithoutHours(this.localMission?.jsonMission?.startingDate)
    },
    getLastValuePassed,
    getLastManagementFeeValuePassed(options) {
      return this.getLastValuePassed(JSON.parse(JSON.stringify(this.localMission?.jsonCost?.managementFeeValues)).map(value => {
        return { ...value, effectiveDate: setDateWithoutHours(value.effectiveDate)}
      }), { monthOnly: true, ...options })
    },
    getLastNegotiatedRateValuePassed(options) {
      return this.getLastValuePassed(JSON.parse(JSON.stringify(this.localMission?.jsonCost?.negotiatedRateValues)).map(value => {
        return { ...value, effectiveDate: setDateWithoutHours(value.effectiveDate)}
      }), { negotiatedRateUnit: this.localMission.jsonCost.negotiatedRateUnit, ...options })
    },
    // eslint-disable-next-line no-unused-vars
    async updateNegotiatedRateValues(values, forceUpdate = true, closeDialog = true) {
      const previousComputedNegotiatedRateValues = JSON.parse(JSON.stringify(this.getLastNegotiatedRateValuePassed({ currentDate: this.getCurrentEffectiveDate() })))
      this.localMission.jsonCost.negotiatedRateValues = values
      this.lastNegotiatedRateValuePassed = JSON.parse(JSON.stringify(this.getLastNegotiatedRateValuePassed({ currentDate: this.getCurrentEffectiveDate() })))

      // console.log('previousComputedNegotiatedRateValues', previousComputedNegotiatedRateValues)
      // console.log('newComputedNegotiatedRateValues', this.lastNegotiatedRateValuePassed)
      // console.log('isAmendment', previousComputedNegotiatedRateValues !== this.lastNegotiatedRateValuePassed)

      // in all case it's an amendment, it's automatically saved so no need to prevent for that
      if ((previousComputedNegotiatedRateValues?.costOnSite !== this.lastNegotiatedRateValuePassed?.costOnSite)
          || (previousComputedNegotiatedRateValues?.costOnRemote !== this.lastNegotiatedRateValuePassed?.costOnRemote)
          || (previousComputedNegotiatedRateValues?.effectiveDate !== this.lastNegotiatedRateValuePassed?.effectiveDate)) {
        this.changeMissionFields(this.lastNegotiatedRateValuePassed, null, {isAmendment: true})
      }
      if (forceUpdate) {
        this.$emit('force-mission-update')
      }
      if (closeDialog) {
        this.closeNegotiatedRateValuesDialog()
      }
    },
    // eslint-disable-next-line no-unused-vars
    async updateManagementFeeValues(values, forceUpdate = true, closeDialog = true) {
      const previousComputedNegotiatedRateValues = JSON.parse(JSON.stringify(this.getLastManagementFeeValuePassed({ currentDate: this.getCurrentEffectiveDate() })))
      this.localMission.jsonCost.managementFeeValues = values
      this.lastManagementFeeValuePassed = JSON.parse(JSON.stringify(this.getLastManagementFeeValuePassed({ currentDate: this.getCurrentEffectiveDate() })))

      // console.log('previousComputedNegotiatedRateValues', previousComputedNegotiatedRateValues)
      // console.log('newComputedNegotiatedRateValues', this.lastNegotiatedRateValuePassed)
      // console.log('isAmendment', previousComputedNegotiatedRateValues !== this.lastNegotiatedRateValuePassed)

      // in all case it's an amendment, it's automatically saved so no need to prevent for that
      if ((previousComputedNegotiatedRateValues?.value !== this.lastManagementFeeValuePassed?.value)
          || (previousComputedNegotiatedRateValues?.cap !== this.lastManagementFeeValuePassed?.cap)
          || (previousComputedNegotiatedRateValues?.effectiveDate !== this.lastManagementFeeValuePassed?.effectiveDate)) {
        this.changeMissionFields(this.lastManagementFeeValuePassed, null, {isAmendment: true})
      }
      if (forceUpdate) {
        this.$emit('force-mission-update')
      }
      if (closeDialog) {
        this.closeManagementFeeValuesDialog()
      }
    },
    async updateOriginalNegotiatedRateValues (init = false, useDefaultNegotiatedRate = false, fixedPriceReset = false, closeDialog = true) {
      this.isFetchingNegotiatedRateValues = true
      const updatedDefaultNegotiatedValues = await this.$store.dispatch('mission/getDefaultNegotiatedRateValues', {
        previousUpdatedStartingDate: this.previousUpdatedStartingDate,
        mission: this.localMission,
        init,
        fixedPriceReset,
        useDefaultNegotiatedRate
      })
      await this.updateNegotiatedRateValues(updatedDefaultNegotiatedValues, false, closeDialog)
      this.isFetchingNegotiatedRateValues = false
    },
    async updateOriginalManagementFeeValues (init = false, useDefaultNegotiatedRate = false, closeDialog = true) {
      this.isFetchingManagementFeeValues = true
      const updatedDefaultNegotiatedValues = await this.$store.dispatch('mission/getDefaultManagementFeeValues', {
        previousUpdatedStartingDate: this.previousUpdatedStartingDate,
        mission: this.localMission,
        init,
        useDefaultNegotiatedRate
      })
      await this.updateManagementFeeValues(updatedDefaultNegotiatedValues, false, closeDialog)
      this.isFetchingManagementFeeValues = false
    },
    async changeInvoiceMode (value) {
      // we reset the fixed price only on pendingContract missions
      const isMissionPending = this.localMission.status === this.constants.MISSION_STATUS.PENDING_CONTRACT
      await this.updateOriginalNegotiatedRateValues(false, isMissionPending, isMissionPending)
      this.changeMissionFields(value, 'jsonCost.forfaitFacturationMode', { isAmendment: true })
    },
    async negotiatedRateUnitChange (value) {
      // we reset the fixed price only on pendingContract missions
      const isMissionPending = this.localMission.status === this.constants.MISSION_STATUS.PENDING_CONTRACT
      await this.updateOriginalNegotiatedRateValues(false, isMissionPending, isMissionPending)
      this.changeMissionFields(value, 'jsonCost.negotiatedRateUnit', { isAmendment: true })
    },
    async teleworkingTypeChange (value) {
      await this.updateOriginalNegotiatedRateValues()
      this.changeMissionFields(value)
    },
    async startingDateChange (closeDialog = true) {
      // could init due to mission creation directly by the independent for example
      await this.updateOriginalNegotiatedRateValues(true, false, false, closeDialog)
      await this.updateOriginalManagementFeeValues(true, false, false, closeDialog)
      // might do it after the save only ?
      this.previousUpdatedStartingDate = JSON.parse(JSON.stringify(this.localMission.jsonMission.startingDate))
    },
    openForceStartingDateDialog () {
      this.openForceStartingDate = true
    },
    closeForceStartingDateDialog () {
      this.openForceStartingDate = false
    },
    clearValidateForcedStartingDate () {
      this.afterValidateForcedStartingDate = () => {}
    },
    async validateForcedStartingDate (value) {
      this.localMission.jsonMission.startingDate = value
      await this.startingDateChange(false)
      process.nextTick(() => {
        this.closeForceStartingDateDialog()
        this.afterValidateForcedStartingDate()
        this.clearValidateForcedStartingDate()
      })
    }
  }
}
</script>

<style scoped>
  /*.p-dropdown {*/
  /*  width: 15rem;*/
  /*}*/

.p-card {
  /*width: 50rem;*/
  border-radius: 20px;
}

.left-cards {
  width: 40%;
}

.right-cards {
  width: 60%;
}

.p-card-content > div {
  gap: 0rem;
}

.p-inputtext {
  width: 15rem;
}

.dropdown-units {
  width: auto;
}

.inputtext-medium {
  width: 6rem;
}

</style>

<style>

.inputtext-medium input {
  width: 6rem;
}

.inputtext-short {
  width: 3rem;
}
</style>
