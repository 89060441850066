import axios from '@/plugins/axios'
import {formatFromApi, formatToApi} from '@/utils/SimulationUtil'

export default {
  namespaced: true,

  state: {
    simulation: undefined,
    simulations: []
  },
  getters: {
    getSimulation (state) {
      return state.simulation
    },
    getSimulations (state) {
      return state.simulations
    }
  },
  mutations: {
    SET_SIMULATION(state, value) {
      state.simulation = value
    },
    SET_SIMULATION_BY_ID(state, id) {
      state.simulation = state.simulations.find((s) => s.id === id)?? state.simulations[0]
    },
    SET_SIMULATIONS(state, values) {
      state.simulations = values
    },
    UPDATE_SIMULATIONS(state, value) {
      const simulationIndex = state.simulations.findIndex((simulation) => simulation.id === value.id)
      if (simulationIndex !== -1) {
        state.simulations.splice(simulationIndex, 1, value)
      }
    }
  },
  actions: {
    // eslint-disable-next-line
    async getLastSimulation({ commit }, data) {
      return await axios.api.get('/simulation/independent/last/' + data).then(res => {
        const result = formatFromApi(res.data)
        commit('SET_SIMULATION', result)
        return result
      }).catch(() => {
        commit('SET_SIMULATION', {})
      })
    },
    // eslint-disable-next-line no-unused-vars
    async sharedSimulation({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get('/sharedSimulation/' + data.token + '/' + data.simulation).then(res => {
          const result = formatFromApi(res.data.simulation)
          commit('SET_SIMULATION', result)
          res.data.allSharedSimulations.sort((a, b) => {
            return new Date(a.date_created) - new Date(b.date_created)
          })
          commit('SET_SIMULATIONS', res.data.allSharedSimulations.map((sharedSimulation) => sharedSimulation.simulation))
          resolve(res.data.allSharedSimulations)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async lastSharedSimulationByIndependent({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get('/sharedSimulation/last/independent/' + data.independentId).then(res => {
          if (res?.data?.data[0]?.simulation) {
            const result = formatFromApi(res.data.data[0].simulation)
            commit('SET_SIMULATION', result)
          }
          // the returned result is never used
          resolve()
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    async getSimulation({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get('/simulation/' + data).then(res => {
          const result = formatFromApi(res.data)
          commit('SET_SIMULATION', result)
          resolve(result)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async getSimulationsIndependent({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get('/simulation/independent/' + data).then(res => {
          let datas = res.data.map((sim) => formatFromApi(sim))
          datas = datas.sort(function (b, a) { return new Date(b.date_created) - new Date(a.date_created) })
          commit('SET_SIMULATIONS', datas)
          resolve(datas)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async getValidatedSimulationsIndependent({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get('/simulation/validated-simulation/independent/' + data).then(res => {
          let datas = res.data.map((sim) => formatFromApi(sim))
          datas = datas.sort(function (b, a) { return new Date(b.date_created) - new Date(a.date_created) })
          commit('SET_SIMULATIONS', datas)
          resolve(datas)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async getSharedSimulationsByIndependent({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get('/sharedSimulation/independent/' + data).then(res => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },

    async setSimulationBySimulationId ({ commit }, id) {
      commit('SET_SIMULATION_BY_ID', id)
    },

    async clearAllSimulations ({ commit }) {
      commit('SET_SIMULATION', {})
      commit('SET_SIMULATIONS', [])
    },

    // eslint-disable-next-line
    async getAllSimulationsForProspects({ commit }) {
      return new Promise((resolve, reject) => {
        axios.api.get('/simulation/prospects').then(res => {
          const datas = res.data.map((sim) => formatFromApi(sim))
          commit('SET_SIMULATIONS', datas)
          resolve(datas)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async saveSimulation({ commit }, data) {
      data = formatToApi(data)
      if (data.id) {
        return new Promise((resolve, reject) => {
          axios.api.put('/simulation', data).then(res => {
            commit('SET_SIMULATION',  formatFromApi(res.data))
            resolve(res.data)
          }).catch((e) => {
            reject(e)
          })
        });
      } else {
        return new Promise((resolve, reject) => {
          axios.api.post('/simulation', data).then(res => {
            commit('SET_SIMULATION', formatFromApi(res.data))
            resolve(res.data)
          }).catch((e) => {
            reject(e)
          })
        });
      }
    },
    async updateMissionsSimulation({ commit }, data) {
      const formattedData = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.put('/simulation/' + data.id + '/missions', formattedData).then(res => {
          commit('SET_SIMULATION', formatFromApi(res.data))
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async sendSimulation({ commit }, data) {
      data.simulation = formatToApi(data.simulation)
      return new Promise((resolve, reject) => {
        axios.api.post('/simulation/send/' + data.role.toLowerCase() + '/' + data.simulation.id, data).then(async res => {
          commit('SET_SIMULATION', formatFromApi(res.data))
          commit('UPDATE_SIMULATIONS', formatFromApi(res.data))
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },

    // eslint-disable-next-line
    async generateSimulation({ commit }, data) {
      let endUrl = data.id
      if (data.invoiced) {
        endUrl += '?invoiced=' + data.invoiced
      }
      return new Promise((resolve, reject) => {
        axios.api.get('/simulation/generate/' + endUrl).then(async res => {
          resolve(res.data)
        }).catch((err) => {
          reject(err)
        })
      })
    },

    // eslint-disable-next-line
    async generateSalarySlipPreview({ commit }) {
      return await axios.api.get('/simulation/generate-preview').then(res => {
        return res.data
      }).catch(() => {
      })
    },

    // eslint-disable-next-line
    async checkIndependentEmail({ commit }, data) {
      let email = data.email
      return new Promise((resolve, reject) => {
        axios.api.get('/simulation/check-independent/' + email).then(res => {
          if (res.data === '') {
            reject('notExist')
          } else {
            resolve(res.data)
          }
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async validateSimulation(store, data) {
      // only get the simulations that have status to_validate and have their id different that the one we want to validate
      const simulationsIds = data.simulations
          .filter(simulation => simulation.status === store.rootState.constants.SIMULATION_STATUS.TO_VALIDATE && simulation.id !== data.id)
          .map((simulation) => simulation.id)
      return new Promise((resolve, reject) => {
        axios.api.put('/simulation/validate-simulation/' + data.id, { simulationsIds }).then(res => {
          store.commit('SET_SIMULATION', formatFromApi(res.data))
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
