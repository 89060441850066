/**
 * Gestion des items de la sidebar de gauche
 * props: [icon, name, label, to]
 * name: Permet de garder la séléction dans le state
 * label: Le titre a afficher
 * to: La route
 */
export default [
  { icon: 'fa-solid fa-house', name: 'Home', label: 'Tableau de bord', to: 'Home', root:'Home'},
  { icon: 'fa-solid fa-user-tie-hair', name: 'Portes', label: 'Portés', to: 'AdminPortes', root: 'Portes'},
  { icon: 'fa-solid fa-briefcase', name: 'Commercial', label: 'Commercial', to: 'CommercialPortes', root:'Commercial' },
{ icon: 'fa-solid fa-users-rectangle', name: 'RH', label: 'Ressources humaines', to: 'RHPortes', root:'RHPortesRoot'},
  { icon: 'fa-duotone fa-calculator fa-xl', name: 'Adv', label: 'Facturation', to: 'Services', root: 'Adv'},
  { icon: 'fa-solid fa-file-invoice-dollar', name: 'NDF', label: 'Notes de frais', to: 'NDF', root: 'NDF'  },
  { icon: 'fa-solid fa-hand-holding-dollar', name: 'Paie', label: 'Paie', to: 'PaiePortes', root: 'PaieAdmin' },
  { icon: 'fa-solid fa-money-bill-transfer', name: 'Regularisation', label: 'Régularisations', to: 'Regularisations', root: 'Regularisations' },
]
