<template>
  <Dialog v-model:visible="dialogActive" class="p-4 documentDialog" content-class="dialog-content" modal close-on-escape
          @update:visible="resetFields">
    <template #header>
      <p></p>
    </template>
    <prospect-helper v-if="prospectMetadata !== null" :prospect-metadata="prospectMetadata"/>
    <div class="mt-2">
      <h3 class="mb-2">{{ $t('clientTitle') }}</h3>
      <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid p-2">
        <div>
          <div class="field align-items-center">
            <AutoComplete
                v-if="!isStatusCodesReferenceLoading"
                v-model="company"
                :suggestions="filteredClientsContracts"
                @complete="searchCompany($event)"
                @item-select="(event) => selectCompany(event.value)"
                field="nom_raison_sociale"
                placeholder="Chercher une entreprise dans api.gouv"
                class="p-fluid"
            >
              <template #item="slotProps">
                <div class="flex flex-column">
                  <p>{{ `Nom: ${slotProps?.item?.nom_raison_sociale}` }}</p>
                  <p>{{
                      `Adresse: ${slotProps?.item?.siege.numero_voie} ${slotProps?.item?.siege.type_voie} ${slotProps?.item?.siege.libelle_voie} ${slotProps?.item?.siege.code_postal} ${slotProps?.item?.siege.libelle_commune}`
                    }}</p>
                  <p>{{ `Siret: ${formatSiret(slotProps?.item?.siege.siret)}` }}</p>
                  <Divider class="m-0"/>
                </div>
              </template>
            </AutoComplete>
          </div>
          <div class="flex flex-row mb-2">
            <label for="foreign" class="block text-900 font-medium mb-2 mr-2"> {{ $t('clientForeign') }}</label>
            <Checkbox id="foreign" type="text" v-model="foreign" :binary="true" @change="foreignChanged"/>
          </div>

          <label for="name" :class="{'p-error':v$.name.$invalid && submitted}" class="block text-900 font-medium mb-2">
            {{ $t('clientName') + (foreign ? '' : ' *')}}
          </label>
          <InputText id="name" v-model="v$.name.$model" type="text" :class="{'p-invalid':v$.name.$invalid && submitted}"
                     class="w-full mb-2"/>
          <div v-if="v$.name.$error && submitted" class="mb-2">
          <span id="name-error" v-for="(error, index) of v$.name.$errors" :key="index">
            <InlineMessage>{{ error.$message }}</InlineMessage>
          </span>
          </div>
          <!--          <InputText id="status" v-model="v$.legalStatus.$model" type="text" :class="{'p-invalid':v$.legalStatus.$invalid && submitted}" class="w-full mb-3" />-->
          <!--          <div v-if="v$.legalStatus.$error && submitted" class="mb-2">-->
          <!--          <span id="status-error" v-for="(error, index) of v$.legalStatus.$errors" :key="index">-->
          <!--            <InlineMessage>{{error.$message}}</InlineMessage>-->
          <!--          </span>-->
          <!--          </div>-->
          <label for="status" :class="{'p-error':v$.legalStatus.$invalid && submitted}"
                 class="block text-900 font-medium mb-2"> {{ $t('clientLegalStatus') + (foreign ? '' : ' *')}}</label>
          <InputText v-if="foreign" id="status" v-model="v$.legalStatus.$model" class="mb-2"
                     type="text" :class="{'p-invalid':v$.legalStatus.$errors.length}"/>
          <Dropdown v-else v-model="v$.legalStatus.$model" :options="statusCodesReference"
                    :class="{'p-invalid':v$.legalStatus.$errors.length}" class="mb-2"
                    :loading="isStatusCodesReferenceLoading"
                    option-label="libelle" option-value="libelle"
                    filter placeholder="Séléctionnez un status juridique"/>
          <div v-if="v$.legalStatus.$error && submitted" class="mb-2">
            <span id="legalStatus-error" v-for="(error, index) of v$.legalStatus.$errors" :key="index">
              <InlineMessage>{{ error.$message }}</InlineMessage>
            </span>
          </div>

          <label for="capital" :class="{'p-error':v$.capital.$invalid && submitted}"
                 class="block text-900 font-medium mb-2"> {{ $t('clientCapital') + (foreign ? '' : ' *') }}</label>
          <InputNumber id="capital" v-model="v$.capital.$model" class="mb-2" :min="0" locale="fr-FR" mode="currency"
                       currency="EUR"/>
          <div v-if="v$.capital.$error && submitted" class="mb-2">
            <span id="capital-error" v-for="(error, index) of v$.capital.$errors" :key="index">
              <InlineMessage>{{ error.$message }}</InlineMessage>
            </span>
          </div>
          <label for="address" :class="{'p-error':v$.address.$invalid && submitted}"
                 class="block text-900 font-medium mb-2"> {{ $t('clientAddress') + (foreign ? '' : ' *') }}</label>
          <InputText id="address" v-model="v$.address.$model" type="text"
                     :class="{'p-invalid':v$.address.$invalid && submitted}" class="w-full mb-2"/>
          <div v-if="v$.address.$error && submitted" class="mb-2">
            <span id="address-error" v-for="(error, index) of v$.address.$errors" :key="index">
              <InlineMessage>{{ error.$message }}</InlineMessage>
            </span>
          </div>
          <label for="postalCode" :class="{'p-error':v$.postalCode.$invalid && submitted}"
                 class="block text-900 font-medium mb-2"> {{ $t('clientPostalCode') + (foreign ? '' : ' *') }}</label>
          <InputText id="postalCode" v-model="v$.postalCode.$model" type="text"
                     :class="{'p-invalid':v$.postalCode.$invalid && submitted}" class="w-full mb-2"/>
          <div v-if="v$.postalCode.$error && submitted" class="mb-2">
            <span id="postalCode-error" v-for="(error, index) of v$.postalCode.$errors" :key="index">
              <InlineMessage>{{ error.$message }}</InlineMessage>
            </span>
          </div>
          <label for="city" :class="{'p-error':v$.city.$invalid && submitted}" class="block text-900 font-medium mb-2">
            {{ $t('clientCity') + (foreign ? '' : ' *') }}</label>
          <InputText id="city" v-model="v$.city.$model" type="text" :class="{'p-invalid':v$.city.$invalid && submitted}"
                     class="w-full mb-2"/>
          <div v-if="v$.city.$error && submitted" class="mb-2">
            <span id="city-error" v-for="(error, index) of v$.city.$errors" :key="index">
              <InlineMessage>{{ error.$message }}</InlineMessage>
            </span>
          </div>
          <label v-if="foreign" for="country" :class="{'p-error':v$.country.$invalid && submitted}" class="block text-900 font-medium mb-2">
            {{ $t('clientCountry') + ' *' }}</label>
          <InputText v-if="foreign" id="country" v-model="v$.country.$model" type="text" :class="{'p-invalid':v$.country.$invalid && submitted}"
                     class="w-full mb-2"/>
          <div v-if="foreign && v$.country.$error && submitted" class="mb-2">
            <span id="city-error" v-for="(error, index) of v$.country.$errors" :key="index">
              <InlineMessage>{{ error.$message }}</InlineMessage>
            </span>
          </div>

          <label for="cedex" class="block text-900 font-medium mb-2"> {{ $t('clientCedex') }}</label>
          <InputText id="cedex" v-model="cedex" type="text" class="w-full mb-2"/>

          <label for="rcsCity" :class="{'p-error':v$.rcsCity.$invalid && submitted}"
                 class="block text-900 font-medium mb-2"> {{ $t('clientCityRcs') + (foreign ? '' : ' *') }}</label>
          <InputText id="rcsCity" v-model="v$.rcsCity.$model" type="text"
                     :class="{'p-invalid':v$.rcsCity.$invalid && submitted}" class="w-full mb-2"/>
          <div v-if="v$.rcsCity.$error && submitted" class="mb-2">
            <span id="rcsCity-error" v-for="(error, index) of v$.rcsCity.$errors" :key="index">
              <InlineMessage>{{ error.$message }}</InlineMessage>
            </span>
          </div>

          <label v-if="foreign" for="identification-number" :class="{'p-error':v$.identificationNumber.$invalid && submitted}"
                 class="block text-900 font-medium mb-2"> {{ $t('clientIdentificationNumber') + ' *' }}</label>
          <InputText v-if="foreign" id="identification-number" v-model="v$.identificationNumber.$model" type="text"
                     :class="{'p-invalid':v$.identificationNumber.$invalid && submitted}" class="w-full mb-2"/>
          <div v-if="foreign && v$.identificationNumber.$error && submitted" class="mb-2">
            <span id="siren-error" v-for="(error, index) of v$.identificationNumber.$errors" :key="index">
              <InlineMessage>{{ error.$message }}</InlineMessage>
            </span>
          </div>

          <label v-if="!foreign" for="siret" :class="{'p-error':v$.siret.$invalid && submitted}"
                 class="block text-900 font-medium mb-2"> {{ $t('clientSiret') + (foreign ? '' : ' *')}}</label>
          <InputMask v-if="!foreign" id="siret" v-model="v$.siret.$model" type="text" mask="999 999 999? 99999"
                     :class="{'p-invalid':v$.siret.$invalid && submitted}" class="w-full mb-2" unmask/>
          <div v-if="!foreign && v$.siret.$error && submitted" class="mb-2">
            <span id="siret-error" v-for="(error, index) of v$.siret.$errors" :key="index">
              <InlineMessage>{{ error.$message }}</InlineMessage>
            </span>
          </div>

          <!--  todo: are those one required ?  -->
          <label for="representing" class="block text-900 font-medium mb-2"> {{ $t('clientRepresenting') }}</label>
          <InputText id="representing" v-model="representing" type="text" class="w-full mb-2"/>

          <label for="quality" class="block text-900 font-medium mb-2"> {{ $t('signatoryQuality') }}</label>
          <InputText id="quality" v-model="quality" type="text" class="w-full mb-2"/>

        </div>
        <div class="flex flex-row-reverse mt-2">
          <div class="flex col-6 justify-content-between">
            <Button :label="$t('save')" class="mr-2 p-button-outlined" @click="saveCreatedClient(true)"/>
            <Button :label="$t('validate')" type="submit" class="w-full p-3"></Button>
          </div>
        </div>
      </form>
    </div>
  </Dialog>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import Alert from "@/utils/Alert";
import ProspectHelper from "@/components/Client/ProspectHelper";
import RequestErrorManager from "@/utils/RequestErrorManager";
import FindLegalStatusByCode from "@/utils/FindLegalStatusByCode";
import {formatSiret} from "@/utils/Misc";
// import LegalStatusOptions from "@/utils/LegalStatusCodesReferences.json"
import {mapState} from "vuex";

export default {
  components: {ProspectHelper},
  setup: () => ({v$: useVuelidate()}),
  emits: ["closeDialog", "submitClient"],
  name: "DialogCrudClient",
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
    prospectMetadata: {
      type: Object,
      default: null,
    },
    clientId: {
      type: String,
      default: null,
    },
    clientData: {
      type: Object,
      default: null,
    }
  },
  watch: {
    isActive(value) {
      if (value) {
        this.initFields()
      }
      this.dialogActive = value
    }
  },
  data() {
    return {
      dialogActive: false,
      formValidated: false,

      company: null,
      filteredClientsContracts: [],

      defaultCountry: 'France',

      id: '',
      foreign: false,
      name: '',
      legalStatus: null,
      capital: 0,
      address: '',
      postalCode: '',
      city: '',
      country: '',
      cedex: '',
      rcsCity: '',
      siret: null,
      identificationNumber: null,
      representing: '',
      quality: '',

      isStatusCodesReferenceLoading: false,
      submitted: false
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
      statusCodesReference: state => state.misc.legalStatusCodesReference
    })
  },
  validations() {
    const form = {
      name: {
        required: helpers.withMessage(this.$t('validations.nameClientRequired'), required),
      },
      legalStatus: {
        required: helpers.withMessage(this.$t('validations.legalStatusClientRequired'), required),
      },
      capital: {
        required: helpers.withMessage(this.$t('validations.capitalClientRequired'), required),
        minValue: helpers.withMessage(this.$t('validations.capitalClientMinValue'), (value) => value > 0)
      },
      address: {
        required: helpers.withMessage(this.$t('validations.addressClientRequired'), required),
      },
      postalCode: {
        required: helpers.withMessage(this.$t('validations.postalCodeClientRequired'), required),
      },
      city: {
        required: helpers.withMessage(this.$t('validations.cityClientRequired'), required),
      },
      country: {},
      rcsCity: {
        required: helpers.withMessage(this.$t('validations.rcsCityClientRequired'), required),
      },
      siret: {
        required: helpers.withMessage(this.$t('validations.siretClientRequired'), required),
        format: helpers.withMessage('Veuillez renseigner un siren (9 chiffres) ou un siret (14 chiffres)', (value) => {
          return value.length === 9 || value.length === 14
        })
      },
      identificationNumber: {}
    }
    if (this.foreign) {
      form.legalStatus = {}
      form.capital = {}
      form.address = {}
      form.postalCode = {}
      form.city = {}
      form.country = {
        required: helpers.withMessage(this.$t('validations.countryClientRequired'), required),
      }
      form.rcsCity = {}
      form.siret = {}
      form.identificationNumber = {
        required: helpers.withMessage(this.$t('validations.identificationNumberClientRequired'), required),
      }
    }
    return form
  },
  async created () {
    this.isStatusCodesReferenceLoading = true
    await this.$store.dispatch('misc/getLegalStatusCodesReference')
    this.isStatusCodesReferenceLoading = false
  },
  methods: {
    formatSiret,
    initFields() {
      if (this.clientData !== null) {
        this.id = this.clientData.id
        this.foreign = this.clientData.foreign
        this.name = this.clientData.name
        this.legalStatus = this.clientData.legalStatus
        this.capital = this.clientData.capital
        this.address = this.clientData.address
        this.postalCode = this.clientData.postalCode
        this.city = this.clientData.city
        this.country = this.clientData.country
        this.cedex = this.clientData.cedex
        this.rcsCity = this.clientData.rcs_city
        this.siren = this.clientData.siren
        this.siret = this.clientData.siret
        this.representing = this.clientData.representing
        this.quality = this.clientData.quality
      }
    },
    foreignChanged () {
      if (this.foreign) {
        this.country = ''
      } else {
        this.country = this.defaultCountry
      }
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
    async saveCreatedClient(forceValidation = false) {
      if (forceValidation) {
        this.submitted = true
        this.v$.$touch()
        if (this.v$.$invalid) {
          Alert.errorMessage(this, 'fieldRequired', {symbole: '(*)'})
          return
        }
        this.submitted = false
      }

      try {
        const clientSavedPayload = {
          foreign: this.foreign,
          name: this.name,
          legalStatus: this.legalStatus,
          capital: this.capital,
          address: this.address,
          postalCode: this.postalCode,
          city: this.city,
          country: !this.foreign ? this.defaultCountry : this.country,
          cedex: this.cedex,
          rcs_city: this.rcsCity,
          siret: this.foreign ? null : this.siret,
          identificationNumber: this.foreign ? this.identificationNumber : null,
          representing: this.representing,
          quality: this.quality
        }
        if (this.id) {
          clientSavedPayload.id = this.id
        }

        let clientSaved = {}
        clientSaved = await this.$store.dispatch('client/saveClient', clientSavedPayload)
        if (clientSaved.id) {
          this.id = clientSaved.id
        }
        return clientSaved
      } catch (e) {
        // might apply some changes onto the field concern ?
        RequestErrorManager.error(this, e)
        return null
      }
    },
    async handleSubmit(isFormValid) {
      this.submitted = true
      this.v$.$touch()

      if (!isFormValid) {
        Alert.errorMessage(this, 'fieldRequired', {symbole: '(*)'})
        return
      }

      const clientSaved = await this.saveCreatedClient()
      if (clientSaved === null) {
        this.submitted = false
        return
      }

      // send the id back in all cases
      const payload = {
        type: this.constants.CLIENT_TYPES.CONTRACT,
        clientData: clientSaved
      }
      this.$emit('submitClient', payload)
      // force reset
      this.resetFields()
    },
    async searchCompany(event) {
      if (event.query.length > 2) {
        this.filteredClientsContracts = await this.$store.dispatch('apigouv/autocompleteEntreprise', event.query)
      }
    },
    selectCompany(payload) {
      this.name = payload.nom_raison_sociale
      this.siret = payload.siege.siret ? payload.siege.siret : payload.siren
      this.identificationNumber = null
      this.address = `${payload.siege.numero_voie} ${payload.siege.type_voie} ${payload.siege.libelle_voie}`
      this.postalCode = payload.siege.code_postal
      this.city = payload.siege.libelle_commune
      this.country = !this.foreign ? this.defaultCountry : this.country
      this.cedex = payload.siege.libelle_cedex
      if (!this.foreign) {
        const legalStatusObject = FindLegalStatusByCode(payload.nature_juridique, this.statusCodesReference)
        if (legalStatusObject) {
          this.legalStatus = legalStatusObject.libelle
        }
      }
    },
    resetFields() {
      this.company = null

      this.id = ''
      this.foreign = false
      this.name = ''
      this.legalStatus = null
      this.capital = 0
      this.address = ''
      this.postalCode = ''
      this.city = ''
      this.country = ''
      this.cedex = ''
      this.rcsCity = ''
      this.siret = null
      this.identificationNumber = null
      this.representing = ''
      this.quality = ''

      this.submitted = false
    }
  }
}
</script>

<style>
.documentDialog {
  width: 800px;
  background-color: white;
}
</style>
