<template>
  <div v-if="isLogged" class="min-h-screen flex-container relative flex-grow-1 lg:static" @click="onDocumentClick">
    <Toast position="top-right"/>
    <div class="layout-wrapper layout-static flex-container non-scrollable" :class="sideContent.isVisible ? 'wide' : ''">
      <AppTopbar :activeTopbarItem="activeTopbarItem" :topbarMenuActive="topbarMenuActive"
                 @menubutton-click="onMenuButtonClick" @topbar-menu-click="onTopbarMenuClick"
                 @topbar-submenu-click="onTopbarSubmenuClick"
                 @topbar-item-click="onTopbarItemClick" @topbar-menuitem-click="onTopbarMenuItemClick"
                 @search-item-click="onSearchItemClick"/>
      <!--  <router-view name="header" />-->
      <Sidebar></Sidebar>
      <div class="layout-content-wrapper flex-container non-scrollable" id="layout-content-wrapper">
        <div id="layout-content" class="layout-content flex-container non-scrollable">
          <slot/>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="min-h-screen flex relative lg:static surface-ground flex-grow-1">
    <Toast position="top-left"/>
    <slot/>
  </div>
  <Dialog v-model:visible="loading" :show-header="false" modal class="loading-dialog">
    <ProgressSpinner/>
  </Dialog>
  <DialogPreventForgetSaving :is-active="isPreventForgetSavingDialogIsActive"
                             @cancelChanges="cancelChanges" @saveChanges="saveChanges"
                             @closeWithoutSavingAndLeaving="closeWithoutSavingAndLeaving"/>
</template>

<script>
import DialogPreventForgetSaving from "@/components/DialogPreventForgetSaving.vue";
import {mapState} from "vuex";
import AppTopbar from "@/layouts/App/AppTopbar.vue";
import Sidebar from "@/layouts/App/Sidebar";

export default {
  name: "Browser",
  components: {DialogPreventForgetSaving, AppTopbar, Sidebar},
  props: {
    // layout: {
    //   required: true
    // }
  },
  data () {
    return {
      menuMode: 'slim',
      theme: 'indigo',
      layoutColor: 'white',
      tempLayoutColor: 'white',
      lightMenu: true,
      staticMenuMobileActive: false,
      staticMenuDesktopInactive: false,
      menuClick: false,
      topbarMenuClick: false,
      topbarMenuActive: false,
      activeTopbarItem: null,
      menuActive: false,
      searchClick: false
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      isLogged: state => state.auth.isLoggedIn,
      sideContent: state => state.sidebar.sideContent,
      loading: state => state.misc.loading,
      isPreventForgetSavingDialogIsActive: state => state.misc.isPreventForgetSavingDialogIsActive,
      routeToRedirect: state => state.misc.routeToRedirect,
      previousSideSelectedMenu: state => state.misc.previousSideSelectedMenu,
      previousSideSelectedContentMenu: state => state.misc.previousSideSelectedContentMenu,
      previousSideContent: state => state.misc.previousSideContent
    })
  },
  methods: {
    onDocumentClick() {
      if (!this.topbarItemClick && !this.searchClick) {
        this.activeTopbarItem = null;
      }

      if (!this.topbarMenuClick) {
        this.topbarMenuActive = false;
      }

      this.searchClick = false;
      this.topbarItemClick = false;
      this.topbarMenuClick = false;
      this.menuClick = false;
    },
    onMenuButtonClick(event) {
      this.menuClick = true;
      this.topbarMenuActive = false;

      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;

      event.preventDefault();
    },
    onTopbarItemClick(event) {
      this.topbarItemClick = true;


      if (this.activeTopbarItem === event.item)
        this.activeTopbarItem = null;
      else
        this.activeTopbarItem = event.item;

      event.originalEvent.preventDefault();
    },
    onSearchItemClick() {
      this.searchClick = true;
    },
    onTopbarMenuClick() {
      this.topbarMenuClick = true;
      this.topbarMenuActive = true;
    },
    onTopbarSubmenuClick(event) {
      this.topbarMenuClick = true;

      if (!event.item.items) {
        this.topbarMenuActive = false;
      }
    },
    onTopbarMenuItemClick(event) {
      this.topbarItemClick = true;

      if (this.activeTopbarItem === event.item)
        this.activeTopbarItem = null;
      else
        this.activeTopbarItem = event.item;

      event.originalEvent.preventDefault();
    },
    async closeWithoutSavingAndLeaving () {
      this.closePreventForgetSavingDialog()
    },
    async saveChanges() {
      this.closePreventForgetSavingDialog()
      this.$store.dispatch('misc/updateSaveFlag', true)
      process.nextTick(async () => {
        this.$store.dispatch('misc/updateSaveFlag', false)
        await this.resetFlags()
        this.$router.push(this.routeToRedirect)
      })
    },
    async cancelChanges() {
      this.closePreventForgetSavingDialog()
      await this.resetFlags()
      this.$router.push(this.routeToRedirect)
    },
    closePreventForgetSavingDialog() {
      this.$store.dispatch('misc/updateIsPreventForgetSavingDialog', false)
    },
    async resetFlags () {
      await this.$store.dispatch('misc/updateContentChanged', false)
    }
  }
}
</script>

<style scoped>

</style>

<style lang="scss" scoped>
@import '../assets/browserLayout';
</style>
