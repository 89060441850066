import data from "@/store/modules/constants/data";

export default {
    namespaced: true,

    state: {
        rowsPerPageOptions: [10,25,50,100],
        defaultRowNumber: 50,
        COLLECTIVE_CONVENTION: {
            SYNTEC: 'syntec',
            PORTAGE: 'portage',
        },
        ROLES: {
            ADMIN: 'Administrator',
            INDEPENDENT: 'Independent',
            PROSPECT: 'Prospect'
        },
        CLIENT_TYPES: {
            CONTRACT: 'CONTRACT',
            CONTACT: 'CONTACT',
            SIGNATORY: 'SIGNATORY',
            INVOICE: 'INVOICE'
        },
        SETTLEMENT_PERIOD_TYPES: {
            DAYS: 'Jours',
            DAYS_END_OF_MONTH: 'Jours fin de mois',
            ON_RECEPTION: 'A réception'
        },
        LINKER_TYPE: {
            SOCIAL_SECURITY_CODE: 'SOCIAL_SECURITY_CODE',
            PERSONAL_NUMBER: 'PERSONAL_NUMBER',
        },
        IKM_TRANSPORT_TYPE: {
            CAR: {
                label: 'Voiture',
                value: 'cars'
            },
            MOTORBIKE: {
                label: 'Moto',
                value: 'motorbikes'
            },
            MOTORCYCLE: {
                label: 'Cyclomoteur',
                value: 'motorcycles'
            },
            PUBLIC_TRANSPORT: {
                label: 'Transports en commun',
                value: 'publicTransport'
            }
        },
        SIMULATION_STATUS: {
            DRAFT: 'draft',
            TO_VALIDATE: 'toValidate',
            VALIDATED: 'validated',
            ARCHIVED: 'archived',
        },
        INDEPENDENT_STATUS: {
            DRAFT: 'draft',
            NEW: 'new',
            ON_GOING: 'ongoing',
            OUT_GOING: 'outgoing',
            OUT: 'out',
        },
        MISSION_STATUS: {
            DRAFT: 'draft',
            INCOMPLETE: 'incomplete',
            PENDING_CONTRACT: 'pendingContract',
            COMPLETE: 'complete',
            ARCHIVED: 'archived',
        },
        DOCUMENT_STATUS: {
            DRAFT: 'draft',
            PENDING_SIGNATURE: 'pendingSignature',
            CANCELLED: 'cancelled',
            SIGNED: 'signed',
            VALIDATED: 'validated',
            ARCHIVED: 'archived',
        },
        NDF_ADMIN_STATUS: {
            VALIDATE: "validate",
            REFUND: "refund",
            REFUNDED: "refunded"
        },
        NDF_INDEPENDENT_STATUS: {
            DRAFT: "draft",
            VALIDATED: "validated"
        },
        NDF_TYPE: {
            NDF: 'NDF',
            NDFR: 'NDFR',
            SOCIAL_MEASURES: 'SOCIAL_MEASURES',
            IKM: 'IKM',
        },
        FACTURE_FOURNISSEUR_ADMIN_STATUS: {
            REFUND: "refund", // todo: need to check if exist somewhere
            VALIDATE: "validate",
            REFUNDED: "refunded"
        },
        FACTURE_FOURNISSEUR_INDEPENDENT_STATUS: {
            DRAFT: "draft",
            VALIDATED: "validated"
        },
        INVOICE_STATUS: {
            DRAFT: 'draft',
            TO_INVOICE: 'toInvoice',
            INVOICED: 'invoiced'
        },
        WORK_CONTRACT_TYPE: {
            CDD: 'cdd',
            CDI: 'cdi',
        },
        CONTRACT_TYPE: {
            INTERNAL: 'internal',
            CLIENT: 'client',
        },
        CONTACT_TYPE: {
            CONTACT: 'contact',
            SIGNATORY: 'signatory',
        },
        PERSONAL_SITUATION_TYPE: {
            ALONE: 'alone',
            MARRIED: 'married',
            CONCUBINE: 'concubine',
            PACS: 'pacs'
        },
        MEDICAL_VISIT_TYPE: {
            LT_FIVE: 'belowFive',
            GT_FIVE: 'upperFive',
        },
        MUTUAL_TYPE: {
            INDIVIDUAL: 'individual',
            FAMILIAL: 'familial',
            NONE: 'none',
        },
        IBAN_TYPE: {
            SALARY: 'salary',
            NDF: 'ndf',
            BOTH: 'both',
        },
        NATIONALITY_TYPE: {
            FRENCH: 'french',
            EUROPEAN_UNION: 'european_union',
            EUROPEAN_UNION_OUT: 'european_union_out',
        },
        WORK_TIME: {
            FULL: 'full',
            PARTIAL: 'partial',
        },
        INDEPENDENT_WORK_STATUS_PORTAGE: {
            JUNIOR: 'Junior',
            SENIOR: 'Senior',
            FIXED_PRICE: 'Forfait',
            EXECUTIVE: 'cadre',
            NON_EXECUTIVE: 'nonCadre',
        },
        NEGOTIATED_RATE_UNITS: {
            DAILY_AVERAGE_PRICE: 'TJM',
            HOURLY_PRICE: 'TH',
            FIXED_PRICE: 'forfait',
        },
        INVOICE_TYPE: {
            MONTHLY: 'MONTHLY',
            OTHER: 'OTHER'
        },
        TELEWORKING_TYPE: {
            FULL: 'full',
            PARTIAL: 'partial',
            NONE: 'none',
        },
        RENEWAL_TYPE: {
            ON: 'on',
            OFF: 'off',
            AUTOMATIC: 'automatic'
        },
        PAY_STATUS: {
            DRAFT: 'noValidate',
            TO_PROCESS: 'toProcess',
            TO_EXPORT: 'toExport',
            EXPORTED: 'exported',
            IMPORTED: 'imported'
        },
        FACTURATION_STATUS: {
            DRAFT: 'noValidate',
            TO_PROCESS: 'toProcess',
            VALIDATED: 'validate'
        },
        SERVICE_STATUS: {
            TO_INVOICE: 'toInvoice',
            INVOICING: 'invoicing',
            INVOICED: 'invoiced',
        },
        BASE_TYPES: {
            BRUT_SALARY: 'brutSalary',
            MINIMUM_CONVENTIONAL_SALARY: 'MINIMUM_CONVENTIONAL_SALARY',
            SALARY_BASE: 'SALARY_BASE',
            PMSS: 'PMSS',
            CAF: 'CAF',
            CAP: 'CAP',
            CA_AFTER_TGF: 'CA_AFTER_TGF',
            CA_AFTER_TGP: 'CA_AFTER_TGP',
            CUSTOM: 'custom',
        },
        CRA_TYPES: {
          PAY: 'PAY',
          INVOICE: 'INVOICE'
        },
        SUBSCRIPTION_TRANSPORT_PERIODICITY: {
          NONE: 'NONE',
          YEARLY: 'YEARLY',
          MONTHLY: 'MONTHLY',
          WEEKLY: 'WEEKLY'
        },
        DOCUMENT_TYPE: {
            INDEPENDENT_DOCUMENT: 'independent_documents',
            // Mission contracts
            MISSION_CONTRACT: 'mission_contract',
            MISSION_CONTRACT_AMENDMENT: 'mission_contract_amendment',
            // Independent contracts
            WORK_CONTRACT: 'work_contract',
            WORK_CONTRACT_AMENDMENT: 'work_contract_amendment',
            // Independent documents
            IDENTITY_CARD_RECTO: 'identity_card_recto',
            IDENTITY_CARD_VERSO: 'identity_card_verso',
            PROOF_OF_RESIDENCE: 'proof_of_residence',
            IBAN_NDF: 'iban_ndf',
            IBAN_SALARY: 'iban_salary',
            CPAM_CERTIFICATION: 'cpam_certification',
            MUTUAL_PROOF: 'mutual_proof',
            MUTUAL_CERTIFICATION: 'mutual_certification',
            MEDICAL_VISIT: 'medical_visit',
            CURRICULUM_VITAE: 'curriculum_vitae',
            AGEFIP_CERTIFICATION: 'agefip_certification',
            PASSPORT: 'passport',
            RESIDENT_PERMIT: 'resident_permit',
            OTHER: 'other',
            // other type:
            RESTAURANT_TICKET_RENUNCIATION: 'restaurant_ticket_renunciation',
            // Independent payroll documents
            PAY_SLIP: 'payslip', // bulletin de salaire
            RESIGNATION_LETTER: 'quit_resignation_letter',
            DREETS_FORM: 'quit_dreets_form',
            AGREEMENT_DOCUMENT: 'quit_agreement_document',
            INTERVIEW_DOCUMENT: 'quit_interview_document',
            APPROVAL_CERTIFICATE: 'quit_approval_certificate',
            EMPLOYMENT_CERTIFICATE: 'quit_employment_certificate',
            FINAL_BALANCE: 'quit_final_balance',
            POLE_EMPLOI_CERTIFICATE: 'quit_pole_emploi_certificate',
            DPAE: 'dpae',
            SUBSCRIPTION_TRANSPORT_PROOF: 'subscription_transport_proof'
        },
        salarySheetDomainIds: { // must be all string different
            BUSINESS_INTRODUCER_BONUS: 'BUSINESS_INTRODUCER_BONUS',
            PRECARIOUSNESS_BONUS: 'PRECARIOUSNESS_BONUS',
            PAID_HOLIDAYS_BONUS: 'PAID_HOLIDAYS_BONUS',
            CONVENTIONAL_VACANCY_BONUS: 'CONVENTIONAL_VACANCY_BONUS',

            SOCIAL_SECURITY_ILLNESS_MATERNITY_DISABILITY_DEATH: 'SOCIAL_SECURITY_ILLNESS_MATERNITY_DISABILITY_DEATH',
            COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_A: 'COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_A',
            COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_B: 'COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_B',
            COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_C: 'COMPLEMENTARY_INCAPACITY_INVALIDITY_DEATH_C',
            COMPULSORY_MUTUAL: 'COMPULSORY_MUTUAL',

            WORK_ACCIDENTS_AND_OCCUPATIONAL_DISEASES: 'WORK_ACCIDENTS_AND_OCCUPATIONAL_DISEASES',

            CAPPED_SOCIAL_SECURITY: 'CAPPED_SOCIAL_SECURITY',
            UNCAPPED_SOCIAL_SECURITY: 'UNCAPPED_SOCIAL_SECURITY',
            COMPLEMENTARY_A: 'COMPLEMENTARY_A',
            COMPLEMENTARY_B: 'COMPLEMENTARY_B',

            AF: 'AF',

            UNEMPLOYMENT: 'UNEMPLOYMENT',
            EMPLOYEE_ASSOCIATION_FOR_GUARANTEE: 'EMPLOYEE_ASSOCIATION_FOR_GUARANTEE',
            EXECUTIVE_ASSOCIATION_EMPLOYMENT: 'EXECUTIVE_ASSOCIATION_EMPLOYMENT',

            GENERAL_SOCIAL_CONTRIBUTION: 'GENERAL_SOCIAL_CONTRIBUTION',
            ADESATT: 'ADESATT',
            STATUARY_FEE: 'STATUARY_FEE',
            CSG_CRDS: 'CSG_CRDS',

            OTHER_DUE_EMPLOYER_CONTRIBUTION: 'OTHER_DUE_EMPLOYER_CONTRIBUTION',
            AUTONOMY_SOLIDARITY_CONTRIBUTION: 'AUTONOMY_SOLIDARITY_CONTRIBUTION',
            UNCAPPED_FNAL: 'UNCAPPED_FNAL',
            MOBILITY_TRANSFER: 'MOBILITY_TRANSFER',
            SOCIAL_DIALOG_CONTRIBUTION: 'SOCIAL_DIALOG_CONTRIBUTION',
            PRO_FORMATION_CONTRIBUTION: 'PRO_FORMATION_CONTRIBUTION',
            CONSTRUCTION_EFFORT_PARTICIPATION: 'CONSTRUCTION_EFFORT_PARTICIPATION',
            LEARNING_TAXES: 'LEARNING_TAXES',
            OTHER_DUE_EMPLOYER_CONTRIBUTION_PROVISIONING_SOCIAL_FEE: 'OTHER_DUE_EMPLOYER_CONTRIBUTION_PROVISIONING_SOCIAL_FEE',
        },
        TELEWORKING_BASE_DESCRIPTION: 'Le télétravail sera prévu en fonction des missions et des dispositions prévues par le client ou le client final',
        REGEX_VALIDATORS: {
            EMAIL: /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/,
            SOCIAL_SECURITY_CODE: /^([1-37-8])([0-9]{2})(0[0-9]|[2-35-9][0-9]|[14][0-2])((0[1-9]|[1-8][0-9]|9[0-69]|2[abAB])(00[1-9]|0[1-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|990)|(9[78][0-9])(0[1-9]|[1-8][0-9]|90))(00[1-9]|0[1-9][0-9]|[1-9][0-9]{2})(0[1-9]|[1-8][0-9]|9[0-7])$/
        }
    },
    mutations: {
        // todo: Should make a function which takes as input the value and the array of option to make the link and automatically return the label (prevent duplicating some formatState, formatStatus ... etc)
        // use it to init the default array of options
        INIT_DEFAULT_STATE_WITH_CONSTANTS(state) {
            state.data.SYNTEC_STATUS = [
                {label: 'Cadre', value: state.INDEPENDENT_WORK_STATUS_PORTAGE.EXECUTIVE},
                {label: 'Non cadre', value: state.INDEPENDENT_WORK_STATUS_PORTAGE.NON_EXECUTIVE}
            ]
            state.data.PORTAGE_STATUS = [
                {label: 'Junior', value: state.INDEPENDENT_WORK_STATUS_PORTAGE.JUNIOR},
                {label: 'Senior', value: state.INDEPENDENT_WORK_STATUS_PORTAGE.SENIOR},
                {label: 'Forfait', value: state.INDEPENDENT_WORK_STATUS_PORTAGE.FIXED_PRICE}
            ]
            state.data.contractTypeOptions = [
                {label: 'Interne', value: state.CONTRACT_TYPE.INTERNAL},
                {label: 'Client', value: state.CONTRACT_TYPE.CLIENT}
            ]
            state.data.independentOptions = [
                {label: 'À traiter' , value: state.INDEPENDENT_STATUS.DRAFT},
                {label: 'Nouveau' , value: state.INDEPENDENT_STATUS.NEW},
                {label: 'En cours', value: state.INDEPENDENT_STATUS.ON_GOING},
                {label: 'Sortant' , value: state.INDEPENDENT_STATUS.OUT_GOING},
                {label: 'Sorti' , value: state.INDEPENDENT_STATUS.OUT},
            ]
            state.data.serviceOptions = [
                {label: 'À facturer', value: state.SERVICE_STATUS.TO_INVOICE},
                {label: 'En cours' , value: state.SERVICE_STATUS.INVOICING},
                {label: 'Facturé' , value: state.SERVICE_STATUS.INVOICED},
            ]
            state.data.payOptions = [
                {label: 'En attente du porté' , value: state.PAY_STATUS.DRAFT},
                {label: 'À traiter' , value: state.PAY_STATUS.TO_PROCESS},
                {label: 'À exporter', value: state.PAY_STATUS.TO_EXPORT},
                {label: 'Exporté' , value: state.PAY_STATUS.EXPORTED},
                {label: 'Importé' , value: state.PAY_STATUS.IMPORTED}
            ]
            state.data.ndfOptions = [
                {label: 'En attente du porté' , value: state.NDF_INDEPENDENT_STATUS.DRAFT},
                {label: 'À traiter' , value: state.NDF_INDEPENDENT_STATUS.VALIDATED}
            ]
            state.data.subscriptionTransportPeriodicityOptions = [
                {label: 'Aucune' , value: state.SUBSCRIPTION_TRANSPORT_PERIODICITY.NONE},
                {label: 'Annuel' , value: state.SUBSCRIPTION_TRANSPORT_PERIODICITY.YEARLY},
                {label: 'Mensuel' , value: state.SUBSCRIPTION_TRANSPORT_PERIODICITY.MONTHLY},
                {label: 'Hebdomadaire' , value: state.SUBSCRIPTION_TRANSPORT_PERIODICITY.WEEKLY}
            ]
            state.data.documentOptions = [
                {label: 'Signé' , value: state.DOCUMENT_STATUS.SIGNED},
                {label: 'Signé', value: state.DOCUMENT_STATUS.VALIDATED},
                {label: 'En attente de signature' , value: state.DOCUMENT_STATUS.PENDING_SIGNATURE},
                {label: 'Non existant' , value: null}
            ]
            state.data.facturationOptions = [
                {label: 'Brouillon' , value: state.FACTURATION_STATUS.DRAFT},
                {label: 'Validé', value: state.FACTURATION_STATUS.VALIDATED},
            ]
            state.data.ndfTypeOptions = [
                {label: 'NDF' , value: state.NDF_TYPE.NDF},
                {label: 'NDFR' , value: state.NDF_TYPE.NDFR},
                {label: 'Indemnités kilométriques' , value: state.NDF_TYPE.IKM},
                {label: 'Dispositifs sociaux' , value: state.NDF_TYPE.SOCIAL_MEASURES},
            ]
            state.data.personalSituationOptions = [
                {name: "Célibataire", value: state.PERSONAL_SITUATION_TYPE.ALONE},
                {name: 'Marié', value: state.PERSONAL_SITUATION_TYPE.MARRIED},
                {name: 'Concubin', value: state.PERSONAL_SITUATION_TYPE.CONCUBINE},
                {name: 'Pacsé', value: state.PERSONAL_SITUATION_TYPE.PACS}
            ]
            state.data.medicalVisitOptions = [
                {name: '-5 ans', value: state.MEDICAL_VISIT_TYPE.LT_FIVE},
                {name: '+5 ans', value: state.MEDICAL_VISIT_TYPE.GT_FIVE}
            ]
            state.data.mutualOptions = [
                {name: 'Individuelle', value: state.MUTUAL_TYPE.INDIVIDUAL},
                {name: 'Familiale', value: state.MUTUAL_TYPE.FAMILIAL},
                {name: 'Aucune', value: state.MUTUAL_TYPE.NONE}
            ]
            state.data.IBANOptions = [
                {name: 'Salaire', value: state.IBAN_TYPE.SALARY},
                {name: 'NDF', value: state.IBAN_TYPE.NDF},
                {name: 'Les deux', value: state.IBAN_TYPE.BOTH},
            ]
            // change due to specification about another type of contractOption
            state.data.contractOption = [
                {name: 'CDD de portage salarial', value: state.WORK_CONTRACT_TYPE.CDD},
                {name: 'CDI de portage salarial', value: state.WORK_CONTRACT_TYPE.CDI}
            ]
            state.data.defaultBaseOptions = [
                state.BASE_TYPES.BRUT_SALARY,
                state.BASE_TYPES.PMSS
            ]
            state.data.baseOptions = [
                {label: 'Salaire Brut', type: state.BASE_TYPES.BRUT_SALARY},
                {label: 'Salaire de base minimum conventionnel', type: state.BASE_TYPES.MINIMUM_CONVENTIONAL_SALARY},
                {label: 'Salaire de base + complément de salaire', type: state.BASE_TYPES.SALARY_BASE},
                {label: 'PMSS', type: state.BASE_TYPES.PMSS},
                {label: 'CA Facturé', type: state.BASE_TYPES.CAF}, // Chiffre d'affaire facture
                {label: 'CA Payé', type: state.BASE_TYPES.CAP}, // Chiffre d'affaire Payé
                {label: 'CA après Tarif Gestion facturé', type: state.BASE_TYPES.CA_AFTER_TGF}, // Chiffre d'affaire après tarif de gestion facturé
                {label: 'CA après Tarif Gestion payé', type: state.BASE_TYPES.CA_AFTER_TGP}, // Chiffre d'affaire après tarif de gestion payé
                {label: 'Choisir ma valeur', type: state.BASE_TYPES.CUSTOM}, // Chiffre d'affaire après tarif de gestion payé
            ]
            state.data.renewalOptions = [
                {label: 'Oui', value: state.RENEWAL_TYPE.ON},
                {label: 'Non', value: state.RENEWAL_TYPE.OFF},
                {label: 'Tacite reconduction', value: state.RENEWAL_TYPE.AUTOMATIC}
            ]
            state.data.allDocuments = [ // todo: need to add all the existing document
                {
                    labelKey: 'rectoIdentityCard',
                    icon: 'carteIdentite.svg',
                    type: state.DOCUMENT_TYPE.IDENTITY_CARD_RECTO,
                    multiple: false,
                    mandatory: true,
                    customType: false
                },
                {
                    labelKey: 'versoIdentityCard',
                    icon: 'carteIdentite.svg',
                    type: state.DOCUMENT_TYPE.IDENTITY_CARD_VERSO,
                    multiple: false,
                    mandatory: true,
                    customType: false
                },
                {
                    labelKey: 'proofResidence',
                    icon: 'justifDomicile.svg',
                    type: state.DOCUMENT_TYPE.PROOF_OF_RESIDENCE,
                    multiple: false,
                    mandatory: true,
                    customType: false
                },
                {
                    labelKey: 'ibanSalary',
                    icon: 'dpae.svg',
                    type: state.DOCUMENT_TYPE.IBAN_SALARY,
                    multiple: false,
                    mandatory: true,
                    customType: false
                },
                {
                    labelKey: 'ibanNdf',
                    icon: 'dpae.svg',
                    type: state.DOCUMENT_TYPE.IBAN_NDF,
                    multiple: false,
                    mandatory: true,
                    customType: false
                },
                {
                    labelKey: 'cpamCertification',
                    icon: 'attestCpam.svg',
                    type: state.DOCUMENT_TYPE.CPAM_CERTIFICATION,
                    multiple: false,
                    mandatory: true,
                    customType: false
                },
                {
                    labelKey: 'mutualProof',
                    icon: 'attestCpam.svg',
                    type: state.DOCUMENT_TYPE.MUTUAL_PROOF,
                    multiple: false,
                    mandatory: true,
                    customType: false
                },
                {
                    labelKey: 'mutualCertification',
                    icon: 'attestCpam.svg',
                    type: state.DOCUMENT_TYPE.MUTUAL_CERTIFICATION,
                    multiple: false,
                    mandatory: true,
                    customType: false
                },
                {
                    labelKey: 'medicalVisit',
                    icon: 'attestCpam.svg',
                    type: state.DOCUMENT_TYPE.MEDICAL_VISIT,
                    multiple: false,
                    mandatory: false,
                    customType: false
                },
                {
                    labelKey: 'curriculumVitae',
                    icon: 'cv.svg',
                    type: state.DOCUMENT_TYPE.CURRICULUM_VITAE,
                    multiple: false,
                    mandatory: true,
                    customType: false
                },
                {
                    labelKey: 'agefipCertification',
                    icon: 'attestCpam.svg',
                    type: state.DOCUMENT_TYPE.AGEFIP_CERTIFICATION,
                    multiple: false,
                    mandatory: true,
                    customType: false
                },
                {
                    labelKey: 'passport',
                    icon: 'cv.svg',
                    type: state.DOCUMENT_TYPE.PASSPORT,
                    multiple: false,
                    mandatory: true,
                    customType: false
                },
                {
                    labelKey: 'residentPermit',
                    icon: 'cv.svg',
                    type: state.DOCUMENT_TYPE.RESIDENT_PERMIT,
                    multiple: false,
                    mandatory: true,
                    customType: false
                },
                {
                    labelKey: 'other',
                    icon: 'otherFile.svg',
                    type: state.DOCUMENT_TYPE.OTHER,
                    multiple: true,
                    mandatory: false,
                    customType: true
                },
                {
                    labelKey: 'dpae',
                    icon: 'otherFile.svg',
                    type: state.DOCUMENT_TYPE.DPAE,
                    multiple: false,
                    mandatory: false,
                    customType: false
                }
            ]
            state.data.contactOptions = [
                {labelKey: 'contactType', value: state.CONTACT_TYPE.CONTACT},
                {labelKey: 'signatoryType', value: state.CONTACT_TYPE.SIGNATORY}
            ]
            state.data.negotiatedRateUnitOptions = [
                state.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE,
                state.NEGOTIATED_RATE_UNITS.HOURLY_PRICE,
                state.NEGOTIATED_RATE_UNITS.FIXED_PRICE
            ]
        }
    },
    actions: {
        async initDefaultStateWithConstants(store) {
            store.commit('INIT_DEFAULT_STATE_WITH_CONSTANTS')
        }
    },

    modules: {
        data
    }
}
