import {setDateWithoutHours} from "@/utils/Date";

export const formatFromApi = (ikmI) => {
    let ikm = JSON.parse(JSON.stringify(ikmI))
    ikm.distanceTotale = (ikm.distanceTrajet * ikm.nombreTrajets).toFixed(2)
    ikm.dates = ikm.dates.map(d => new Date(d))
    return ikm
}

export const formatToApi = (ikmI) => {
    let ikm = JSON.parse(JSON.stringify(ikmI))
    if (ikm.date) {
        ikm.date = setDateWithoutHours(ikm.date)
    }
    if (ikm.dates) {
        ikm.dates.map(date => setDateWithoutHours(date))
    }
    return ikm
}
