import axios from '@/plugins/axios'

export default {
  namespaced: true,

  state: {
    commercials: []
  },
  mutations: {
    SET_COMMERCIALS(state, value) {
      state.commercials = value.data;
    }
  },
  actions: {
    // eslint-disable-next-line
    async getAllCommercials({ commit }) {
      return new Promise((resolve, reject) => {
        axios.api.get('/commercial').then(res => {
          commit('SET_COMMERCIALS', res.data)
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async getOneCommercial({ commit }, data) {
      return await axios.api.get('/commercial/' + data).then(res => {
        return res.data
      }).catch(() => {
      })
    },
  },
}
