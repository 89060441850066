import axios from '@/plugins/axios'
import { formatFromApi, formatToApi } from '@/utils/IkmUtil'

export default {
  namespaced: true,

  state: {
    ikm: [],
    ikms: []
  },
  getters: {
    getIkms (state) {
      return state.ikms
    },
    getIkm (state) {
      return state.ikm
    },
  },
  mutations: {
    SET_IKMS(state, value) {
      state.ikms = value
    },
    SET_IKM(state, value) {
      state.ikm = value
    }
  },
  actions: {
    // eslint-disable-next-line
    async getAllIkmsByIndependent({ commit }, independentId) {
      return new Promise((resolve, reject) => {
        axios.api.get('/ikm/independent/' + independentId).then(res => {
          let results = res.data.map((ikm) => formatFromApi(ikm))
          commit('SET_IKMS', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createIkm({ commit }, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.post('/ikm', data).then(res => {
          let results = formatFromApi(res.data)
          commit('SET_IKM', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteIkm(_, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.delete('/ikm/' + data.id).then(res => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateIkm({ commit }, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.put('/ikm/' + data.id, data).then(res => {
          let results = formatFromApi(res.data)
          commit('SET_IKM', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async assignIkm({ commit }, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.post('/ikm/' + data.id + '/assign', data).then(res => {
          let results = formatFromApi(res.data)
          commit('SET_IKM', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async unassignIkm({ commit }, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.post('/ikm/' + data.id + '/unassign', data).then(res => {
          let results = formatFromApi(res.data)
          commit('SET_IKM', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    }
  },
}
