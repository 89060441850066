<template>
  <div class="flex flex-row justify-content-evenly">
    <div class="flex flex-column">
      <div>
        <label>Nom: </label>
        <label class="font-bold">{{ prospectMetadata.clientContact.fullName }}</label>
      </div>
      <div>
        <label>Téléphone: </label>
        <label class="font-bold">{{ formatPhoneNumber(prospectMetadata.clientContact.phone) }}</label>
      </div>
      <div>
        <label>Email: </label>
        <label class="font-bold">{{ prospectMetadata.clientContact.email }}</label>
      </div>
    </div>
    <div class="flex flex-column">
      <div>
        <label>Raison sociale: </label>
        <label class="font-bold">{{ prospectMetadata.clientContract.name }}</label>
      </div>
      <div>
        <label>SIREN / SIRET: </label>
        <label class="font-bold">{{ prospectMetadata.clientContract.siret }}</label>
      </div>
    </div>
  </div>

</template>

<script>
import {formatPhoneNumber} from "@/utils/Misc";

export default {
  name: "ProspectHelper",
  props: {
    prospectMetadata: {
      type: Object,
      default: null,
    }
  },
  methods: {
    formatPhoneNumber
  }
}
</script>

<style scoped>

</style>
