import axios from '@/plugins/axios'
import { formatFromApi, formatToApi } from '@/utils/RegularisationUtil'

export default {
  namespaced: true,

  state: {
    regularisations: []
  },
  getters: {
    getRegularisations (state) {
      return state.regularisations
    },
  },
  mutations: {
    SET_REGULARISATIONS(state, value) {
      state.regularisations = value
    },
  },
  actions: {
    // eslint-disable-next-line
    async getAllRegularisations({ commit }, independentId) {
      return new Promise((resolve, reject) => {
        axios.api.get('/regularisation').then(res => {
          let results = res.data.map((ikm) => formatFromApi(ikm))
          commit('SET_REGULARISATIONS', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createRegularisation(_, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.post('/regularisation', data).then(res => {
          let results = formatFromApi(res.data)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteRegularisation(_, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.delete('/regularisation/' + data.id).then(res => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateRegularisation(_, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.put('/regularisation/' + data.id, data).then(res => {
          let results = formatFromApi(res.data)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async importCsv (store, data) {
      return new Promise((resolve, reject) => {
        axios.api.post('/regularisation/import', data).then(async (res) => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
