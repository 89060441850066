<template>
  <div>
    <div v-if="contact?.id"
         class="grid formgrid p-fluid">
      <div v-if="me.role.name === constants.ROLES.INDEPENDENT" class="field mb-3 col-12 md:col-3">
        <span class="text-600 font-medium">Nom :</span>
      </div>
      <div v-if="me.role.name === constants.ROLES.INDEPENDENT" class="field mb-3 col-12 md:col-9">
        <span class="text-lg text-900 font-medium">{{ contact?.last_name ?? '' }}</span>
      </div>
      <div v-if="me.role.name === constants.ROLES.INDEPENDENT" class="field mb-3 col-12 md:col-3">
        <span class="text-600 font-medium">Prénom :</span>
      </div>
      <div v-if="me.role.name === constants.ROLES.INDEPENDENT" class="field mb-3 col-12 md:col-9">
        <span class="text-lg text-900 font-medium">{{ contact?.first_name ?? '' }}</span>
      </div>
      <div class="field mb-3 col-12 md:col-3">
        <span class="text-600 font-medium">Téléphone :</span>
      </div>
      <div class="field mb-3 col-12 md:col-9">
        <span class="text-lg text-900 font-medium">{{ contact?.phone ?? '' }}</span>
      </div>
      <div class="field mb-3 col-12 md:col-3">
        <span class="text-600 font-medium">Email :</span>
      </div>
      <div class="field mb-3 col-12 md:col-9">
        <span class="text-lg text-900 font-medium">{{ contact?.email ?? '' }}</span>
      </div>
      <div v-if="me.role.name === constants.ROLES.INDEPENDENT" class="field mb-3 col-12 md:col-3">
        <span class="text-600 font-medium">Position :</span>
      </div>
      <div v-if="me.role.name === constants.ROLES.INDEPENDENT" class="field mb-3 col-12 md:col-9">
        <span class="text-lg text-900 font-medium">{{ contact?.position ?? '' }}</span>
      </div>
    </div>
    <div v-else>
      <div class="field mb-3 col-12 md:col-9 ">
        <span class="text-lg text-900 font-medium">Aucun contact défini</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ContactInfo",
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
      me: state => state.auth.me
    })
  }
}
</script>

<style scoped>

</style>
