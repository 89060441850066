const getDaysInMonthUTC = function (month, year) {
    var date = new Date(Date.UTC(year, month, 1));
    var days = [];
    while (date.getUTCMonth() == month) {
        days.push(new Date(date));
        date.setUTCDate(date.getUTCDate() + 1);
    }
    return days;
}

export default {
    namespaced: true,

    state: {
        crasPay: [],
        crasFacturation: [],
        date: {
            loaded: false,
            days: [],
            month: 0,
            year: 2022,
        }
    },
    getters: {
        getDate(state) {
            return state.date
        }
    },
    mutations: {
        SET_CRAS_PAY(state, data) {
          state.crasPay = data
        },
        UPDATE_DATE(state, date) {
            state.date = date
        }
    },
    actions: {
        updateDate({commit}, data) {
            let date = {
                month: parseInt(data.month),
                year: parseInt(data.year),
                days: getDaysInMonthUTC(data.month, data.year)
            }
            commit('UPDATE_DATE', date);
        }
    }
}