import axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
    // serve only to reset the values if the user clicked on the don't change anything button
    previousSideContent: null,
    previousSideSelectedMenu: null,
    previousSideSelectedContentMenu: null,

    // serve as a flag on the router-before-leave to know if there's still content unsaved
    contentChanged: false,
    // on true, automatically open a dialog which purpose to save or cancel the changes done
    isPreventForgetSavingDialogIsActive: false,
    // flag to notify all the listener what to do,
    // this flag will make a rapid transition false to true, then false, to notify all the listener if they should register their actual content before leaving
    // (listeners are the watchers of this property)
    SAVE_FLAG: false,
    routeToRedirect: null,

    SAME_ROUTE_CLICKED_FLAG: true,

    QR_CODE_SCAN_FLAG: false,
    QR_CODE_FORCE_CAMERA_FLAG: false,

    loading: false,
    legalStatusCodesReference: null,
    settings: null
  },
  mutations: {
    SET_LOADING(state, val) {
      state.loading = val
    },
    SET_SETTINGS (state, data) {
      state.settings = data
    },
    UPDATE_SETTINGS (state, data) {
      // data overwrite state settings while keeping all props
      state.settings = { ...state.settings, ...data }
    },
    UPDATE_CONTENT_CHANGED (state, data) {
      state.contentChanged = data
    },
    UPDATE_IS_PREVENT_FORGET_SAVING_DIALOG_IS_ACTIVE (state, data) {
      state.isPreventForgetSavingDialogIsActive = data.isActive
      if (data.isActive) {
        state.routeToRedirect = data.routeToRedirect
      }
    },
    UPDATE_SAVE_FLAG (state, data) {
      state.SAVE_FLAG = data
    },
    UPDATE_SAME_ROUTE_CLICKED_FLAG (state, data) {
      state.SAME_ROUTE_CLICKED_FLAG = data
    },
    UPDATE_PREVIOUS_SIDE_SELECTED_MENU (state, data) {
      state.previousSideSelectedMenu = data
    },
    UPDATE_PREVIOUS_SIDE_SELECTED_CONTENT_MENU (state, data) {
      state.previousSideSelectedContentMenu = data
    },
    UPDATE_PREVIOUS_SIDE_CONTENT (state, data) {
      state.previousSideContent = data
    },
    UPDATE_QR_CODE_SCAN_FLAG (state, data) {
      state.QR_CODE_SCAN_FLAG = data
    },
    UPDATE_QR_CODE_FORCE_CAMERA_FLAG (state, data) {
      state.QR_CODE_FORCE_CAMERA_FLAG = data
    },
    UPDATE_LEGAL_STATUS_CODES_REFERENCE (state, data) {
      state.legalStatusCodesReference = data
    }
  },
  getters: {
    getLoading (state) {
      return state.loading
    },
    getSettings (state) {
      return state.settings
    }
  },
  actions: {
    getLegalStatusCodesReference({ commit }) {
      return new Promise((resolve, reject) => {
        axios.api.get(`/settings/status-codes-reference`).then(results => {
          commit('UPDATE_LEGAL_STATUS_CODES_REFERENCE', results.data)
          resolve(results.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // token and simulation are optional
    getAllSettings ({ commit }, { token, simulation }) {
      return new Promise((resolve, reject) => {
        axios.api.get(`/settings${!token ? '' : `?token=${token}`}${(!token ? '?' : '&') + (!simulation ? '' : `simulation=${simulation}`)}`).then(results => {
          commit('SET_SETTINGS', results.data)
          resolve(results.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getCompanyDocumentsShared ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.api.get('/settings/company-document-shared').then(results => {
          resolve(results.data.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getDefaultFooterTemplate ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.api.get('/pdf-generator/footer-template').then(results => {
          console.log('getDefaultFooterTemplate', results)
          resolve(results.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getFormattedTemplate ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.post('/pdf-generator/format-template', { template: data }).then(results => {
          console.log('format-template', results)
          resolve(results.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    getGlobalSummary (_, data) {
      return new Promise((resolve, reject) => {
        axios.api.post('/settings/global-summary', { range: data.range }).then(results => {
          resolve(results.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    updateSettings ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.put('/settings', data, {}).then(results => {
          commit('SET_SETTINGS', results.data)
          resolve(results.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    updateCompanySettings ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.put('/settings/company', data, {}).then(results => {
          commit('SET_SETTINGS', results.data)
          resolve(results.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    updateRhSettings ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.put('/settings/human-resources', data, {}).then(results => {
          commit('SET_SETTINGS', results.data)
          resolve(results.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    updateCommercialSettings ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.put('/settings/commercial', data, {}).then(results => {
          commit('SET_SETTINGS', results.data)
          resolve(results.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    updateContentChanged ({commit}, data) {
      commit('UPDATE_CONTENT_CHANGED', data)
    },
    updateIsPreventForgetSavingDialog ({commit}, data) {
      commit('UPDATE_IS_PREVENT_FORGET_SAVING_DIALOG_IS_ACTIVE', data)
    },
    updateSaveFlag ({commit}, data) {
      commit('UPDATE_SAVE_FLAG', data)
    },
    updateSameRouteClickedFlag ({commit}, data) {
      commit('UPDATE_SAME_ROUTE_CLICKED_FLAG', data)
    },
    updatePreviousSideSelectedMenu ({commit}, data) {
      commit('UPDATE_PREVIOUS_SIDE_SELECTED_MENU', data)
    },
    updatePreviousSideSelectedContentMenu ({commit}, data) {
      commit('UPDATE_PREVIOUS_SIDE_SELECTED_CONTENT_MENU', data)
    },
    updatePreviousSideContent ({commit}, data) {
      commit('UPDATE_PREVIOUS_SIDE_CONTENT', data)
    },
    updateQrCodeScanFlag ({commit}, data) {
      commit('UPDATE_QR_CODE_SCAN_FLAG', data)
    },
    updateQrCodeForceCameraFlag ({commit}, data) {
      commit('UPDATE_QR_CODE_FORCE_CAMERA_FLAG', data)
    }
  }
}
