import Alert from "@/utils/Alert";

const defaultErrorRequestKey = 'defaultErrorKey'

// may just concat the all field into one and then use it as the errorKey ? Might have some issues if the request error result
// can inject the invalid value in the errorKey if needed -- see how work translation
const errorRequests = [
    {
        code: 'RECORD_NOT_UNIQUE',
        collection: 'Client',
        field: 'siret',
        errorKey: 'clientSiretNotUnique',
    },
    {
        code: 'RECORD_NOT_UNIQUE',
        collection: 'Client',
        field: "\"identificationNumber\"",
        errorKey: 'clientIdentificationNumberNotUnique',
    },
    {
        code: 'RECORD_NOT_UNIQUE',
        collection: 'Contact',
        field: 'email',
        errorKey: 'contactEmailNotUnique',
    }
]

const error = (context, error) => {
    // todo: return an array of all the errorKey for each field ?
    if (error.response?.data?.errors?.length === 0) {
        return
    }
    error.response?.data?.errors?.forEach((error) => {
        const key = (errorRequests.find((errorRequest) => {
            return error.extensions.code === errorRequest.code
                && error.extensions.collection === errorRequest.collection
                && error.extensions.field === errorRequest.field
        }))?.errorKey ?? defaultErrorRequestKey
        Alert.errorMessage(context, key)
    })
}

export default {
    error,
    defaultErrorRequestKey
}
