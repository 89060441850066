<template>
  <Dialog
      v-model:visible="dialogActive"
      class="dialog-file-preview"
      :breakpoints="{'960px': '75vw', '640px': '90vw'}"
      :style="{width: '50vw'}"
      :draggable="false"
      :close-on-escape="false"
      modal
      :pt="{
        header: { class: 'align-items-start text-center' },
      }"
  >
    <template #header>
      <div style="width: 100%; text-align: center;">
        <i class="pi pi-check-circle text-green-500 text-4xl"></i>
        <h3 class="text-black-alpha-90">Souhaitez-vous quitter sans sauvegarder ?</h3>
      </div>
    </template>
    <template #closeicon>
        <Button icon="pi pi-times" class="p-dialog-header-icon p-dialog-header-close p-link mr-0" @click="closeWithoutSavingAndLeaving"></Button>
    </template>
    <div class="text-green-500 mt-3" style="text-align: center; font-size: 1.2rem;">
      En sauvegardant vos modifications seront prises en compte.
    </div>
    <div style="text-align: center;" class="mt-2 mb-3">
      Vos modifications ne seront pas prises en compte si vous annulez.
    </div>
    <template #footer>
      <Button label="Annuler mes changements" class="p-button-text" @click="cancelChanges"/>
      <Button label="Sauvegarder mes changements" autofocus @click="saveChanges"/>
    </template>
  </Dialog>
</template>

<script>
export default {
  name: "DialogPreventForgetSaving",
  emits: ['saveChanges', 'cancelChanges', 'closeWithoutSavingAndLeaving'],
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data () {
    return {
      dialogActive: false
    }
  },
  watch: {
    isActive (value) {
      this.dialogActive = value
    }
  },
  methods: {
    closeWithoutSavingAndLeaving () {
      this.$emit('closeWithoutSavingAndLeaving')
    },
    cancelChanges () {
      this.$emit('cancelChanges')
    },
    saveChanges () {
      this.$emit('saveChanges')
    }
  }
}
</script>

<style scoped>

</style>
