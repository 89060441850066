import axios from '@/plugins/axios'
import { formatFromApi, formatToApi } from '@/utils/VehicleUtil'

export default {
  namespaced: true,

  state: {
    vehicle: [],
    vehicles: []
  },
  getters: {
    getVehicles (state) {
      return state.vehicles
    },
    getVehicle (state) {
      return state.vehicle
    },
  },
  mutations: {
    SET_VEHICLES(state, value) {
      state.vehicles = value
    },
    SET_VEHICLE(state, value) {
      state.vehicle = value
    }
  },
  actions: {
    // eslint-disable-next-line
    async getAllVehiclesByIndependent({ commit }, independentId) {
      return new Promise((resolve, reject) => {
        axios.api.get('/vehicle/independent/' + independentId).then(res => {
          let results = res.data.map((ikm) => formatFromApi(ikm))
          commit('SET_VEHICLES', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createVehicle({ commit }, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.post('/vehicle', data).then(res => {
          let results = formatFromApi(res.data)
          commit('SET_VEHICLE', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteVehicle(_, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.delete('/vehicle/' + data.id).then(res => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateVehicle({ commit }, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.put('/vehicle/' + data.id, data).then(res => {
          let results = formatFromApi(res.data)
          commit('SET_VEHICLE', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
