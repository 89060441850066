// import {store} from "@/store";
// console.log(store.state.target.auth.me)
// console.log(store)
export default [
    {
        path: '/mobile-society-access',
        name: 'MobileSocietyAccess',
        component: () => import('/src/views/Pages/MobileSocietyAccess'),
        meta: { requiresAuth: false }
    },
    {
        path: '/qr-code-scanner',
        name: 'QrCodeScanner',
        component: () => import('/src/views/Pages/QrCodeScanner'),
        meta: { requiresAuth: false }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('/src/views/Pages/Login'),
        meta: { requiresAuth: false }
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('/src/views/Pages/ResetPassword'),
        meta: { requiresAuth: false }
    },
    {
        path: '/change-password/:token',
        name: 'ChangePassword',
        component: () => import('/src/views/Pages/ChangePassword'),
        meta: { requiresAuth: false }
    },
    {
        path: '/register/:idInde/:token',
        name: 'Register',
        component: () => import('/src/views/Pages/Register'),
        meta: { requiresAuth: false }
    },
    {
        path: '/accueil',
        name: 'Home',
        component: () => import('/src/views/Pages/Home'),
        meta: {
            requiresPermission: false,
            sideContentMenu : {
                isVisible: false,
            }
        }
    },
    {
        path: '/profil',
        name: 'Profil',
        component: () => import('/src/views/Pages/Prospect/Profil'),
        meta: {
            requiresPermission: false,
            sideContentMenu : {
                isVisible: false,
            }
        }
    },
    {
        path: '/user/profile',
        name: 'UserProfile',
        component: () => import('/src/views/Pages/UserProfile'),
        meta: { requiresPermission: false }
    },
    {
        path: '/expense-account-image',
        name: 'ExpenseAccountImage',
        component: () => import('/src/views/Pages/Porte/ExpenseAccountMobile.vue'),
        meta: { requiresPermission: false }
    },
    {
        path: '/ndf-mobile',
        name: 'NdfMobile',
        component: () => import('/src/views/Pages/Porte/NdfMobile.vue'),
        meta: { requiresPermission: false }
    },
    {
        path: '/commercial',
        name: 'Commercial',
        meta: {
            sideContentMenu : {
                isVisible: true,
                currentPageTitle: 'sidebar.title.commercial',
                showIndependents: false,
                subItems: [
                    { label: 'sidebar.subItems.label.portes', route: 'CommercialPortesRoot' },
                    { label: 'sidebar.subItems.label.createMission', route: 'MissionRoot' },
                    { label: 'sidebar.subItems.label.simulation', route: 'Simulation' },
                    { label: 'sidebar.subItems.label.prospect', route: 'ProspectsRoot' }
                ]
            }
        },
        children: [
            {
                path: 'portes',
                name: 'CommercialPortesRoot',
                // redirect est obligatoire si l'on veut utiliser le router.push avec les name des routes sur les parent pour utiliser le child par defaut
                redirect: { name: 'CommercialPortes' },
                children: [
                    {
                        path: '',
                        name: 'CommercialPortes',
                        exact: true,
                        components: {
                            default: () => import('/src/views/Pages/Commercial/Portes'),
                        },
                    },
                    {
                        path: ':idPorte/mission/:idMission',
                        name: 'FicheMissionPorte',
                        component: () => import('/src/views/Pages/Commercial/FichePorte'),
                        meta: { 'returnView' : 'CommercialPortes' }
                    },
                    {
                        path: ':idPorte/simulation/:idSimulation',
                        name: 'FicheSimulationPorte',
                        component: () => import('/src/views/Pages/Commercial/FichePorte'),
                        meta: { 'returnView' : 'CommercialPortes' }
                    },
                    {
                        path: ':idPorte/contracts-amendments',
                        name: 'FicheContractsAmendments',
                        component: () => import('/src/views/Pages/Commercial/FichePorte'),
                        meta: { 'returnView' : 'CommercialPortes' }
                    }
                ]
            },
            {
                path: 'mission',
                name: 'MissionRoot',
                redirect: { name: 'Mission' },
                children: [
                    {
                        path: '',
                        name: 'Mission',
                        component: () => import('/src/views/Pages/Commercial/CreateMission'),
                    },
                    {
                        path: ':idMission',
                        name: 'CreationMissionPorte',
                        component: () => import('/src/views/Pages/Commercial/FicheCreateMission'),
                        meta: { 'returnView' : 'Mission' }
                    }
                ]
            },
            {
                path: 'simulation',
                name: 'Simulation',
                component: () => import('/src/views/Pages/Commercial/CreateSimulation')
            },
            {
                path: 'prospects',
                name: 'ProspectsRoot',
                redirect: { name: 'Prospects' },
                children: [
                    {
                        path: '',
                        name: 'Prospects',
                        component: () => import('/src/views/Pages/Commercial/Prospects')
                    },
                    {
                        path: ':idProspect/simulation/:idSimulation',
                        name: 'FicheProspect',
                        component: () => import('/src/views/Pages/Commercial/FicheProspect'),
                        meta: { 'returnView' : 'Prospects' }
                    }
                ]
            }
        ]
    },
    {
        path: '/simulation',
        children: [
            {
                path: ':idSimulation',
                name: 'ProspectsSimulation',
                component: () => import('/src/views/Pages/Prospect/Simulation'),
                meta: { requiresAuth: false }
            },
            {
                path: ':idSimulation/:token',
                name: 'ProspectsSimulation',
                component: () => import('/src/views/Pages/Prospect/Simulation'),
                meta: { requiresAuth: false }
            },
        ]
    },
    {
        path: '/paie',
        name: 'PaieIndependent',
        meta: {
            sideContentMenu : {
                isVisible: true,
                currentPageTitle: 'sidebar.title.paie',
                subItems: [
                    { label: 'sidebar.subItems.label.declarePaie', route: 'DeclarePaie' },
                    { label: 'sidebar.subItems.label.declareFacture', route: 'DeclareFacture' },
                ]
            }
        },
        redirect: { name: 'DeclarePaie' },
        children: [
            {
                path: 'create',
                name: 'DeclarePaie',
                component: () => import('/src/views/Pages/Porte/DeclarePaie')
            },
            {
                path: 'declarationFacturation',
                name: 'DeclareFacture',
                component: () => import('/src/views/Pages/Porte/DeclareFacture')
            },
        ]
    },
    {
        path: '/paie/admin',
        name: 'PaieAdmin',
        meta: {
            sideContentMenu : {
                isVisible: true,
                currentPageTitle: 'sidebar.title.paie',
                showIndependents: false,
                subItems: [
                    {label: 'sidebar.subItems.label.preparationPaie', route: 'PaiePortes'},
                    {label: 'sidebar.subItems.label.importPaie', route: 'ImportPaie'},
                    {label: 'sidebar.subItems.label.historyPaie', route: 'HistoryPaie'},
                    {label: 'sidebar.subItems.label.declareFacture', route: 'DeclareFacturePortes'},
                ]
            }
        },
        children: [
            {
                path: 'portes',
                name: 'PaiePortesRoot',
                children: [
                    {
                        path: '',
                        name: 'PaiePortes',
                        component: () => import('/src/views/Pages/Paie/Portes')
                    },
                    {
                        path: 'declaration',
                        name: 'DeclarePaieAdmin',
                        component: () => import('/src/views/Pages/Paie/Declaration'),
                        meta: {
                            sideContentMenu: {
                                update: true,
                                isVisible: true,
                                showIndependents: true,
                            },
                            returnView : 'PaiePortes'
                        }
                    },
                ]
            },
            {
                path: 'import',
                name: 'ImportPaie',
                component: () => import('/src/views/Pages/Paie/Import')
            },
            {
                path: 'historique',
                name: 'HistoryPaie',
                component: () => import('/src/views/Pages/Paie/PaieHistory')
            },
            {
                path: 'declarationFacturation',
                name: 'DeclareFacturePortesRoot',
                children: [
                    {
                        path: '',
                        name: 'DeclareFacturePortes',
                        component: () => import('/src/views/Pages/Paie/TableauDeclareFacture')
                    },
                    {
                        path: 'declarationFacturation',
                        name: 'DeclareFactureAdmin',
                        component: () => import('/src/views/Pages/Paie/DeclarationFacturation'),
                        meta: {
                            sideContentMenu: {
                                update: true,
                                isVisible: true,
                                showIndependents: true,
                            },
                            returnView : 'DeclareFacturePortes'
                        }
                    }
                ]
            },
        ]
    },
    {
        path: '/mission',
        name: 'MissionIndep',
        meta: {
            sideContentMenu : {
                isVisible: false
            }
        },
        children: [
            {
                path: 'fiches-missions',
                name: 'MissionFiches',
                component: () => import('/src/views/Pages/Porte/FichesMissions')
            }
        ]
    },
    { // todo: passer le side content menu dans le layout RH comme pour commercial avec le système de TAB
        path: '/rh/porte',
        name: 'RHPorte',
        meta: {
            sideContentMenu : {
                isVisible: false
            }
        },
        children: [
            {
                path: 'profil',
                name: 'FicheRHIndependent',
                component: () => import('/src/views/Pages/RH/RH')
            },
            {
                path: 'simulation',
                name: 'RHSimulationIndependent',
                component: () => import('/src/views/Pages/RH/RH')
            },
            {
                path: 'documents',
                name: 'RHDocumentsIndependent',
                component: () => import('/src/views/Pages/RH/RH')
            },
            {
                path: 'sortie',
                name: 'RHSortieIndependent',
                component: () => import('/src/views/Pages/RH/RH')
            },
        ]
    },
    {
        path: '/rh/admin/portes',
        name: 'RHPortesRoot',
        meta: {
            sideContentMenu : {
                isVisible: false,
                showIndependents: false
            }
        },
        children: [
            {
                path: '',
                name: 'RHPortes',
                exact: true,
                component: () => import('/src/views/Pages/RH/Portes'),
            },
            {
                path: 'profil',
                name: 'RHProfil',
                component: () => import('/src/views/Pages/RH/RH'),
                meta: {
                    sideContentMenu: {
                        isVisible: true,
                        showIndependents: true,
                        currentPageTitle:  'sidebar.title.portes'
                    },
                    returnView : 'RHPortes'
                }
            },
            {
                path: 'simulation',
                name: 'RHSimulation',
                component: () => import('/src/views/Pages/RH/RH'),
                meta: {
                    sideContentMenu: {
                        isVisible: true,
                        showIndependents: true,
                        currentPageTitle:  'sidebar.title.portes'
                    },
                    returnView : 'RHPortes'
                }
            },
            {
                path: 'documents',
                name: 'RHDocumentsAdmin',
                component: () => import('/src/views/Pages/RH/RH'),
                meta: {
                    sideContentMenu: {
                        isVisible: true,
                        showIndependents: true,
                        currentPageTitle:  'sidebar.title.portes'
                    },
                    returnView : 'RHPortes'
                }
            },
            {
                path: 'sortie',
                name: 'RHSortieAdmin',
                component: () => import('/src/views/Pages/RH/RH'),
                meta: {
                    sideContentMenu: {
                        isVisible: true,
                        showIndependents: true,
                        currentPageTitle:  'sidebar.title.portes'
                    },
                    returnView : 'RHPortes'
                }
            },
        ]
    },
    {
        path: '/dashboard',
        name: 'DashboardPorte',
        component: () => import('/src/views/Pages/Prospect/DashboardPorte'),
        meta: {
            requiresPermission: false,
            sideContentMenu : {
                isVisible: false
            }
        }
    },
    {
        path: '/portes',
        name: 'Portes',
        meta: {
            sideContentMenu : {
                isVisible: false,
            }
        },
        children: [
            {
                path: '',
                name: 'AdminPortes',
                component: () => import('/src/views/Pages/Portes/Portes')
            },
            {
                path: ':idPorte',
                name: 'AdminDashboardPorte',
                component: () => import('/src/views/Pages/Portes/AdminDashboardPorte')
            }
        ]
    },
    {
        path: '/facturation',
        name: 'Facturation',
        component: () => import('/src/views/Pages/Porte/PorteTableauServices'),
        meta: {
            sideContentMenu : {
                isVisible: false,
            }
        },
    },
    {
        path: '/adv',
        name: 'Adv',
        meta: {
            sideContentMenu : {
              isVisible: true,
              currentPageTitle: 'sidebar.title.adv',
              showIndependents: false,
              subItems: [
                { label: 'sidebar.subItems.label.services', route: 'ServicesRoot' },
                { label: 'sidebar.subItems.label.factureAttente', route: 'FactureAttenteRoot' },
                { label: 'sidebar.subItems.label.factureReglements', route: 'FactureReglementsRoot' },
                { label: 'sidebar.subItems.label.declareFacture', route: 'DeclareFactureAdvRoot' },
              ]
            }
        },
        children: [
            {
                path: 'services',
                name: 'ServicesRoot',
                redirect: { name: 'Services' },
                children: [
                    {
                        path: '',
                        name: 'Services',
                        component: () => import('/src/views/Pages/ADV/Services')
                    },
                    {
                        path: 'invoice',
                        name: 'Facture',
                        component: () => import('/src/views/Pages/ADV/Invoice'),
                        meta: {
                            returnView : 'Services'
                        }
                    }
                ]
            },
            {
                path: 'facture-attente',
                name: 'FactureAttenteRoot',
                redirect: { name: 'FactureAttente'},
                children: [
                    {
                        path: '',
                        name: 'FactureAttente',
                        component: () => import('/src/views/Pages/ADV/FactureAttente')
                    },
                    {
                        path: 'invoice/:id',
                        name: 'FactureAttenteId',
                        component: () => import('/src/views/Pages/ADV/Invoice'),
                        meta: {
                            returnView : 'FactureAttente'
                        }
                    },
                ]
            },
            {
                path: 'facture-reglements',
                name: 'FactureReglementsRoot',
                redirect: { name: 'FactureReglements'},
                children: [
                    {
                        path: '',
                        name: 'FactureReglements',
                        component: () => import('/src/views/Pages/ADV/FactureReglements')
                    },
                    {
                        path: 'invoice/:id',
                        name: 'FactureReglementsId',
                        component: () => import('/src/views/Pages/ADV/Invoice'),
                        meta: {
                            returnView : 'FactureReglements'
                        }
                    },
                ]
            },
            {
                path: 'declarationFacturation',
                name: 'DeclareFactureAdvRoot',
                redirect: { name: 'TableauFactureAdminAdv' },
                children: [
                    {
                        path: '',
                        name: 'TableauFactureAdminAdv',
                        component: () => import('/src/views/Pages/Paie/TableauDeclareFacture')
                    },
                    {
                        path: 'declarationFacturation',
                        name: 'DeclareFactureAdminAdv',
                        component: () => import('/src/views/Pages/Paie/DeclarationFacturation'),
                        meta: {
                            returnView : 'TableauFactureAdminAdv'
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/TestGeneratedPDf',
        name: 'TestGeneratedPDf',
        component: () => import('/src/views/Pages/Test/PdfGeneration')
    },
    {
        path: '/ndf',
        name: 'NDF',
        meta: {
            sideContentMenu : {
                isVisible: true,
                currentPageTitle: 'sidebar.title.ndf',
                showIndependents: false,
                subItems: [
                    { label: 'sidebar.subItems.label.ndfAValider', route: 'NDFAValider' },
                    { label: 'sidebar.subItems.label.ndfRemboursements', route: 'NDFRemboursements' },
                    { label: 'sidebar.subItems.label.ff', route: 'NDFFFAValider' },
                    { label: 'sidebar.subItems.label.ffValidees', route: 'NDFFFValidees' },
                ]
            }
        },
        redirect: { name: 'NDFAValider' },
        children: [
            {
                path: 'ndf-a-valider',
                name: 'NDFAValider',
                component: () => import('/src/views/Pages/NDF/NDFAValider')
            },
            {
                path: 'remboursement',
                name: 'NDFRemboursements',
                component: () => import('/src/views/Pages/NDF/NDFRemboursements')
            },
            {
                path: 'factures-fournisseurs-a-valider',
                name: 'NDFFFAValider',
                component: () => import('/src/views/Pages/NDF/NDFFFAValider')
            },
            {
                path: 'factures-fournisseurs-validees',
                name: 'NDFFFValidees',
                component: () => import('/src/views/Pages/NDF/NDFFFValidees'),
            },
        ]
    },
    {
        path: '/notes-de-frais',
        name: 'NDFPORTE',
        component: () => import('/src/views/Pages/NDF/NDFPorte'),
        meta: {
            sideContentMenu : {
                isVisible: false,
            }
        }
    },
    {
        path: '/admin',
        meta: {
            sideContentMenu : {
                isVisible: true,
                currentPageTitle: 'sidebar.title.adminParams',
                subItems: [
                    { label: 'sidebar.subItems.label.company', route: 'AdminEntreprise' },
                    { label: 'sidebar.subItems.label.commercial', route: 'AdminCommercial' },
                    { label: 'sidebar.subItems.label.rh', route: 'AdminRh' },
                    { label: 'sidebar.subItems.label.adv', route: 'AdminAdv' },
                    { label: 'sidebar.subItems.label.pay', route: 'AdminPaie' },
                    { label: 'sidebar.subItems.label.ndf', route: 'AdminNdf' },
                    { label: 'sidebar.subItems.label.adminUsersManagement', route: 'AdminUsersManagement' },
                    { label: 'sidebar.subItems.label.publicHolidays', route: 'AdminPublicHolidays' }
                ]
            }
        },
        redirect: { name: 'AdminEntreprise' },
        children: [
            {
                path: 'settings/entreprise',
                name: 'AdminEntreprise',
                component: () => import('/src/views/Pages/Admin/Settings/Entreprise')
            },
            {
                path: 'settings/commercial',
                name: 'AdminCommercial',
                component: () => import('/src/views/Pages/Admin/Settings/Commercial')
            },
            {
                path: 'settings/rh',
                name: 'AdminRh',
                component: () => import('/src/views/Pages/Admin/Settings/Rh')
            },
            {
                path: 'settings/adv',
                name: 'AdminAdv',
                component: () => import('/src/views/Pages/Admin/Settings/Adv')
            },
            {
                path: 'settings/paie',
                name: 'AdminPaie',
                component: () => import('/src/views/Pages/Admin/Settings/Paie')
            },
            {
                path: 'settings/ndf',
                name: 'AdminNdf',
                component: () => import('/src/views/Pages/Admin/Settings/Ndf')
            },
            {
                path: 'settings/users',
                name: 'AdminUsersManagement',
                component: () => import('/src/views/Pages/Admin/Settings/Users')
            },
            {
                path: 'settings/public-holidays',
                name: 'AdminPublicHolidays',
                component: () => import('/src/views/Pages/Admin/Settings/PublicHolidays.vue')
            },
        ]
    },
    {
        path: '/regularisations',
        name: 'Regularisations',
        component: () => import('/src/views/Pages/Regularisations/Regularisations'),
        meta: {
            sideContentMenu : {
                isVisible: false,
            }
        }
    },
    {
        path: '/media-library',
        name: 'MediaLibrary',
        component: () => import('/src/views/Pages/Media/Library'),
        navs: true,
        meta: {
            sideContentMenu : {
                isVisible: false
            }
        }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/login',
    }
]
