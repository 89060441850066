<template>
  <Dialog
      v-model:visible="dialogActive"
      :breakpoints="{'960px': '75vw', '640px': '90vw'}"
      :style="{width: '50vw'}"
      closable
      :draggable="false"
      modal
      @hide="closeDialog"
  >
    <template #header>
      <div>
        <h3 class="text-black-alpha-90">
          Gestion des tarifs de gestion
        </h3>
      </div>
    </template>
    <div v-if="!loading">
      <div class="flex flex-row align-items-center justify-content-center mb-3">
        <p class="mb-0 mr-2 w-3 font-bold">Date de mise en effet</p>
        <p class="mb-0 mr-2 w-3 font-bold">
          Tarif de gestion
        </p>
        <p class="mb-0 w-3 mr-2 font-bold">
          Plafond
        </p>
        <p class="w-1">{{ '' }}</p>
      </div>
      <!--   todo: Is the independent can see this ?   -->
      <!--   todo: Bug on the index that is not the index we checked on the save   -->
      <div v-for="(managementFeeValue) in getSortedNegotiatedRateValues" :key="managementFeeValue.id" class="flex flex-column">
        <management-fee-input ref="managementFeeInputs" :management-fee-value="managementFeeValue" :mission="mission" class="mb-2"
                                :invalid-months="invalidMonths" :disabled="disabled"
                                @deleteNegotiatedRateValue="removeNegotiatedRateValue" @updateNegotiatedRateValue="updateNegotiatedRateValue"
                                @add-invalid-month="addInvalidMonth" @delete-invalid-month="deleteInvalidMonth"/>
      </div>
    </div>
    <div v-else class="overflow-hidden flex">
      <ProgressSpinner></ProgressSpinner>
    </div>
    <template #footer>
      <!--      <Button label="Fermer" class="p-button-text" @click="closeDialog"/>-->
      <Button v-if="!disabled" label="Nouveau tarif" icon="pi pi-plus" :disabled="!mission.jsonMission.startingDate || mission.jsonCost.managementFeeValues?.length === 0" @click="addNegotiatedRate"/>
      <Button v-if="!disabled" label="Enregistrer" @click="saveNegotiatedRateValues"/>
    </template>
  </Dialog>
</template>

<script>
import {mapState} from "vuex";
import UtilsDate from "@/utils/Date";
import {invalidMonthFormat} from "@/utils/NegotiatedRateValueUtils";
import ManagementFeeInput from "@/components/ManagementFeeInputs.vue";

export default {
  name: "DialogManagementFeeValues",
  components: {ManagementFeeInput},
  emits: ['closeDialog', 'updateNegotiatedRateValues'],
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
    mission: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogActive: false,
      loading: true,

      localManagementFeeValues: [],
      invalidMonths: []
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants
    }),
    getSortedNegotiatedRateValues() {
      if (this.localManagementFeeValues.length === 0) return []
      const computedLocalNegotiatedRateValues = JSON.parse(JSON.stringify(this.localManagementFeeValues))
      // sort asc on effectiveDate
      const sortedComputedLocalNegotiatedRateValues = computedLocalNegotiatedRateValues
          // .filter(negotiatedRate => negotiatedRate.unit === this.mission.jsonCost.negotiatedRateUnit)
          .sort((a, b) => a.effectiveDate > b.effectiveDate ? -1 : a.effectiveDate < b.effectiveDate ? 1 : 0)
          .map((negotiatedRate) => {
            return {
              ...negotiatedRate,
              effectiveDate: negotiatedRate.effectiveDate ? UtilsDate.getDate(new Date(negotiatedRate.effectiveDate)) : null
            }
          })
      return sortedComputedLocalNegotiatedRateValues
    }
    // disableNewManagementFee () {
    //   if ((this.mission.status === this.constants.MISSION_STATUS.DRAFT || this.mission.status === this.constants.MISSION_STATUS.INCOMPLETE ||
    //       this.mission.status === this.constants.MISSION_STATUS.PENDING_CONTRACT))
    //     return false
    //   return (!this.mission.jsonMission.startingDate || this.mission.jsonCost.managementFeeValues?.length === 0)
    // }
  },
  watch: {
    async isActive(value) {
      if (value) {
        await this.init()
      }
      this.dialogActive = value
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    async init() {
      this.loading = true
      this.invalidMonths = []
      const missionStartingMonth = new Date(this.mission.jsonMission.startingDate).getMonth() + 1
      const missionStartingYear = new Date(this.mission.jsonMission.startingDate).getFullYear()
      if (this.mission.id && missionStartingMonth && missionStartingYear) {
        // consider that the first date is the starting date of the mission
        await this.$store.dispatch('service/getAllServicesByMissionSinceDate', {
          mission: this.mission.id,
          month: missionStartingMonth,
          year: missionStartingYear,
        })
      }

      let id = 1
      if (this.mission.jsonCost?.managementFeeValues?.length && this.mission.jsonCost?.managementFeeValues?.length > 0) {
        this.localManagementFeeValues = JSON.parse(JSON.stringify(this.mission.jsonCost.managementFeeValues))
            .map((negotiatedRate) => {
              negotiatedRate.id = id
              ++id
              return negotiatedRate
            })
      }
      this.initInvalidMonths()
      this.loading = false
    },
    initInvalidMonths () {
      // if (this.mission.jsonCost.negotiatedRateUnit !== this.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE
      //     && this.mission.jsonCost.forfaitFacturationMode !== this.constants.INVOICE_TYPE.MONTHLY) {
      //   this.invalidMonths = []
      //   return
      // }

      for (let index = 0; index < this.getSortedNegotiatedRateValues.length; index++) {
        if (this.getSortedNegotiatedRateValues[index].effectiveDate === null) continue
        // todo: need to test if the forceAdd condition is "valid"
        this.addInvalidMonth(this.getSortedNegotiatedRateValues[index], this.getSortedNegotiatedRateValues.length === 0)
      }
    },
    addInvalidMonth (negotiatedRateValue, forceAdd = false) {
      if (!negotiatedRateValue.effectiveDate) return
      const effectiveDateMonth = invalidMonthFormat(negotiatedRateValue.effectiveDate)
      if (this.invalidMonths.findIndex(invalidMonth => invalidMonth.monthFormat === effectiveDateMonth) === -1 || forceAdd) {
        this.invalidMonths.push({ id: negotiatedRateValue.id, monthFormat: effectiveDateMonth })
      }
    },
    deleteInvalidMonth (negotiatedRateValueId) {
      // delete the invalidMonth if exist
      if (!negotiatedRateValueId) return
      const foundIndex = this.invalidMonths.findIndex(invalidMonth => invalidMonth.id === negotiatedRateValueId)
      if (foundIndex !== -1) {
        this.invalidMonths.splice(foundIndex, 1)
      }
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
    async saveNegotiatedRateValues() {
      let index = 0
      let isFormCorrect = false
      // eslint-disable-next-line no-unused-vars
      for (const localNegotiatedRateValue in this.getSortedNegotiatedRateValues) {
        // the negotiated values are filtered in the computed get, so we need to exclude them as in the computed to get only the components that are existing
        isFormCorrect = await this.$refs.managementFeeInputs[index].onSubmit()
        // if (this.localNegotiatedRateValues[index].unit === this.mission.jsonCost.negotiatedRateUnit) {
        // } else {
        //   isFormCorrect = true
        // }
        // todo: see if it's better to submit all the negotiatedRateInputs ref or only one is ok
        if (!isFormCorrect) {
          return false
        }
        ++index
      }
      // should return all the date as ISO string format
      const payload = this.localManagementFeeValues
          .map((negotiatedRateValue) => {
            // eslint-disable-next-line no-unused-vars
            const {id, ...value} = negotiatedRateValue
            return {...value, effectiveDate: negotiatedRateValue.effectiveDate}
          })
      this.$emit('updateNegotiatedRateValues', payload)
    },
    addNegotiatedRate() {
      let lastId = 0
      this.localManagementFeeValues.forEach((negotiatedRate) => {
        if (negotiatedRate.id > lastId) {
          lastId = negotiatedRate.id
        }
      })
      ++lastId
      this.localManagementFeeValues.push({
        id: lastId,
        effectiveDate: null,
        value: null,
        cap: null,
        // unit: this.mission.jsonCost.negotiatedRateUnit
      })
    },
    removeNegotiatedRateValue(negotiatedRateId) {
      const foundIndex = this.localManagementFeeValues.findIndex((localNegotiatedRate) => localNegotiatedRate.id === negotiatedRateId)
      if (foundIndex === -1) return
      this.localManagementFeeValues.splice(foundIndex, 1)
    },
    updateNegotiatedRateValue(negotiatedRateValue) {
      const foundIndex = this.localManagementFeeValues.findIndex((localNegotiatedRate) => localNegotiatedRate.id === negotiatedRateValue.id)
      if (foundIndex === -1) return
      this.localManagementFeeValues.splice(foundIndex, 1, negotiatedRateValue)
    }
  }
}
</script>

<style scoped>

</style>
