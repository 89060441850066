import axios from '@/plugins/axios'
import { formatFromApi, formatToApi } from '@/utils/ServiceUtil'
import { formatFromApi as formatDeclarationFacturationFromApi } from '@/utils/DeclarationFacturationUtil'
export default {
  namespaced: true,
  state: {
    services: [],
    servicesForfait: null,
  },
  getters: {
    getServices (state) {
      return state.services
    },
    getServicesForfait (state) {
      return state.servicesForfait
    }
  },
  mutations: {
    SET_SERVICES(state, value) {
      state.services = value
    },
    SET_SERVICES_FORFAIT(state, value) {
      state.servicesForfait = value
    },
    ADD_SERVICE_FORFAIT(state, data) {
      state.servicesForfait[data.index].services.push(data.service)
    },
    REMOVE_SERVICE_FORFAIT(state, data) {
      state.servicesForfait[data.indexMission].services.splice(data.indexService, 1)
    },
    CHANGE_SERVICE(state, value) {
      if (state.services.length === 0) return
      let serviceIndex = state.services.findIndex((service) => service.id === value.id)
      if (serviceIndex !== -1) {
        state.services.splice(serviceIndex, 1, {
          ...state.services[serviceIndex],
          ...value
        })
      }
    }
  },
  actions: {
    // eslint-disable-next-line
    async getAllServices({ commit }) {
      return await new Promise((resolve, reject) => {
        axios.api.get('/service').then(res => {
          let results = res?.data?.map((c) => formatFromApi(c))
          commit('SET_SERVICES', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async getAllServicesByIndependent({ commit }, data) {
      return await new Promise((resolve, reject) => {
        axios.api.get('/service/independent/' + data).then(res => {
          let results = res.data.map((c) => formatFromApi(c))
          commit('SET_SERVICES', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async getAllServicesByMissionSinceDate({ commit }, data) {
      return await new Promise((resolve, reject) => {
        axios.api.get('/service/mission-since/' + data.mission + '/' + data.month + '/' + data.year).then(res => {
          let results = res.data.map((c) => formatFromApi(c))
          commit('SET_SERVICES', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async getServicesByMissionAndDate({ commit }, data) {
      return await new Promise((resolve, reject) => {
        axios.api.get('/service/mission/' + data.mission + '/' + data.month + '/' + data.year).then(res => {
          let results = res.data.map((c) => formatFromApi(c))
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createServiceForNdf(_, id) {
      return await new Promise((resolve, reject) => {
        axios.api.post('/service/ndf/' + id).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createServiceForFacturefournisseur(_, id) {
      return await new Promise((resolve, reject) => {
        axios.api.post('/service/facturefournisseur/' + id).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async saveService({ commit }, data) {
      data = formatToApi(data)
      if (data.id) {
        return await new Promise((resolve, reject) => {
          axios.api.put('/service', data).then(res => {
            let serv = formatFromApi(res.data)
            commit('CHANGE_SERVICE', serv)
            resolve(serv)
          }).catch((e) => {
            console.log(e)
            reject(e)
          })
        })
      } else {
        return await new Promise((resolve, reject) => {
          axios.api.post('/service', data).then(res => {
            resolve(formatFromApi(res.data))
          }).catch((e) => {
            reject(e)
          })
        })
      }
    },
    // eslint-disable-next-line
    async removeServices(store, data) {
      const config = { params: {} }
      if (data.ids) config.params.ids = data.ids
      return await new Promise((resolve, reject) => {
        axios.api.delete('/service', config).then(res => {
          // todo: should based the delete of these services by what is returned by the back and not assume they are deleted ?
          store.commit('declarationFacturation/DELETE_SERVICE_FROM_DECLARATION_FACTURATION', data, { root: true })

          // todo: might need to do the same with NDFS and FACTURES FOURNISSEURS
          if (res?.data?.invoicesDeclarations?.length > 0) {
            res.data.invoicesDeclarations
                .map(invoiceDeclaration => formatDeclarationFacturationFromApi(invoiceDeclaration))
                .forEach(invoiceDeclaration => {
                  store.commit('declarationFacturation/UPDATE_DECLARATION_FACTURATION', invoiceDeclaration, { root: true })
                })
          }
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },

    // Gestion services pour les missions au forfait
    setServicesForfait({ commit }, servicesForfait) {
      commit('SET_SERVICES_FORFAIT', servicesForfait)
    },
    addServiceForfait({ commit }, data) {
      commit('ADD_SERVICE_FORFAIT', data)
    },

    removeServiceForfait({ commit }, data) {
      commit('REMOVE_SERVICE_FORFAIT', data)
    },
    changeService({ commit }, data) {
      commit('CHANGE_SERVICE', data)
    },
  },
}
