const successMessage = (context, key, options = {}, life = 3000) => {
    const message = context.$t('success.'+key, options);
    if (message && message.length > 0) {
        context.$toast.add({ severity: 'success', detail: message, life: life });
    }
}

const warningMessage = (context, key, options = {}, life = 3000) => {
    const message = context.$t('warning.'+key, options);
    if (message && message.length > 0) {
        context.$toast.add({ severity: 'warn', detail: message, life: life });
    }
}

const errorMessage = (context, key = 'defaultError', options = {}, life = 6000) => {
    const message = context.$t('errors.'+key, options);
    if (message && message.length > 0) {
        context.$toast.add({ severity: 'error', detail: message, life: life });
    }
}

const infoMessage = (context, key, options = {}, life = 3000) => {
    const message = context.$t('info.'+key, options);
    if (message && message.length > 0) {
        context.$toast.add({ severity: 'info', detail: message, life: life });
    }
}

export default {
    successMessage,
    warningMessage,
    errorMessage,
    infoMessage,
}
