import axios from '@/plugins/axios'
import {formatFromApi, formatToApi} from '@/utils/ContactUtil'
// todo: refactor Contacts
export default {
  namespaced: true,

  state: {
  },
  mutations: {},
  actions: {
    // eslint-disable-next-line
    async getAllContacts({ commit }, { clientId }) {
      return await axios.api.get(`/contact?clientId=${clientId}`).then(res => {
        let results = res.data.map((c) => formatFromApi(c))
        return results
      }).catch(() => {
      })
    },
    // eslint-disable-next-line
    async getAllFilteredContacts({ commit }, { queryInput, clientId }) {
      return new Promise((resolve, reject) => {
        axios.api.get(`/contact/getFiltered?input=${queryInput}&clientId=${clientId}` ).then(res => {
          let results = res.data.map((c) => formatFromApi(c))
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async saveContact({ commit }, data) {
      data = formatToApi(data)
      if (data.id) {
        return await axios.api.put('/contact', data).then(res => {
          return formatFromApi(res.data)
        })
      } else {
        return await axios.api.post('/contact', data).then(res => {
          return formatFromApi(res.data)
        })
      }
    },

    async importSourceData (store, data) {
      const payload = {
        contacts: data
      }
      return new Promise((resolve, reject) => {
        axios.api.post('/contact/import/source-society-contacts', payload).then(async (res) => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  }
}
