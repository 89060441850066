import {setDateWithoutHours} from "@/utils/Date";

export const formatFromApi = (regularisationI) => {
    let regularisation = JSON.parse(JSON.stringify(regularisationI))
    return regularisation
}

export const formatToApi = (regularisationI) => {
    let regularisation = JSON.parse(JSON.stringify(regularisationI))
    regularisation.value = Math.round(regularisation.value * 100)
    regularisation.date = regularisation.date ? setDateWithoutHours(regularisation.date) : null
    return regularisation
}
