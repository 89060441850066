<template>
  <div class="flex flex-column">
    <div class="flex flex-row align-items-center justify-content-center" :class="(v$.localManagementFeeValue.effectiveDate.$dirty && v$.localManagementFeeValue.effectiveDate.$errors.length)
        || (v$.localManagementFeeValue.value.$dirty && v$.localManagementFeeValue.value.$errors.length) || (v$.localManagementFeeValue.cap.$dirty && v$.localManagementFeeValue.cap.$errors.length)
      ? 'mb-1' : ''">
      <Calendar ref="calendarRateInput" id="effectiveDate" v-model="v$.localManagementFeeValue.effectiveDate.$model"
                :showIcon="true" class="mr-2 w-3" :date-format="getDateFormat" :view="getDateView"
                :class="{'p-invalid':v$.localManagementFeeValue.effectiveDate.$errors.length}"
                :disabled="disableEffectiveDate || disableNegotiatedRateInputs" :min-date="getMinDate"
                :disabled-dates="disabledDates"
                @blur="updateNegotiatedRateValue"/>

      <InputNumber id="managementFeeValue" v-model="v$.localManagementFeeValue.value.$model" class="mr-2 w-3" highlightOnFocus
                   :min="0" :max-fraction-digits="2" suffix=" %" :class="{'p-invalid':v$.localManagementFeeValue.value.$errors.length}"
                   :disabled="disableNegotiatedRateInputs" @blur="updateNegotiatedRateValue"/>

      <InputNumber id="managementFeeCap" v-model="v$.localManagementFeeValue.cap.$model" class="mr-2 w-3" :min="0"
                   locale="fr-FR" mode="currency" currency="EUR" highlightOnFocus
                   :class="{'p-invalid':v$.localManagementFeeValue.cap.$errors.length}" :disabled="disableNegotiatedRateInputs"
                   @blur="updateNegotiatedRateValue"/>

      <i class="pi pi-times-circle cursor-pointer text-red-300 w-1"
         style="font-size: 1.5rem" :style="disableEffectiveDate || disableNegotiatedRateInputs ? 'visibility: hidden' : ''"
         @click="removeNegotiatedRateValue"/>
    </div>
    <div
        v-if="v$.localManagementFeeValue.effectiveDate.$errors.length || v$.localManagementFeeValue.value.$errors.length || v$.localManagementFeeValue.cap.$errors.length"
        class="mb-1">
        <span
            v-for="(error, index) of [...v$.localManagementFeeValue.effectiveDate.$errors, ...v$.localManagementFeeValue.value.$errors, ...v$.localManagementFeeValue.cap.$errors]"
            :key="index">
          <InlineMessage class="w-full mb-1">{{ error.$message }}</InlineMessage>
        </span>
    </div>
  </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {mapState} from "vuex";
import {helpers, required} from "@vuelidate/validators";
import {invalidMonthFormat} from "@/utils/NegotiatedRateValueUtils";

export default {
  name: "ManagementFeeInput",
  setup: () => ({v$: useVuelidate()}),
  emits: ['deleteNegotiatedRateValue', 'updateNegotiatedRateValue', 'addInvalidMonth', 'deleteInvalidMonth'],
  props: {
    mission: {
      type: Object,
      required: true
    },
    managementFeeValue: {
      type: Object,
      required: true
    },
    invalidMonths: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localManagementFeeValue: this.managementFeeValue
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
      service: state => state.service,
    }),
    isMissionMonthlyFixedPrice () {
      return true
    },
    getDateView () {
      if (this.isMissionMonthlyFixedPrice) {
        return 'month'
      }
      return 'date'
    },
    getDateFormat () {
      if (this.isMissionMonthlyFixedPrice) {
        return 'mm/yy'
      }
      return 'dd/mm/yy'
    },
    disableEffectiveDate () {
      if (!this.localManagementFeeValue.effectiveDate || !this.mission.jsonMission.startingDate) return false
      const missionStartingDate = new Date(this.mission.jsonMission.startingDate).toISOString()
      const formattedEffectiveDate = new Date(this.localManagementFeeValue.effectiveDate).toISOString()
      return formattedEffectiveDate === missionStartingDate || !!this.localManagementFeeValue.service_id;
    },
    disableNegotiatedRateInputs () {
      if (this.disabled) return true
      if (!this.service.services || !this.localManagementFeeValue?.effectiveDate
          || !this.mission.jsonMission.startingDate || !this.mission.id) return false
      // todo: is it superior or equal or just equal ?
      const foundValidatedServiceAtEffectiveDate = this.service.services.findIndex(service =>
          service?.month >= new Date(this.localManagementFeeValue?.effectiveDate).getMonth() + 1
          && service?.year >= new Date(this.localManagementFeeValue?.effectiveDate).getFullYear())
      return foundValidatedServiceAtEffectiveDate !== -1 || !!this.localManagementFeeValue.service_id;
    },
    getMinDate () {
      if (!this.mission.jsonMission.startingDate) return null
      const missionStartingDate = new Date(JSON.parse(JSON.stringify(this.mission.jsonMission.startingDate))).toISOString()
      const currentDateISO = new Date().toISOString()
      const currentDate = new Date(currentDateISO)

      let minDate
      // take the further date to define the min date
      if (currentDate < missionStartingDate) {
        minDate = new Date(missionStartingDate)
      } else {
        // take the last day of the previous month because we add +1 to the Date in the end
        const date = new Date()
        date.setDate(0)
        date.setMonth(currentDate.getMonth() <= 2 ? 11 - (Math.abs(currentDate.getMonth() - 2)) : currentDate.getMonth() - 2)
        date.setFullYear(currentDate.getMonth() <= 2 ? currentDate.getFullYear() - 1 : currentDate.getFullYear())
        minDate = date
      }
      minDate.setUTCDate(minDate.getUTCDate() + 1);
      return minDate
    },
    disabledDates () {
      // disable all the existing date to prevent having multiple negotiatedRate on the same date
      if (!this.mission.jsonCost?.managementFeeValues || this.mission.jsonCost.managementFeeValues?.length === 0) return []
      return this.mission.jsonCost.managementFeeValues
          .map((negotiatedRateValue) => new Date(negotiatedRateValue.effectiveDate))
    }
  },
  validations() {
    const form = {
      localManagementFeeValue: {
        effectiveDate: {required: helpers.withMessage('Date de mise en effet obligatoire', required)},
        value: {required: helpers.withMessage('Tarif de gestion obligatoire', required)},
        cap: {}
      }
    }

    if (this.isMissionMonthlyFixedPrice) {
      form.localManagementFeeValue.effectiveDate = {
        required: helpers.withMessage('Date de mise en effet obligatoire', required),
        isValidMonth: helpers.withMessage('Ce mois est déjà utilisé pour cette année', this.isValidMonth)
      }
    }

    return form
  },
  methods: {
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
        return false
      }
      return true
    },
    removeNegotiatedRateValue() {
      this.$emit('deleteNegotiatedRateValue', this.localManagementFeeValue.id)
      this.$emit('deleteInvalidMonth', this.localManagementFeeValue.id)
    },
    updateNegotiatedRateValue () {
      this.$emit('updateNegotiatedRateValue', this.localManagementFeeValue)
    },
    isValidMonth (date) {
      if (!this.isMissionMonthlyFixedPrice) return

      let isValid = true
      if (!date) {
        isValid = true
      }
      const foundIndex = this.invalidMonths.findIndex(validMonth => validMonth.id !== this.localManagementFeeValue.id
          && validMonth.monthFormat === invalidMonthFormat(date))
      if (foundIndex !== -1) {
        isValid = false
      }
      if (isValid) {
        this.$emit('addInvalidMonth', this.localManagementFeeValue)
      } else {
        this.$emit('deleteInvalidMonth', this.localManagementFeeValue.id)
      }
      return {
        $valid: isValid
      }
    }
  }
}
</script>

<style scoped>

</style>
