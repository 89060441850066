<template>
  <OuterCardTitle value="Conditions applicables supplémentaires"/>
  <Card>
    <template #content>
        <div v-if="applicableConditions.length === 0">
          <p v-if="me.role.name === constants.ROLES.ADMIN && editable" class="mb-4">Vous pouvez ajouter des conditions applicables supplémentaires</p>
          <p v-else>Aucunes conditions applicables</p>
        </div>
        <div v-else>
          <div class="grid formgrid p-fluid">
            <template v-for="(condition, index) of applicableConditions" :key="index" class="field mb-4 col-12 md:col-5">
              <div class="field mb-4 col-12 md:col-5">
                <label for="condition.value">Nom de la condition</label>
                <InputText class="mr-2" type="text" v-model="condition.value"  id="condition.value" :disabled="!editable" @blur="sendUpdatedApplicableConditions" />
              </div>
              <div class="field mb-4 col-12 md:col-5">
                <label for="condition-description">Description</label>
                <InputText class="mr-2" type="text" v-model="condition.description" id="condition-description" :disabled="!editable" @blur="sendUpdatedApplicableConditions"/>
              </div>
              <div class="field mb-4 col-12 md:col-1">
                <label>Supprimer</label>
                <Button  v-if="me.role.name === constants.ROLES.ADMIN && editable" icon="pi pi-times-circle " outlined severity="danger" aria-label="Submit" @click="removeCondition(index)" />
              </div>
            </template>
          </div>
        </div>
        <div v-if="me.role.name === constants.ROLES.ADMIN && editable" class="text-right">
          <Button label="Ajouter une condition applicable" icon="pi pi-plus" class="p-button-outlined" @click="addCondition" />
        </div>

    </template>
  </Card>
</template>

<script>
import {mapState} from "vuex";
import OuterCardTitle from "@/components/OuterCardTitle";
export default {
  name: 'ApplicableConditions',
  components: {OuterCardTitle},
  props: {
    existingApplicableConditions: {
      type: Array,
      default: () => { return [] }
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      applicableConditions: []
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      constants: state => state.constants
    })
  },
  watch: {
    existingApplicableConditions (value) {
      this.applicableConditions = JSON.parse(JSON.stringify(value))
    }
  },
  created () {
    this.applicableConditions = JSON.parse(JSON.stringify(this.existingApplicableConditions))
  },
  methods: {
    addCondition () {
      this.applicableConditions.push({ value: '', description: '' })
      this.sendUpdatedApplicableConditions()
    },
    removeCondition (index) {
      this.applicableConditions.splice(index, 1)
      this.sendUpdatedApplicableConditions()
    },
    sendUpdatedApplicableConditions () {
      this.$emit('updateApplicableConditions', { applicableConditions: this.applicableConditions })
    }
  }
}
</script>

<style scoped>

</style>
