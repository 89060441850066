export const formatFromApi = (clientI) => {
    let client = JSON.parse(JSON.stringify(clientI))

    client.custom_roles = client.custom_roles.map(r => r.RolePermission_id)

    return client
}

export const formatToApi = (clientI) => {
    let client = JSON.parse(JSON.stringify(clientI))

    return client
}
