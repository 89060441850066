import {setDateWithoutHours} from "@/utils/Date";

export const formatFromApi = (serviceI) => {
    let service = JSON.parse(JSON.stringify(serviceI))
    service.montantHT = service.montantHT / 100
    service.price = service.price / 100

    service.moisFacturation = new Date(service.year, service.month)
    return service
}

export const formatToApi = (serviceI) => {
    let service = JSON.parse(JSON.stringify(serviceI))

    if (service.independent && service.independent.id) {
        service.independent = service.independent.id
    }
    if (service.declarationFacturation && service.declarationFacturation.id) {
        service.declarationFacturation = service.declarationFacturation.id
    }
    if (service.mission && service.mission.id) {
        service.mission = service.mission.id
    }

    if (service.moisFacturation) {
        let date = setDateWithoutHours(service.moisFacturation)
        service.month = parseInt(date.split('-')[1]) - 1
        service.year = parseInt(date.split('-')[0])
    }

    if (service.montantHT) {
        service.montantHT = Math.round(service.montantHT * 100)
    }

    if (service.price) {
        service.price = Math.round(service.price * 100)
    }


    return service
}
