<template>
  <div v-if="isLogged" class="min-h-screen flex relative lg:static">
    <Toast position="top-right"/>
    <div class="layout-wrapper layout-static">
      <div id="layout-content-wrapper" class="layout-content-wrapper">
        <div id="layout-content" class="layout-content shadow-2 h-full">
          <slot class="h-full"/>
        </div>
      </div>
      <AppBottomBar/>
    </div>
  </div>
  <div v-else-if="!QR_CODE_SCAN_FLAG" class="min-h-screen flex relative surface-ground lg:static">
    <Toast position="top-left"/>
    <slot/>
  </div>
  <Dialog v-model:visible="loading" :show-header="false" modal class="loading-dialog">
    <ProgressSpinner/>
  </Dialog>
</template>

<script>
import {mapState} from "vuex";
import AppBottomBar from "@/layouts/App/AppBottomBar.vue";

export default {
  name: "Mobile",
  components: {AppBottomBar},
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me,
      isLogged: state => state.auth.isLoggedIn,
      loading: state => state.misc.loading,
      QR_CODE_SCAN_FLAG: state => state.misc.QR_CODE_SCAN_FLAG
    })
  },
  watch: {
    QR_CODE_SCAN_FLAG (value) {
      // console.log('QR_CODE_SCAN_FLAG', value)
      if (value) {
        document.getElementsByTagName("body")[0].style.backgroundImage = 'none'
      } else {
        document.getElementsByTagName("body")[0].style.backgroundImage = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/mobileLayout';
</style>
