import axios from '@/plugins/axios'
import { formatFromApi, formatToApi } from '@/utils/DepenseUtil'

export default {
  namespaced: true,

  state: {
    depense: [],
    depenses: []
  },
  getters: {
    getDepenses (state) {
      return state.depenses
    },
    getDepense (state) {
      return state.depense
    },
  },
  mutations: {
    SET_DEPENSES(state, value) {
      state.depenses = value
    },
    SET_DEPENSE(state, value) {
      state.depense = value
    }
  },
  actions: {
    // eslint-disable-next-line
    async getAllDepensesByIndependent({ commit }, independentId) {
      return new Promise((resolve, reject) => {
        axios.api.get('/depense/independent/' + independentId).then(res => {
          let results = res.data.map((ndf) => formatFromApi(ndf))
          commit('SET_DEPENSES', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getRefundedClientInvitation({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get(`/ndf/refunded-client-invitations/${data.independentId}/${data.payId}`).then(res => {
          let results = res.data.map((depense) => formatFromApi(depense))
          commit('SET_DEPENSES', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createDepense({ commit }, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.post('/depense', data).then(res => {
          let results = formatFromApi(res.data)
          commit('SET_DEPENSE', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteDepense(_, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.delete('/depense/' + data.id).then(res => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateDepense({ commit }, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.put('/depense/' + data.id, data).then(res => {
          let results = formatFromApi(res.data)
          commit('SET_DEPENSE', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async assignDepense(_, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.post('/depense/' + data.id + '/assign', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async unassignDepense({ commit }, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.post('/depense/' + data.id + '/unassign', data).then(res => {
          let results = formatFromApi(res.data)
          commit('SET_DEPENSE', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    }
  },
}
