import {setDateWithoutHours} from "@/utils/Date";

export const formatFromApi = (facturefournisseurI) => {
    let facturefournisseur = JSON.parse(JSON.stringify(facturefournisseurI))
    facturefournisseur.date = new Date(facturefournisseur.date)
    return facturefournisseur
}

export const formatToApi = (facturefournisseurI) => {
    let facturefournisseur = JSON.parse(JSON.stringify(facturefournisseurI))
    if (facturefournisseur.date) {
        facturefournisseur.date = setDateWithoutHours(facturefournisseur.date)
    }
    facturefournisseur.ttc = Math.round(facturefournisseur.ttc)
    facturefournisseur.ht = Math.round(facturefournisseur.ht)
    facturefournisseur.tva = Math.round(facturefournisseur.tva)
    facturefournisseur.item?.forEach((i) => {
        i.ttc = Math.round(i.ttc)
        i.ht = Math.round(i.ht)
        i.tva = Math.round(i.tva)
    })
    return facturefournisseur
}
