import axios from '@/plugins/axios'
import { formatFromApi, formatToApi } from '@/utils/MissionUtil'
const FileSaver = require('file-saver')

export default {
  namespaced: true,
  state: {
    mission: undefined,
    missions: [],
  },
  getters: {
    getMission (state) {
      return state.mission
    },
    getMissions (state) {
      return state.missions
    }
  },
  mutations: {
    SET_MISSION(state, value) {
      state.mission = value
    },
    SET_MISSIONS(state, value) {
      state.missions = value
    }
  },
  actions: {
    setMissions ({ commit }, data) {
      commit('SET_MISSIONS', data)
    },
    // eslint-disable-next-line
    async getAllMissionsForEmployees({ commit }) {
      return new Promise((resolve, reject) => {
        axios.api.get('/mission/employees').then((res) => {
          let results = res.data.map((m) => formatFromApi(m))
          commit('SET_MISSIONS', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // DEPRECATED
    // eslint-disable-next-line
    async getAllMissionsForAmendments({ commit }) {
      return new Promise((resolve, reject) => {
        axios.api.get('/mission/amendments').then((res) => {
          let results = res.data.map((m) => formatFromApi(m))
          commit('SET_MISSIONS', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async getAllMissionsNotCompleted({ commit }) {
      return new Promise((resolve, reject) => {
        axios.api.get('/mission/missionsNotCompleted').then((res) => {
          let results = res.data.map((m) => formatFromApi(m))
          commit('SET_MISSIONS', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async getOneMission({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get('/mission/' + data).then((res) => {
          const result = formatFromApi(res.data)
          commit('SET_MISSION', result)
          resolve(result)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async getMissionsByIndependent({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get('/mission/independent/' + data.id).then((res) => {
          let results = res?.data?.data?.map((m) => formatFromApi(m))
          if (!data.unStore) {
            commit('SET_MISSIONS', results)
          }
          resolve(res?.data?.data) // no format, let it as it is to prevent unexpected regressions -- must check later on
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getMissionsOfCurrentMonthByIndependent({ commit }, { independentId, month, year }) {
      return new Promise((resolve, reject) => {
        axios.api.get('/mission/by-date/independent/' + independentId + '/' + month + '/' + year).then((res) => {
          let results = res.data.map((m) => formatFromApi(m))
          commit('SET_MISSIONS', results)
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async getCompletedMissionsByIndependent({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get('/mission/completes/independent/' + data.id).then((res) => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async getDefaultNegotiatedRateValues({ commit }, data) {
      const formatData = {
        ...data,
        mission: {
          negotiatedRateUnit: data.mission.jsonCost.negotiatedRateUnit,
          teleworking: data.mission.jsonCost.teleworking,
          teleworkingRate: data.mission.jsonCost.teleworkingRate,
          negotiatedRate: data.mission.jsonCost.negotiatedRate?.value,
          startingDate: data.mission.jsonMission.startingDate,
          negotiatedRateValues: data.mission.jsonCost?.negotiatedRateValues
        }
      }
      return new Promise((resolve, reject) => {
        axios.api.post('/mission/default-negotiated-rate-values', formatData).then((res) => {
          res.data = res.data?.map(negotiatedRate => {
            return {
              ...negotiatedRate,
              costOnSite: negotiatedRate.costOnSite / 100,
              costOnRemote: negotiatedRate.costOnRemote / 100
            }
          })
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async getDefaultManagementFeeValues({ commit }, data) {
      const formatData = {
        ...data,
        mission: {
          managementFeeValue: data.mission.jsonCost.managementFee,
          managementFeeCap: data.mission.jsonCost.cap,
          startingDate: data.mission.jsonMission.startingDate,
          managementFeeValues: data.mission.jsonCost?.managementFeeValues
        }
      }
      return new Promise((resolve, reject) => {
        axios.api.post('/mission/default-management-fee-values', formatData).then((res) => {
          res.data = res.data?.map(negotiatedRate => {
            return {
              ...negotiatedRate,
              value: negotiatedRate.value,
              cap: typeof negotiatedRate.cap === 'number' ? negotiatedRate.cap / 100 : null
            }
          })
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // DEPRECATED
    // eslint-disable-next-line
    async getMissionsContracts({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get('/mission/independent/' + data.id + '/contracts').then((res) => {
          let results = res.data
          commit('SET_MISSIONS', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async saveMission({ commit }, data) {
      data = formatToApi(data)
      if (data.id) {
        return await new Promise((resolve, reject) => {
          axios.api.put('/mission', data).then(res => {
            resolve(res.data)
          }).catch((e) => {
            reject(e)
          })
        })
      } else {
        return await new Promise((resolve, reject) => {
          axios.api.post('/mission', data).then(res => {
            resolve(res.data)
          }).catch((e) => {
            reject(e)
          })
        })
      }
    },

    // eslint-disable-next-line
    async validateMission({ commit }, data) {
      data.payload = formatToApi(data.payload)
      return new Promise((resolve, reject) => {
        axios.api.patch('/mission/validate/' + data.id, data.payload).then((res) => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    async deleteMissionProcess ({ commit }, data) {
      const config = { params: {} }
      if (data.forceInvalidateIndependent) {
        config.params.forceInvalidateIndependent = true
      }
      return new Promise((resolve, reject) => {
        axios.api.delete('/mission/delete-process/' + data.mission.id, config).then((res) => {
          console.log('deleteMissionProcess', res)
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },

    // eslint-disable-next-line
    async commercialValidateMission({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.put('/mission/commercial-validate/' + data.id).then((res) => {
          const result = formatFromApi(res.data)
          commit('SET_MISSION', result)
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },

    setProfilMission({ commit }, missions) {
      commit('SET_MISSIONS', missions)
    },

    // eslint-disable-next-line no-unused-vars
    generateAmendment ({ commit }, { id, emailPayload, preview, forceDownload, commercialSettings }) {
      const data = {
        commercialSettings
      }
      if (!preview) {
        data.emailPayload = emailPayload
      }
      return new Promise((resolve, reject) => {
        axios.api.post('/mission/generate-amendment/' + id + (preview ? '?preview=true' : ''), data, { responseType: 'arraybuffer' }).then((res) => {
          const blob = new Blob([res.data], {
            type: 'application/pdf'
          });
          if (!preview || forceDownload) {
            FileSaver.saveAs(blob, 'amendment.pdf')
            resolve('SUCCESS')
          }
          if (preview) {
            resolve(blob)
          }
        }).catch((e) => {
          reject(e)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    importContract (store, { id, form, isAmendment }) {
      return new Promise((resolve, reject) => {
        axios.api.post('/mission/import-contract/' + id + (isAmendment ? '?amendment=true' : ''), form, {})
          .then(async (res) => {
            const result = formatFromApi(res.data)
            store.commit('SET_MISSION', result)
            resolve(result)
          }).catch((e) => {
            reject(e)
          })
      })
    },

    // { id, emailPayload, preview, forceDownload, commercialSettings, isAmendment }
    // eslint-disable-next-line no-unused-vars
    generateContract (store, generateContractPayload) {
      const data = {
        electronicallySigned: generateContractPayload.electronicallySigned,
        commercialSettings: generateContractPayload.commercialSettings,
        header: generateContractPayload.header,
        footer: generateContractPayload.footer,
      }
      if (!generateContractPayload.preview) {
        data.emailPayload = generateContractPayload.emailPayload
      }
      return new Promise((resolve, reject) => {
        axios.api.post('/mission/generate-contract/' + generateContractPayload.id + (generateContractPayload.preview ? '?preview=true' : '') + (generateContractPayload.isAmendment ? `${ generateContractPayload.preview ? '&' : '?' }isAmendment=true` : ''), data, { responseType: 'arraybuffer' })
          .then(async (res) => {
            const blob = new Blob([res.data], {
              type: 'application/pdf'
            });
            await store.dispatch('mission/getOneMission', generateContractPayload.id, { root: true })
            if (!generateContractPayload.preview || generateContractPayload.forceDownload) {
              FileSaver.saveAs(blob, 'contrat.pdf')
              resolve('SUCCESS')
            }
            if (generateContractPayload.preview) {
              resolve(blob)
            }
          }).catch((e) => {
            reject(e)
          })
      })
    },

    async cancelMissionContractSignature(store, payload) {
      return new Promise((resolve, reject) => {
        axios.api.post(`/mission/cancel-mission-contract-signature/${payload.signatureRequestId}/document/${payload.documentId}`)
            .then((res) => {
              resolve(res.data.data)
            }).catch((e) => {
          console.log('cancelContractSignature', e)
          reject(e)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    generateGeneralConditionsContract ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.api.post('/mission/generate-contract-general-conditions', {}, { responseType: 'arraybuffer' }).then((res) => {
          const blob = new Blob([res.data], {
            type: 'application/pdf'
          });
          // FileSaver.saveAs(blob, 'amendment.pdf')
          resolve(blob)
        }).catch((e) => {
          reject(e)
        })
      })
    },

    // eslint-disable-next-line no-unused-vars
    generateParticularConditionsContract ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.api.post('/mission/generate-contract-particular-conditions', {}, { responseType: 'arraybuffer' }).then((res) => {
          const blob = new Blob([res.data], {
            type: 'application/pdf'
          });
          // FileSaver.saveAs(blob, 'amendment.pdf')
          resolve(blob)
        }).catch((e) => {
          reject(e)
        })
      })
    },

    async importSourceData (store, data) {
      const payload = {
        missions: data
      }
      return new Promise((resolve, reject) => {
        axios.api.post('/mission/import/source-society-missions', payload).then(async (res) => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
