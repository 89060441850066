const FileSaver = require('file-saver')

export const formatSpreadsheetFile = async (data) => {
    const a2ab = (s) => {
        return new Uint8Array(s).buffer;
    }

    const buf = a2ab(data);
    const buffType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';
    let blob = new Blob([buf], {
        type: buffType
    });

    // todo: could define a specific filename directly from the back and link it there
    FileSaver.saveAs(blob, 'export.xlsx')
    return true
}

export const formatCsvFile = async (data) => {
    const a2ab = (s) => {
        return new Uint8Array(s).buffer;
    }

    const buf = a2ab(data);
    const buffType = 'text/csv';
    let blob = new Blob([buf], {
        type: buffType
    });

    FileSaver.saveAs(blob, 'template.csv')
    return true
}
