<template>
	<div id="web-layout-topbar" class="layout-topbar">
		<div class="layout-topbar-left">
			<router-link to="/" class="app-name">
        <img v-if="settings && societyLogo" alt="Le logo de votre société" class="text-primary mr-2" :src="societyLogo" height="70" />
        <Skeleton v-else shape="circle" size="2rem" class="mr-2"/>
			</router-link>
		</div>

		<div class="layout-topbar-right">
			<ul class="layout-topbar-actions">
        <Avatar v-if="isDevelopmentApp" label="D" class="bg-red-400" size="xlarge" />
        <li v-if="hasAccess('AdminEntreprise', false)" :class="['profile-item', {'active-topmenuitem fadeInDown': activeTopbarItem === 'profile'}]">
          <Button icon="pi pi-cog" class="p-button-text text-white" @click="openAdmin"/>
        </li>
				<li :class="['profile-item', {'active-topmenuitem fadeInDown': activeTopbarItem === 'profile'}]">
					<div class="profile-container" @click="onTopbarItemClick($event, 'profile')">
						<a href="#">{{ me?.role.name === constants.ROLES.ADMIN ? me?.first_name.charAt(0) + me?.last_name.charAt(0) : independent?.first_name.charAt(0) + independent?.last_name.charAt(0) }}</a>
						<div class="name-container">
							<span class="name">{{ me?.role.name === constants.ROLES.ADMIN ? me?.first_name + ' ' + me?.last_name : independent?.first_name + ' ' + independent?.last_name }}</span>
							<span class="job">{{ me?.role.name }}</span>
						</div>
						<i class="pi pi-angle-down"></i>
					</div>

					<ul class="profile-menu fadeInDown">
						<li>
							<a class="cursor-pointer" @click="goToProfile">
								<span>Profil</span>
							</a>
						</li>
						<li>
							<a class="cursor-pointer" @click="logout">
								<span>Déconnexion</span>
							</a>
						</li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import {getBase64FromDownloadedFile, hasAccess} from "@/utils/Misc";
import logoutMixin from "@/mixins/logoutMixin";
export default {
  mixins: [logoutMixin],
  emits: ['menubutton-click', 'topbar-menu-click', 'topbar-item-click', 'topbar-submenu-click', 'search-item-click'],
  props: ['activeTopbarItem', 'topbarMenuActive', 'layoutColor'],
	data() {
		return {
      hasAccess: hasAccess,
      societyLogo: null,
			menu: [
				{
					label: 'Menu',
					items: [
						{
							label: 'UI Kit', icon: 'pi pi-align-left',
							items: [
								{label: 'Form Layout', icon: 'pi pi-id-card', to: '/formlayout'},
								{label: 'Input', icon: 'pi pi-check-square', to: '/input'},
							]
						},
						{
							label: 'Hierarchy', icon: 'pi pi-align-left',
							items: [
								{
									label: 'Submenu 1', icon: 'pi pi-align-left',
									items: [
										{label: 'Submenu 1.1', icon: 'pi pi-align-left'},
										{label: 'Submenu 1.2', icon: 'pi pi-align-left'},
									]
								},
								{
									label: 'Submenu 2', icon: 'pi pi-align-left',
									items: [
										{label: 'Submenu 2.1', icon: 'pi pi-align-left'},
									]
								},
							]
						}
					]
				}
			]
		}
	},
  computed: {
    ...mapState({
      me: state => state.auth.me,
      independent: state => state.independent.independent,
      settings: state => state.misc.settings,
      constants: state => state.constants
    }),
    isDevelopmentApp () {
      return process.env.NODE_ENV === 'development'
    }
  },
  watch: {
    settings (val, old) {
      if (val?.Entreprise?.logo !== old?.Entreprise?.logo) {
        this.loadLogo()
      }
    }
  },
  async created () {
    await this.loadLogo()
  },
	methods: {
    openAdmin () {
      this.$router.push({ name: 'AdminEntreprise' })
    },
    async goToProfile () {
      await this.$router.push({ name: 'UserProfile' })
    },
    onMenuButtonClick(event) {
      this.$emit('menubutton-click', event);
    },
    onTopbarItemClick(event, item) {
      if(item === 'search' && this.$refs.input1) {
        setTimeout(() => {
          this.$refs.input1.$el.focus();
          this.$refs.input2.$el.focus();
        }, 400);
      }

      this.$emit('topbar-item-click', {originalEvent: event, item: item});
      event.preventDefault();
    },
    async loadLogo () {
      if (this.settings?.Entreprise?.logo) {
        const downloadedLogo = await this.$store.dispatch('file/downloadFile', { id: this.settings?.Entreprise?.logo })
        // todo: change for image
        getBase64FromDownloadedFile(downloadedLogo, (result) => {
          this.societyLogo = result
        })
      }
    }
	}
}
</script>
