import axios from '@/plugins/axios'
import { formatFromApi, formatToApi, formatDocFromApi } from '@/utils/IndependentUtil'

export default {
  namespaced: true,

  state: {
    independent: undefined,
    independents: [],
  },
  getters: {
    getIndependent (state) {
      return state.independent
    },
    getIndependents (state) {
      return state.independents
    }
  },
  mutations: {
    SET_INDEPENDENT(state, value) {
      state.independent = value
    },
    SET_INDEPENDENTS(state, value) {
      state.independents = value
    }
  },
  actions: {
    // eslint-disable-next-line
    async getAllIndependents({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get(`/independent${data?.onlyRhValidated ? '?onlyValidatedByCommercial=true' : data?.all ? '?all=true' : ''}`).then(res => {
          let results = res.data.map((indep) => formatFromApi(indep))
          commit('SET_INDEPENDENTS', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    async getAllIndependentsFiltered({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get(`/independent/getFiltered?input=${data}`).then(res => {
          let results = res.data.map((indep) => formatFromApi(indep))
          // commit('SET_INDEPENDENTS', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    createPayRollIndependentDocument (store, data) {
      return new Promise((resolve, reject) => {
        axios.api.post('/independent/payroll-document/' + data?.linker, { ...data?.document }, {})
          .then((res) => {
            resolve(formatDocFromApi(res.data))
          }).catch((e) => {
            reject(e)
          })
      })
    },
    async getOneIndependent({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get('/independent/' + data).then((res) => {
          const result = formatFromApi(res.data)
          commit('SET_INDEPENDENT', result)
          resolve(result)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getCagnotteByIndependent(_, data) {
      return new Promise((resolve, reject) => {
        axios.api.get('/independent/cagnotte/' + data).then((res) => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getAllSummariesPerYear(_, data) {
      return new Promise((resolve, reject) => {
        axios.api.get('/independent/summary/' + data).then((res) => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getSummaryByIndependentPerMonth(_, data) {
      return new Promise((resolve, reject) => {
        axios.api.post('/independent/summary/' + data.id,  { range: data.range }).then((res) => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getExpensesSynthesis(_, data) {
      console.log('getExpensesSynthesis')
      return new Promise((resolve, reject) => {
        axios.api.post('/independent/summary-expenses/' + data.id,  { range: data.range }).then((res) => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getOneIndependentByUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get('/independent/user/' + data).then((res) => {
          const result = formatFromApi(res.data.data[0])
          commit('SET_INDEPENDENT', result)
          resolve(result)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getOneIndependentForRegister({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get('/independent/register/' + data).then((res) => {
          const result = formatFromApi(res.data.data[0])
          commit('SET_INDEPENDENT', result)
          resolve(result)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async getIndependentExitDocuments({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get('/independent/exit-documents/' + data.id + '/').then((res) => {
          const result = res.data
          resolve(result)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateIndependent(_, data) {
      data.payload = formatToApi(data.payload)
      return new Promise((resolve, reject) => {
        axios.api.put('/independent/' + data.id, data.payload).then((res) => {
          const result = formatFromApi(res.data)
          // commit('SET_INDEPENDENT', result)
          resolve(result)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async validateIndependent({ commit, dispatch }, data) {
      data.payload = formatToApi(data.payload)
      return new Promise((resolve, reject) => {
        axios.api.patch('/independent/validate/' + data.id, data.payload).then(async (res) => {
          // prevent from role to not be updated properly due to changing role while being connected
          await dispatch('auth/refresh', null, { root: true })
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async importSourceData (store, data) {
      const payload = {
        independents: data
      }
      return new Promise((resolve, reject) => {
        axios.api.post('/independent/import/source-society-independents', payload).then(async (res) => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async importIndependentBaseSalary (store, data) {
      const payload = {
        independents: data
      }
      return new Promise((resolve, reject) => {
        axios.api.post('/independent/import/base-salary', payload).then(async (res) => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async importIndependentDates (store, data) {
      const payload = {
        independents: data
      }
      return new Promise((resolve, reject) => {
        axios.api.post('/independent/import/dates', payload).then(async (res) => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },

    async setIndependent ({ commit }, data) {
      commit('SET_INDEPENDENT', data)
    }
  }
}
