const getDate = (d) => {
    if (d instanceof Date && !isNaN(d)) {
        return d
    }
    return null
}

export const getDateFormatted = (d, overrideOptions = null) => {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const date = new Date(d)
    if (date.toString() === 'Invalid Date') return ''
    return date?.toLocaleDateString('fr-FR', overrideOptions !== null ? overrideOptions : options)
}

export const getFullTextMonth = (d) => {
    const date = new Date(d);
    const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    return months[date.getMonth()];
}

export const setDateWithoutHours = (date) => {
    if (!date) return null
    let newDate = new Date(date)
    if (newDate.toString() === 'Invalid Date') return ''
    const offset = newDate.getTimezoneOffset()
    newDate = new Date(newDate.getTime() - (offset*60*1000))
    return newDate.toISOString().split('T')[0]
}

export default {
    getDate,
    getFullTextMonth,
}
