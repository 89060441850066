import {setDateWithoutHours} from "@/utils/Date";

export const formatFromApi = (payI) => {
    let pay = JSON.parse(JSON.stringify(payI))

    if (pay.jsonImportedPay) {
        if (pay.jsonImportedPay.globalCompanyCost) {
            pay.jsonImportedPay.globalCompanyCost = pay.jsonImportedPay.globalCompanyCost / 100
        }
        if (pay.jsonImportedPay.brutSalary) {
            pay.jsonImportedPay.brutSalary = pay.jsonImportedPay.brutSalary / 100
        }
        if (pay.jsonImportedPay.salaryExpense) {
            pay.jsonImportedPay.salaryExpense = pay.jsonImportedPay.salaryExpense / 100
        }
        if (pay.jsonImportedPay.reserve) {
            pay.jsonImportedPay.reserve = pay.jsonImportedPay.reserve / 100
        }
        if (pay.jsonImportedPay.pas) {
            pay.jsonImportedPay.pas = pay.jsonImportedPay.pas / 100
        }
        if (pay.jsonImportedPay.netToPay) {
            pay.jsonImportedPay.netToPay = pay.jsonImportedPay.netToPay / 100
        }
        if (pay.jsonImportedPay.netSalary) {
            pay.jsonImportedPay.netSalary = pay.jsonImportedPay.netSalary / 100
        }
        if (pay.jsonImportedPay.flatRate) {
            pay.jsonImportedPay.flatRate = pay.jsonImportedPay.flatRate / 100
        }
        if (pay.jsonImportedPay.employerCharge) {
            pay.jsonImportedPay.employerCharge = pay.jsonImportedPay.employerCharge / 100
        }
        if (pay.jsonImportedPay.taxableRevenue) {
            pay.jsonImportedPay.taxableRevenue = pay.jsonImportedPay.taxableRevenue / 100
        }
    }

    if (pay.jsonPay) {
        if (pay.jsonPay.bounty) {
            pay.jsonPay.bounty = pay.jsonPay.bounty / 100
        }
        if (pay.jsonPay.bountyPass) {
            pay.jsonPay.bountyPass = pay.jsonPay.bountyPass / 100
        }
        if (pay.jsonPay.salary) {
            pay.jsonPay.salary = pay.jsonPay.salary / 100
        }
        if (pay.jsonPay.salaryPas) {
            pay.jsonPay.salaryPas = pay.jsonPay.salaryPas / 100
        }
    }
    if (pay.jsonInfo) {
        if (pay.jsonInfo.subscriptionsTransport?.length > 0) {
            pay.jsonInfo.subscriptionsTransport = pay.jsonInfo.subscriptionsTransport.map((subscriptionTransport) => {
                return {
                    ...subscriptionTransport,
                    price: subscriptionTransport.price / 100,
                    endingDate: new Date(subscriptionTransport.endingDate)
                }
            })
        }
        if (pay.jsonInfo.pee) {
            pay.jsonInfo.pee = pay.jsonInfo.pee / 100
        }
        if (pay.jsonInfo.perco) {
            pay.jsonInfo.perco = pay.jsonInfo.perco / 100
        }
        if (pay.jsonInfo.ASTD) {
            pay.jsonInfo.ASTD = pay.jsonInfo.ASTD / 100
        }
        if (pay.jsonInfo.regul && pay.jsonInfo.regul.price) {
            pay.jsonInfo.regul.price = pay.jsonInfo.regul.price / 100
        }
    }

    return pay
}

export const formatToApi = (payI) => {
    let pay = JSON.parse(JSON.stringify(payI))

    if (pay.date) {
        pay.date = setDateWithoutHours(pay.date)
    }
    if (pay.jsonPay) {
        // if (pay.jsonPay.bounty) {
        //     pay.jsonPay.bounty = Math.round(pay.jsonPay.bounty * 100)
        // }
        if (pay.jsonPay.bountyPass) {
            pay.jsonPay.bountyPass = Math.round(pay.jsonPay.bountyPass * 100)
        }
        // if (pay.jsonPay.salary) {
        //     pay.jsonPay.salary = Math.round(pay.jsonPay.salary * 100)
        // }
        if (pay.jsonPay.salaryPas) {
            pay.jsonPay.salaryPas = Math.round(pay.jsonPay.salaryPas * 100)
        }
    }
    if (pay.jsonInfo) {
        if (pay.jsonInfo.subscriptionsTransport?.length > 0) {
            pay.jsonInfo.subscriptionsTransport = pay.jsonInfo.subscriptionsTransport.map((subscriptionTransport) => {
                return {
                    ...subscriptionTransport,
                    price: Math.round(subscriptionTransport.price * 100),
                    endingDate: setDateWithoutHours(subscriptionTransport.endingDate),
                }
            })
        }
        if (pay.jsonInfo.pee) {
            pay.jsonInfo.pee = Math.round(pay.jsonInfo.pee * 100)
        }
        if (pay.jsonInfo.perco) {
            pay.jsonInfo.perco = Math.round(pay.jsonInfo.perco * 100)
        }
        if (pay.jsonInfo.ASTD) {
            pay.jsonInfo.ASTD = Math.round(pay.jsonInfo.ASTD * 100)
        }
        if (pay.jsonInfo.regul && pay.jsonInfo.regul.price) {
            pay.jsonInfo.regul.price = Math.round(pay.jsonInfo.regul.price * 100)
        }
        if (pay.jsonInfo.forfait) {
            pay.jsonInfo.forfait = Math.round(pay.jsonInfo.forfait * 100)
        }
    }

    if (pay.jsonImportedPay) {
        if (pay.jsonImportedPay.globalCompanyCost) {
            pay.jsonImportedPay.globalCompanyCost = pay.jsonImportedPay.globalCompanyCost * 100
        }
        if (pay.jsonImportedPay.brutSalary) {
            pay.jsonImportedPay.brutSalary = pay.jsonImportedPay.brutSalary * 100
        }
        if (pay.jsonImportedPay.salaryExpense) {
            pay.jsonImportedPay.salaryExpense = pay.jsonImportedPay.salaryExpense * 100
        }
        if (pay.jsonImportedPay.reserve) {
            pay.jsonImportedPay.reserve = pay.jsonImportedPay.reserve * 100
        }
        if (pay.jsonImportedPay.pas) {
            pay.jsonImportedPay.pas = pay.jsonImportedPay.pas * 100
        }
        if (pay.jsonImportedPay.netToPay) {
            pay.jsonImportedPay.netToPay = pay.jsonImportedPay.netToPay * 100
        }
        if (pay.jsonImportedPay.netSalary) {
            pay.jsonImportedPay.netSalary = pay.jsonImportedPay.netSalary * 100
        }
        if (pay.jsonImportedPay.flatRate) {
            pay.jsonImportedPay.flatRate = pay.jsonImportedPay.flatRate * 100
        }
        if (pay.jsonImportedPay.employerCharge) {
            pay.jsonImportedPay.employerCharge = pay.jsonImportedPay.employerCharge * 100
        }
    }

    return pay
}
