import { createWebHistory , createRouter } from 'vue-router'
import appRoutes from './routes/app'
import { store } from '@/store'
import {app} from "@/main";
import axios from "@/plugins/axios";

const routes = [...appRoutes]
const router = createRouter({
    scrollBehavior(to) {
      if (to.hash) {
          setTimeout(() => {
              const element = document.getElementById(to.hash.replace(/#/, ''))
              if (element && element.scrollIntoView) {
                  // element.scrollIntoView({block: 'end', behavior: 'smooth'})
                  document.getElementsByClassName('layout-content-wrapper')[0].scrollTo({ left: 0, top: element.offsetTop, behavior: "smooth" })
              }
          }, 1)
          return { selector: to.hash }
      }
    },
    history: createWebHistory (),
    routes
})

router.beforeEach(async (to, from, next) => {
    // console.log('to', to)
    // console.log('from', from)
    const isAuthenticatedOptions = {
        ERROR_SET_LOGGED_IN: to.name !== 'Login'
    }
    if (process.env.VUE_APP_PDF_TEMPLATE === 'true') {
        next()
    } else {
        if (to.name === 'MobileSocietyAccess') {
            return next()
        }
        if (!app.config.globalProperties.isBrowserApp()) {
            // todo: later on might need to store those values into json file instead of just localStorage
            if (to.name === 'QrCodeScanner') {
                if (to.query.forceCamera === 'true') {
                    await store.dispatch('misc/updateQrCodeForceCameraFlag', true)
                }
                return next()
            }
            const localStoredUrl = localStorage.getItem('api_url')
            if (to.query.api_url) {
                localStorage.setItem('api_url', to.query.api_url)
                axios.api.defaults.baseURL = to.query.api_url
            } else if (localStoredUrl) {
                axios.api.defaults.baseURL = localStoredUrl
            } else if (!localStoredUrl) {
                return next({ name: 'QrCodeScanner' })
            }
        }

        try {
            await store.dispatch('auth/getAuthInfo')
        } catch (e) {
            // consider that the current api_url is not up to log anyone
            if (!app.config.globalProperties.isBrowserApp()) {
                return next({ name: 'QrCodeScanner' })
            }
        }

        if (to.name === 'PageNotFound') {
            return next()
        } else if (to.meta.requiresAuth !== false) {
            await store.dispatch('auth/isAuthenticated', isAuthenticatedOptions)
                .then(async (res) => {
                    if (!store.state.misc.settings) {
                        await store.dispatch('misc/getAllSettings', {})
                    }
                    // redirection si l'utilisateur courrant à le rôle "prospect". Le rôle change si user a fini de remplir son profil.
                    if (res?.role?.name === store.state.constants.ROLES.PROSPECT && to.name !== 'Profil') {
                        return next({ name: 'Profil' })
                    } else if (res?.role?.name !== store.state.constants.ROLES.PROSPECT && to.name === 'Profil') {
                        return next({ name: 'Home' })
                    } else if (res?.role?.name === store.state.constants.ROLES.INDEPENDENT && to.name === 'Home') {
                        return next({ name: 'DashboardPorte' })
                    } else {
                        let canGo = false
                        if (to.meta.requiresPermission === false) return next() // prevent from double call of next() (vue router warn)
                        store.state.auth.me.custom_roles.forEach(role => {
                            role.RolePermission_id.permissions.forEach(permission => {
                                if (permission.Permission_id.app === 'front' && permission.Permission_id.route && to.fullPath.startsWith(permission.Permission_id.route)) {
                                    canGo = true
                                }
                            })
                        })
                        if (canGo) return next()
                        else return next({ name: 'Home' })
                    }
                }).catch(() => {
                    return next({name: 'Login'})
                })
        } else {
            await store.dispatch('auth/isAuthenticated', isAuthenticatedOptions).then(() => {
                return next({ name: 'Home' })
            }).catch(() => {
                return next()
            })
        }
    }
})


router.afterEach(async (to) => {
    // console.log('------------------AfterEach------------------')
    window.scrollTo(0, 0)
    // console.log(to.meta)
    // console.log(to.path)
    // console.log(to.meta.sideContentMenu)
    if (to.meta.sideContentMenu) {
        // console.log('to.name', to.name)
        // console.log('to.meta.sideContentMenu', JSON.parse(JSON.stringify(to.meta.sideContentMenu.subItems)))
        // to.meta.sideContentMenu.showIndependents = store.state.auth.me?.role.name === store.state.constants.ROLES.ADMIN,
        await store.dispatch('sidebar/updateSideContent', to.meta.sideContentMenu)
        // console.log('store.state.sidebar.sideContent', store.state.sidebar.sideContent)
    }
})

// eslint-disable-next-line no-unused-vars
router.beforeResolve(async to => {
    if (store.state.misc.contentChanged) {
        await store.dispatch('misc/updateIsPreventForgetSavingDialog', { isActive: true, routeToRedirect: to })
        return false
    }
})

export default router
