import {formatFromApi as IndependentFormatFrom, formatToApi as IndependentFormatTo} from './IndependentUtil';

export const formatFromApi = (simulationI) => {
    let simulation = JSON.parse(JSON.stringify(simulationI))
    simulation.independent = IndependentFormatFrom(simulation.independent)

    simulation?.jsonMissions?.missions.forEach((mission) => {
        mission.base = mission.base / 100
        // todo: don't import the constants -- prefer the all-in refacto in the back
        if (mission.type === 'forfait') {
            mission.coefPaid = mission.coefPaid * 100
        }
    })
    if (simulation.jsonMissions?.managementFee) {
        if (typeof simulation.jsonMissions.managementFee.cap === 'number') {
            simulation.jsonMissions.managementFee.cap = simulation.jsonMissions.managementFee.cap / 100
        } else {
            simulation.jsonMissions.managementFee.cap = null
        }
    }
    if (simulation.jsonDeplacements) {
        for (var key of Object.keys(simulation.jsonDeplacements)) {
            if (simulation.jsonDeplacements[key]) {
                if (simulation.jsonDeplacements[key].price) {
                    simulation.jsonDeplacements[key].price = simulation.jsonDeplacements[key].price / 100
                }
            }
        }
    }
    if (simulation.date_created) {
        simulation.date_created = new Date(simulation.date_created)
    }

    // if (simulation.commercial && simulation.commercial.user) {
    //     console.log('simulation.commercial')
    //     simulation.commercial.user.fullName = simulation.commercial.user.first_name + ' ' + simulation.commercial.user.last_name
    // }

    return simulation
}

export const formatToApi = (simulationI) => {
    let simulation = JSON.parse(JSON.stringify(simulationI))
    simulation.independent = IndependentFormatTo(simulation.independent)

    simulation.jsonMissions.missions.forEach((mission) => {
        mission.base = Math.round(mission.base * 100)
        // todo: don't import the constants -- prefer the all-in refacto in the back
        if (mission.type === 'forfait') {
            mission.coefInvoiced = 1
            mission.coefPaid = mission.coefPaid / 100
            // if (mission.jsonCost.dueDates) {
            //     mission.jsonCost.dueDates.forEach((dd) => {
            //         dd.price = Math.round(dd.price * 100)
            //     })
            // }
        }
    })
    if (simulation.jsonMissions.managementFee) {
        if (typeof simulation.jsonMissions.managementFee.cap === 'number') {
            simulation.jsonMissions.managementFee.cap = simulation.jsonMissions.managementFee.cap * 100
        } else {
            simulation.jsonMissions.managementFee.cap = null
        }
    }
    if (simulation.jsonDeplacements) {
        for (var key of Object.keys(simulation.jsonDeplacements)) {
            if (simulation.jsonDeplacements[key]) {
                if (simulation.jsonDeplacements[key].price) {
                    simulation.jsonDeplacements[key].price = Math.round(simulation.jsonDeplacements[key].price * 100)
                }
            }
        }
    }

    return simulation
}
