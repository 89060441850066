import axios from '@/plugins/axios'

export default {
    namespaced: true,

    state: {
        publicHolidays: [],
        publicHoliday: {}
    },
    getters: {

    },
    mutations: {
        SET_PUBLIC_HOLIDAY (state, data) {
            state.publicHoliday = data
        },
        SET_PUBLIC_HOLIDAYS (state, data) {
            state.publicHolidays = data
        },
        UPDATE_PUBLIC_HOLIDAY (state, data) {
            state.publicHoliday = data
        },
        ADD_PUBLIC_HOLIDAYS (state, data) {
            state.publicHolidays.push(data)
        },
        UPDATE_PUBLIC_HOLIDAYS (state, data) {
            const index = state.publicHolidays.findIndex((publicHoliday) => { return publicHoliday.id === data.id })
            if (index !== -1) {
                const previousValue = state.publicHolidays[index]
                state.publicHolidays.splice(index, 1, {
                    ...previousValue,
                    ...data
                })
            }
        },
        DELETE_PUBLIC_HOLIDAYS (state, data) {
            const index = state.publicHolidays.findIndex((publicHoliday) => { return publicHoliday.id === data.id })
            if (index !== -1) {
                state.publicHolidays.splice(index, 1)
            }
        }
    },
    actions: {
        async getPublicHolidays(store, { years }) {
            const config = { params: {} }
            if (years) config.params.years = years
            return new Promise((resolve, reject) => {
                axios.api.get('/public-holidays/holidays', config).then((res) => {
                    store.commit('SET_PUBLIC_HOLIDAYS', res.data.data)
                    resolve(store.state.publicHolidays)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        async getPublicHoliday(store, { id }) {
            return new Promise((resolve, reject) => {
                axios.api.get(`/public-holidays/${id}`).then((res) => {
                    store.commit('SET_PUBLIC_HOLIDAY', res.data)
                    resolve(store.state.publicHoliday)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        async getPublicHolidayByYear(store, { year }) {
            return new Promise((resolve, reject) => {
                axios.api.get(`/public-holidays/by-year/${year}`).then((res) => {
                    if (res.data.data.length === 0) reject('not-found')
                    store.commit('SET_PUBLIC_HOLIDAY', res.data.data[0])
                    resolve(store.state.publicHoliday)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        async addPublicHolidays(store, data) {
            return new Promise((resolve, reject) => {
                axios.api.post(`/public-holidays`, data).then((res) => {
                    store.commit('ADD_PUBLIC_HOLIDAYS', res.data)
                    resolve(store.state.publicHolidays)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        async updatePublicHoliday(store, data) {
            return new Promise((resolve, reject) => {
                axios.api.put(`/public-holidays/${data.id}`, data).then((res) => {
                    store.commit('UPDATE_PUBLIC_HOLIDAYS', res.data)
                    store.commit('UPDATE_PUBLIC_HOLIDAY', res.data)
                    resolve(store.state.publicHolidays)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        async deletePublicHoliday(store, data) {
            return new Promise((resolve, reject) => {
                axios.api.delete(`/public-holidays/${data.id}`, data).then((res) => {
                    store.commit('DELETE_PUBLIC_HOLIDAYS', res.data)
                    resolve(store.state.publicHolidays)
                }).catch((e) => {
                    reject(e)
                })
            })
        }
    },
}
