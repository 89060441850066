<template>
  <Dialog
      v-model:visible="dialogActive"
      :breakpoints="{'960px': '75vw', '640px': '90vw'}"
      :style="{width: '50vw'}"
      closable
      :draggable="false"
      modal
      @hide="closeDialog"
  >
    <template #header>
      <div>
        <h3 class="text-black-alpha-90">
          Choix d'une date de début de mission
        </h3>
      </div>
    </template>
    <div class="flex flex-row align-items-center justify-content-center">
      <div class="field">
        <label for="missionStartingDate">Date de début de mission</label>
        <Calendar id="missionStartingDate" :showIcon="true" v-model="startingDate"
                  date-format="dd/mm/yy" :manualInput="false" class="calendar-custom h-auto"
                  input-class="h-auto"/>
      </div>
    </div>
    <template #footer>
      <Button label="Valider" @click="validateStartingDate"/>
    </template>
  </Dialog>
</template>

<script>
export default {
  name: "DialogForceStartingDate",
  emits: ['closeDialog', 'validateStartingDate'],
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
  },
  data () {
    return {
      dialogActive: false,
      startingDate: new Date()
    }
  },
  watch: {
    async isActive(value) {
      this.startingDate = new Date()
      this.dialogActive = value
    }
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog')
    },
    validateStartingDate () {
      this.$emit('validateStartingDate', this.startingDate)
    }
  }
}
</script>

<style scoped>

</style>
