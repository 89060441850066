import {store} from "@/store";
import router from "@/router";

export const capitalizeWord = (word) => {
    return word.charAt(0).toUpperCase() + word.toLowerCase().slice(1)
}

export const getBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        callback(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

export const getBase64FromDownloadedFile = (downloadedFile, callback) => {
    if (!downloadedFile?.data?.data) return callback(null)
    const file = new Blob([new Uint8Array(downloadedFile.data.data)], {
        type: downloadedFile.mimetype
    })
    getBase64(file, (result) => {
        callback(result)
    })
}

export const getBase64FromUploadedFile = (uploadedFile, callback) => {
    const form = new FormData()
    form.append('filename', uploadedFile.files[0].name)
    form.append('file', uploadedFile.files[0])
    getBase64(uploadedFile.files[0], (result) => {
        callback(result, form)
    });
}

export const formatQueryArray = (arrayName, values) => {
    if (values.length === 0) { return '' }
    let formattedArray = ''
    values.forEach((value, index) => {
        formattedArray += `${arrayName}[]=${value + ((values.length - 1 === index) ? '' : '&')}`
    })
    return formattedArray
}

export const generateId = () => {
    return Date.now().toString(36) + Math.random().toString(36)
}

/**
 * Use this function only if you sure that this will go in HtmlToPdfMake in back
 * Use to prevent getting some weird (example)->  style=\"color: rgb(0, 102, 204);\">
 * (WARNING) This function also assume that htmlTemplate string to replace will not be used as proper usable text
 * @param htmlTemplate
 */
export const formatHtmlToPdf = (htmlTemplate) => {
    if (!htmlTemplate) return ''
    const correctedHtmlTemplate = htmlTemplate
    correctedHtmlTemplate.replaceAll("style=\"", 'style="')
    correctedHtmlTemplate.replaceAll(";\">", ';">')
    return correctedHtmlTemplate
}

export const formatRateToApi = (rate) => {
    if (rate === 0) {
        return 0
    }
    // console.log(`rate: ${rate}`, rate / 100, (rate / 100).toFixed(6))
    return parseFloat((rate / 100).toFixed(6)) // todo: SAVE TO FIXED 6 MAXIMUM ~~
}

export const formatRateFromApi = (rate) => {
    if (!rate) return 0
    return (rate * 100).toFixed(4)
}

export const formatAmountToApi = (amount) => {
    return amount * 100
}

export const formatAmountFromToApi = (amount) => {
    return amount / 100
}

export const REGEX_VALIDATORS = {
    EMAIL: /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{1,30})+$/
}

export const hasAccess = (routeName, write) => {
    const realRoute = router.getRoutes().find(r => r.name === routeName)
    if (realRoute) {
        if (realRoute.meta.requiresPermission === false) return true
        else {
            let yes = false
            store.state.auth.me?.custom_roles?.forEach(role => {
                role.RolePermission_id?.permissions?.forEach(permission => {
                    if (permission.Permission_id.app === 'front' && permission.Permission_id.route && realRoute.path.startsWith(permission.Permission_id.route) && permission.Permission_id.write === write) {
                        yes = true
                    }
                })
            })
            if (yes) return true
        }
    }
    return false
}

export const disablePComponentsOfCurrentPage = (document) => {
    const element = document.getElementById('layout-content-wrapper')
    let elements = element.getElementsByClassName('p-component')
    for (const el of elements) {
        if (!el.classList.contains('p-scrollpanel')) {
            el.disabled = true
            el.style.pointerEvents = 'none'
            const children = el.getElementsByTagName('*')
            for (let i = 0; i < children.length; i++) {
                children[i].disabled = true
                children[i].style.pointerEvents = 'none'
            }
        }
    }
}

export const getLastValuePassed = (values, options = {}) => {
    if (!values?.length || values?.length === 0) return null
    // using the mission starting date if the current date is inferior to that one
    const monthlyDateLength = options?.currentDate?.length - 3
    const sortedValues = values
        .filter(value => (value.unit ? value.unit === options?.negotiatedRateUnit : true)
            && (options?.currentDate ? (options.monthOnly ?
                value.effectiveDate.substring(0, monthlyDateLength) <= options.currentDate.substring(0, monthlyDateLength)
                : (value.effectiveDate <= options.currentDate)) : true))
        .sort((a, b) => a.effectiveDate > b.effectiveDate ? -1 : a.effectiveDate < b.effectiveDate ? 1 : 0)
    if (sortedValues.length === 0) return null
    return sortedValues[0]
}

export const getContractEndingDate = (mission) => {
    if (!mission.jsonMission) return null
    return mission.jsonMission.renewalEndingDate ?
        (mission.jsonMission.initialEndingDate < mission.jsonMission.renewalEndingDate ? mission.jsonMission.initialEndingDate : mission.jsonMission.renewalEndingDate)
        : mission.jsonMission.initialEndingDate
}

export const callbackObjectColors = () => {
    return {
        danger: () => 'red',
        warning: () => 'orange',
        good: () => 'blue',
        default: () => 'black'
    }
}

export const callbackObjectSort = () => {
    return {
        danger: () => 1,
        warning: () => 2,
        good: () => 4,
        default: () => 3,
    }
}

export const getEndContractAction = (endContractObject, constants, callbackObject) => {
    if (!endContractObject.value) return callbackObject.default()
    if (endContractObject.renewalValue === constants.RENEWAL_TYPE.AUTOMATIC)
        return callbackObject.good()
    let endContractDate = endContractObject.value
    if (typeof endContractObject.value === 'string')
        endContractDate = new Date(endContractObject.value)
    const nbDays = (endContractDate?.getTime() - new Date().getTime()) / 1000 / 3600 / 24
    if (nbDays <= 5) {
        return callbackObject.danger()
    } else if (nbDays <= 35) {
        return callbackObject.warning()
    }
    return callbackObject.default()
}

// DEPRECATED FUNC
export const findManagementFeeForMonthAndYear = (values, month, year) => {
    const sorted = JSON.parse(JSON.stringify(values)).sort((c, d) => { return new Date(c.effectiveDate).getTime() - new Date(d.effectiveDate).getTime() })
    let retained = null
    for (const value of sorted) {
        if ((month >= new Date(value.effectiveDate).getMonth() && year == new Date(value.effectiveDate).getFullYear()) || year > new Date(value.effectiveDate).getFullYear()) {
            retained = value
        }
    }
    return retained
}

export const formatSiret = (siret) => {
    if (!siret || siret.length !== 14) return null
    return `${siret.substring(0, 3)} ${siret.substring(3, 6)} ${siret.substring(6, 9)} ${siret.substring(9, siret.length)}`
}

export const formatSiren = (siren) => {
    if (!siren || siren.length < 9) return null
    return `${siren.substring(0, 3)} ${siren.substring(3, 6)} ${siren.substring(6, 9)}`
}

export const formatPhoneNumber = (phone) => {
    if (!phone || phone?.length !== 10) return null
    return `${phone.substring(0, 2)}-${phone.substring(2, 4)}-${phone.substring(4, 6)}-${phone.substring(6, 8)}-${phone.substring(8, 10)}`
}

export const getOptionLabel = (options, value) => {
    if (!options || options?.length === 0) return null
    return options.find((option) => option.value === value)?.label
}

export const getRestPerCent = (value) => {
    return (10000 - value * 100) / 100
}

export const getBasesFromOptions = (defaultBases, specialBases, allBases) => {
    const bases = [...defaultBases, ...specialBases]
    return JSON.parse(JSON.stringify(allBases)).filter((baseOption) => {
        return bases.findIndex((defaultBaseOption) => defaultBaseOption === baseOption.type) !== -1;
    })
}

export const getMissionEndingDate = (mission, constants) => {
    const missionRange = getMissionRange(mission, constants)
    if (mission?.jsonMission?.renewal === constants.RENEWAL_TYPE.AUTOMATIC) {
        let missionEndingDate = new Date()
        missionEndingDate.setDate(missionRange.startingDate ? missionRange.startingDate.getDate() : 0)
        missionEndingDate.setMonth(new Date().getMonth() + 1)
        return missionEndingDate
    }
    return missionRange.endingDate
}

export const getMissionRange = (mission, constants) => {
    const currentDate = new Date()
    const initialStartingDate = (mission?.jsonMission?.startingDate ? new Date(mission.jsonMission.startingDate) : null)
    const initialEndingDate = (mission?.jsonMission?.initialEndingDate ? new Date(mission.jsonMission.initialEndingDate) : null)

    const useRenewalDate = mission?.jsonMission?.renewalEndingDate && mission?.jsonMission?.renewal === constants.RENEWAL_TYPE.ON &&
        (!initialEndingDate || currentDate > initialEndingDate)

    // useRenewalDate ?
    //     (mission?.jsonMission?.initialRenewalStartingDate ? new Date(mission?.jsonMission?.initialRenewalStartingDate) : initialStartingDate) :
    const startingDate = initialStartingDate

    const endingDate = useRenewalDate ?
        (mission?.jsonMission?.renewalEndingDate ? new Date(mission?.jsonMission?.renewalEndingDate) : initialEndingDate) :
        initialEndingDate

    return {
        startingDate,
        endingDate
    }
}
