<template>
  <component :is="layout" class="flex">
    <router-view
      v-model:layout="layout"
      :class="{'flex': getIsBrowserApp, 'h-full': !getIsBrowserApp}"
    />
  </component>
  <ConfirmDialog></ConfirmDialog>
</template>

<script>
import isBrowserAppMixin from "@/mixins/isBrowserAppMixin";
import Browser from "@/layouts/Browser.vue";
import Mobile from "@/layouts/Mobile.vue";
import {mapActions} from "vuex";
import {markRaw} from "vue";

export default {
  mixins: [isBrowserAppMixin],
  data() {
    return {
      layout: '',
    }
  },
  async beforeMount() {
    this.layout = this.getIsBrowserApp ? markRaw(Browser) : markRaw(Mobile)
  },
  async mounted() {
    await this.initDefaultStateWithConstants()
    if (!this.getIsBrowserApp) {
      document.addEventListener('deviceready', () => {
        window.plugins.universalLinks.subscribe('kaizen_deepLink', (eventData) => {
          this.$router.push(eventData.url)
        });
      })
    }
  },
  unmounted() {
    if (!this.getIsBrowserApp) {
      document.removeEventListener('deviceready', () => {})
    }
  },
  methods: {
    ...mapActions({
      initDefaultStateWithConstants: 'constants/initDefaultStateWithConstants',
    })
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.p-datatable-footer) {
  border-bottom: none !important;
}
::v-deep(.p-datatable-header) {
  border-top: none !important;
}
</style>

<style>
#app {
  display: flex;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  /*height: 100vh;*/
  /*margin-top: 60px;*/
}

.router-view {
  /*height: calc(100vh);*/
}

/*Pour la largeur des pop-up de filtre*/
.p-column-filter-overlay, .p-column-filter-overlay-menu {
  width: auto !important;
}
body {
  overflow: hidden !important;
}
.spacer {
  min-height: 5rem;
  height: 5rem;
}
.set-column {
  min-width: 5rem;
}
.custom-chip-validated {
  background-color: #ffffff;
  color: #97C353;
}
.custom-chip-waiting {
  background-color: #ffffff;
  color: #7C7C7C;
}
.custom-chip-outgoing {
  background-color: #feffcc;
  color: #dbdf0c;
}
.custom-chip-new {
  background-color: #cce4ff;
  color: #2E6BAB;
}
.custom-chip-ongoing {
  background-color: #ebffcc;
  color: #97C353;
}
.custom-chip-out {
  background-color: #e6e6e6;
  color: #7C7C7C;
}
.disabled-button {
  opacity: 0.6;
  background-color: grey;
  border-color: grey;
}
</style>
