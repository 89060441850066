import axios from '@/plugins/axios'
import { formatToApi, formatFromApi } from '@/utils/InvoiceUtil'

export default {
  namespaced: true,

  state: {
    invoices: [],
    invoice: undefined,
  },
  getters: {
    getInvoices (state) {
      return state.invoices
    },
    getInvoice (state) {
      return state.invoice
    }
  },
  mutations: {
    SET_INVOICES (state, value) {
      state.invoices = value
    },
    SET_INVOICE (state, value) {
      state.invoice = value
    },
    UPDATE_INVOICE (state, value) {
      let index = state.invoices.findIndex((invoice) => { return invoice.id === value.id })
      if (index !== -1) {
        const previousValue = state.invoices[index]
        state.invoices.splice(index, 1, {
          ...previousValue,
          ...value
        })
      }
    },
  },
  actions: {
    updateInvoice (store, data) {
      store.commit('UPDATE_INVOICE', data)
    },
    // eslint-disable-next-line
    async saveInvoice({ commit, dispatch }, data) {
      data = formatToApi(data)
      if (data.id) {
        return new Promise((resolve, reject) => {
          axios.api.put('/invoice', data).then(async (res) => {
            let invoice = formatFromApi(res.data)
            commit('SET_INVOICE', invoice)
            resolve(invoice)
          }).catch((e) => {
            reject(e)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          axios.api.post('/invoice', data).then(async (res) => {
            let invoice = formatFromApi(res.data)
            commit('SET_INVOICE', invoice)
            resolve(invoice)
          }).catch((e) => {
            reject(e)
          })
        })
      }
    },
    // eslint-disable-next-line
    async saveValidatedInvoice({ commit, dispatch }, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.put('/invoice/validated', data).then(async (res) => {
          let invoice = formatFromApi(res.data)
          commit('UPDATE_INVOICE', invoice)
          resolve(invoice)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async getAllInvoices({ commit, dispatch }, data) {
      let url = '/invoice'
      if (data.status) {
        url += '?status=' + data.status
      }
      return new Promise((resolve, reject) => {
        axios.api.get(url).then(async (res) => {
          let results = res.data.map((inv) => formatFromApi(inv))
          commit('SET_INVOICES', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async getInvoicesByIndependent({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get('/invoice/independent/' + data).then(async (res) => {
          let results = res.data.map((inv) => formatFromApi(inv))
          commit('SET_INVOICES', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async getLastInvoiceOfIndependent({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get('/invoice/last-invoice/independent/' + data).then(async (res) => {
          let results = res.data.map((inv) => formatFromApi(inv))
          resolve(results[0])
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async getLastInvoiceOfIndependentByMission({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get(`/invoice/last-invoice/independent/${data.independentId}/mission/${data.missionId}`).then(async (res) => {
          let results = res.data.map((inv) => formatFromApi(inv))
          resolve(results[0])
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async getOneInvoice({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get('/invoice/' + data.id).then(async (res) => {
          let result = formatFromApi(res.data)
          commit('SET_INVOICE', result)
          resolve(result)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async validateInvoice({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        axios.api.post('/invoice/validate/' + data.id, data).then(async (res) => {
          let result = formatFromApi(res.data)
          commit('SET_INVOICE', result)
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async creerAvoir({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        axios.api.post('/invoice/avoir', data).then(async (res) => {
          let result = formatFromApi(res.data)
          commit('SET_INVOICE', result)
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async sendMail({}, data) {
      return new Promise((resolve, reject) => {
        axios.api.post('/invoice/sendmail', data).then(async (res) => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async downloadInvoices({}, ids) {
      return new Promise((resolve, reject) => {
        axios.api.post('/invoice/multi-download', ids).then(async (res) => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async removeInvoice(_, id) {
      return await new Promise((resolve, reject) => {
        axios.api.delete('/invoice/' + id,).then(res => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async reportInvoice({ commit }, data) {
      return await new Promise((resolve, reject) => {
        axios.api.post('/invoice/' + data.id + '/report').then(res => {
          let invoice = formatFromApi(res.data)
          commit('UPDATE_INVOICE', invoice)
          resolve(invoice)
        }).catch((e) => {
          reject(e)
        })
      })
    }
  }
}
