import axios from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
  },
  mutations: {},
  actions: {
    // eslint-disable-next-line
    async uploadFile({ commit }, data) {
      let config = null
      if (data.progressFunction) {
        config = {
          onUploadProgress: data.progressFunction
        }
        data = data.data
      }
      return await axios.api.post('/files/upload', data, config).then(res => {
        return res.data
      }).catch((err) => {
        return Promise.reject(err)
      })
    },
    // eslint-disable-next-line no-unused-vars
    async deleteFile({ commit }, data) {
      return await axios.api.delete('/files/single/' + data.id).then(res => {
        return Promise.resolve(res.data)
      }).catch((err) => {
        return Promise.reject(err)
      })
    },
    // eslint-disable-next-line no-unused-vars
    async deleteManyFiles({ commit }, data) {
      return await axios.api.delete('/files/many', { data }).then(res => {
        return Promise.resolve(res.data)
      }).catch((err) => {
        return Promise.reject(err)
      })
    },
    // eslint-disable-next-line
    async downloadFile({ commit }, data) {
      return await axios.api.post('/files/download', data).then(res => {
        return res.data
      }).catch((err) => {
        return Promise.reject(err)
      })
    },
    // eslint-disable-next-line
    async updateFileData({ commit }, data) {
      return await axios.api.put('/files', data).then(res => {
        return res.data
      }).catch((err) => {
        return Promise.reject(err)
      })
    }
  },
}
