import axios from 'axios'
import {store} from "@/store";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false
});

api.interceptors.request.use(function (config) {
  // const cookieHeader = localStorage.getItem('cookie_header')
  // if (cookieHeader) {
  //   config.headers['set-cookie'] = cookieHeader
  // }
  const token = localStorage.getItem('access_token')
  if (token && !config.url.includes('auth/refresh')) {
    config.headers.Authorization = `Bearer ${token}`
  }
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

api.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // if ((response.status >= 200 && response.status < 300) && response.config.url.includes('auth/login')) {
    //   console.log('response', response)
    //   console.log(response.headers["set-cookie"])
    //   localStorage.setItem('cookie_header', response.headers["set-cookie"] ?? '')
    // }
    return response;
  }, async function (error) {
    const originalRequest = error.response.config
    // If refresh token did 401 error we can clear the user data
    if (
        error.response.status === 401 &&
        originalRequest.url.includes('auth/refresh')
    ) {
      // clear registered data of the user
      // await store.dispatch('auth/clearMe')
      return Promise.reject(error)
    } else if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      // set the access token response in the local storage
      await store.dispatch('auth/refresh')
      return api(originalRequest)
  } else {
      return Promise.reject(error)
  }}
);

export default {
  api
};
