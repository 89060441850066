import axios from '@/plugins/axios'
import { formatFromApi, formatToApi } from '@/utils/ClientUtil'
// todo: refactor Contacts

export default {
  namespaced: true,

  state: {
    clients: []
  },
  mutations: {
    SET_CLIENTS(state, value) {
      state.clients = value
    }
  },
  getters: {
    getClients (state) {
      return state.clients
    },
  },
  actions: {
    // eslint-disable-next-line
    async getAllClients({ commit }) {
      return new Promise((resolve, reject) => {
        axios.api.get('/client').then(res => {
          let results = res.data.map((c) => formatFromApi(c))
          commit('SET_CLIENTS', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async getAllFilteredClients({ commit }, input) {
      return new Promise((resolve, reject) => {
        axios.api.get('/client/getFiltered?input=' + input).then(res => {
          let results = res.data.map((c) => formatFromApi(c))
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line
    async saveClient({ commit }, data) {
      data = formatToApi(data)
      if (data.id) {
        return new Promise((resolve, reject) => {
          axios.api.put('/client', data).then(res => {
            resolve(formatFromApi(res.data))
          }).catch((error) => {
            reject(error)
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          axios.api.post('/client', data).then(res => {
            resolve(formatFromApi(res.data))
          }).catch((error) => {
            reject(error)
          })
        })
      }
    },

    async importSourceData (store, data) {
      const payload = {
        clients: data
      }
      return new Promise((resolve, reject) => {
        axios.api.post('/client/import/source-society-clients', payload).then(async (res) => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  }
}
