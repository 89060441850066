export default {
  namespaced: true,

  state: {
    selected: null,
    sideContent: {
      isVisible: false,
      profil: {
        isVisible: false,
        fullname: '',
        picture: null
      },
      currentPageTitle: '',
      subItems: [],
    },
    independentIdsFilter: null,
    activeItem: 0
  },
  mutations: {
    setSelected (state, selected) {
      state.selected = selected
    },
    setSideContent (state, data) {
      state.sideContent = data
    },
    updateSideContent (state, data) {
      state.sideContent = {
        ...state.sideContent,
        ...data
      }
    },
    setActiveItemSideContent (state, index) {
      state.activeItem = index
    },
    setIndependentIdsFilter (state, data) {
      state.independentIdsFilter = data
    }
  },
  getters: {
    getSelected (state) {
      return state.selected
    }
  },
  actions: {
    updateSideContent (store, data) {
      const { update, ...sideContent } = data
      if (update) {
        store.commit('updateSideContent', sideContent)
      } else {
        store.commit('setSideContent', sideContent)
      }
    },
    changeMenu (store, data) {
      store.commit('setSelected', data)
    },
    updateActiveItemSideContent (store, index) {
      store.commit('setActiveItemSideContent', index)
    },
    updateIndependentIdsFilter ({ commit }, data) {
      commit('setIndependentIdsFilter', data)
    }
  }
}
