<template>
  <div v-if="sideContent.isVisible" style="background-color: #FFFFFF2E; border-bottom-left-radius: 30px; border-top-left-radius: 30px; padding-right: 30px"
    class="flex-container non-scrollable">
    <div class="flex flex-column p-4 overflow-y-auto flex-shrink-0 flex-grow-1 md:flex-grow-0" style="width:calc(20rem - 75px);">
      <div class="justify-content-end mb-3 flex lg:hidden">
        <button icon="pi pi-times" class="cursor-pointer text-white appearance-none bg-transparent border-none inline-flex justify-content-center align-items-center border-circle hover:bg-indigo-600 transition-duration-150 transition-colors"
                style="width:2rem;height:2rem"
                v-styleclass="{ selector: '#app-sidebar-10', leaveToClass: 'hidden', leaveActiveClass: 'fadeoutleft' }">
          <i class="pi pi-times text-xl text-indigo-100"></i>
        </button>
      </div>
      <div class="border-round flex-auto">
        <div>
          <div v-if="sideContent.currentPageTitle" class="p-3 font-medium text-2xl text-white mb-3 text-center">{{ $t(sideContent.currentPageTitle) }}</div>
          <ul v-if="sideContent.isVisible && !sideContent.showIndependents && sideContent.subItems && sideContent.subItems.length > 0" class="list-none p-0 m-0 text-white">
            <!--              :class="(index === sideBar.activeItem) ? 'bg-green-500' : 'bg-green-700'"-->
<!--            hover:bg-green-600-->
            <li
              v-for="(subItem, index) in sideContent.subItems.filter(i => i !== null)"
              :key="index"
              class="submenu mb-3 flex align-items-start p-3 transition-duration-150 transition-colors p-ripple"
              :class="isSubItemActive(subItem) ? 'submenu-active' : ''"
              style="border-radius: 12px;"
              v-ripple
              @click="selectSubMenu(index)"
            >
                <i v-if="subItem.icon" :class="subItem.icon" class="text-xl mr-3 "></i>
                <div class="flex flex-column">
                  <span>{{ $t(subItem.label)}}</span>
                </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="sideContent.showIndependents" style="max-height: 80vh" class="scrollable">
      <DataTable :value="getFilteredIndependent" :rowClass="rowClass"
                 class="p-datatable-sm border-round px-1 p-datatable-white" style="font-weight: 500; font-size: 12px;"
                 responsiveLayout="scroll" selection-mode="single" :rowHover="true"
                 @rowSelect="clickPorte">
        <Column field="name" header="">
          <template #body="slotProps">
            <span>{{ `${slotProps.data.last_name}  ` }}</span>
            <br v-if="(slotProps.data.last_name.length + slotProps.data.first_name.length) > 20"/>
            <span class="text-sm">{{ `${slotProps.data.first_name}` }}</span>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import '../App.scss'
import Alert from "@/utils/Alert";
export default {
  name: 'SidebarContent',
  computed: {
    ...mapState({
      sideBar: state => state.sidebar,
      sideContent: state => state.sidebar.sideContent,
      menuSelected: state => state.sidebar.selected,
      activeItem: state => state.sidebar.activeItem
    }),
    ...mapGetters({
      independents: 'independent/getIndependents'
    }),
    getFilteredIndependent () {
      if (this.sideBar?.independentIdsFilter?.length > 0) {
        return this.independents.filter(independent => this.sideBar?.independentIdsFilter.find(id => id === independent.id))
      }
      return this.independents
    }
  },
  created () {
    const routeName = this.$router.currentRoute.value.name
    const nameLinkedRouteItemIndex = this.sideContent.subItems?.findIndex(item => item.route === routeName)
    if (nameLinkedRouteItemIndex !== -1) {
      this.$store.dispatch('sidebar/updateActiveItemSideContent', nameLinkedRouteItemIndex)
    }
  },
  methods: {
    async selectSubMenu (index) {
      if (this.sideContent.subItems[index].route) {
        this.$router.push({ name: this.sideContent.subItems[index].route }) // , path: '/'
      }
      // if (this.sideContent.subItems[index].to) {
      //   this.$router.push(this.sideContent.subItems[index].to)
      // }
    },
    isSubItemActive (subItem) {
      const findRouteName = this.$route.matched.find(route => subItem.route === route.name)
      return findRouteName !== undefined
    },
    rowClass (i) {
      return i.id === this.$route.query.idIndependent ? 'grey-background text-black-alpha-90' : 'invisible-background'
      // return 'invisible-background'
    },
    async clickPorte (i) {
      try {
        await this.$store.dispatch('independent/getOneIndependent', i.data.id)
        this.$router.replace({
          query: {
            ...this.$route.query,
            idIndependent: i.data.id
          }
        })
      } catch (e) {
        Alert.errorMessage(this, )
      }
    }
  }
}
</script>

<style scoped>
.submenu:hover {
  cursor: pointer;
  background-color: rgba(144, 185, 103, 0.65);
}
.submenu-active {
  background-color: #90B967;
}
.icon.status-new {
  background-color: #2E6BAB;
}
.icon {
  border-radius: 20px;
  overflow: hidden;
  padding: 2px;
  color: #fff;
}
.icon.status-ongoing {
  background-color: #97C353;
}
.icon.status-outgoing {
  background-color: #dbdf0c;
}
.icon.status-out {
  background-color: #7C7C7C;
}

</style>
<style>
.grey-background {
  background-color: #DCDCDC !important;
}
.invisible-background {
  background-color: transparent !important;
}
</style>
<style lang="scss" scoped>
::v-deep(.p-datatable-thead) {
  display: none
}
::v-deep(.p-datatable-white .p-selectable-row) {
  color: white;
}
</style>
