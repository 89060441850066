import axios from '@/plugins/axios'
// todo: might need to impl this to keep token between app restart -> https://stackoverflow.com/questions/42277102/cordova-ios-losing-storage-on-app-shut-down
export default {
  namespaced: true,

  state: {
    authInfo: null,
    me: null,
    isLoggedIn: false,
  },
  mutations: {
    SET_ME(state, val) {
      state.me = val
    },
    SET_LOGGED_IN(state, val) {
      state.isLoggedIn = val
    },
    UPDATE_AUTH_INFO (state, data) {
      state.authInfo = data
    }
  },
  getters: {
    getMe (state) {
      return state.me
    }
  },
  actions: {
    getAuthInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.api.get('/auth/info').then(results => {
          commit('UPDATE_AUTH_INFO', results.data)
          resolve(results.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async login({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.post('/auth/login', data).then((res) => {
          localStorage.setItem('access_token', res.data?.access_token)
          localStorage.setItem('refresh_token', res.data?.refresh_token)
          commit('SET_LOGGED_IN', true)
          resolve(res);
        }).catch((e) => {
          commit('SET_LOGGED_IN', false)
          reject(e);
        })
      });
    },
    // eslint-disable-next-line no-unused-vars
    async register({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.post('/auth/register/' + data.sharedId + '/' + data.independentId , data.payload).then((res) => {
          resolve(res)
        }).catch((e) => {
          reject(e);
        })
      });
    },
    // eslint-disable-next-line no-unused-vars
    async reset({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.post('/auth/reset', data).then((res) => {
          resolve(res)
        }).catch((e) => {
          reject(e);
        })
      });
    },
    // eslint-disable-next-line no-unused-vars
    async update({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.post('/auth/update', data).then((res) => {
          resolve(res)
        }).catch((e) => {
          reject(e);
        })
      });
    },
    // eslint-disable-next-line no-unused-vars
    async changePassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.post('/auth/changePassword', data).then((res) => {
          resolve(res.data)
        }).catch((e) => {
          reject(e);
        })
      });
    },
    // eslint-disable-next-line no-unused-vars
    async checkReset({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.post('/auth/checkReset', data).then((res) => {
          resolve(res)
        }).catch((e) => {
          reject(e);
        })
      });
    },
    async refresh({ commit }) {
      return new Promise((resolve, reject) => {
        axios.api.post('/auth/refresh', { refresh_token: localStorage.getItem('refresh_token') ?? '' })
          .then((res) => {
            localStorage.setItem('access_token', res.data?.access_token)
            localStorage.setItem('refresh_token', res.data?.refresh_token)
            commit('SET_LOGGED_IN', true)
            resolve(true);
          })
          .catch((e) => {
            reject(e);
          })
      })
    },
    async clearMe (store) {
      store.commit('SET_LOGGED_IN', false)
      store.commit('SET_ME', null)
    },
    async clearLocalStorage () {
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')
    },
    async logout(store) {
      return new Promise((resolve, reject) => {
        axios.api.post('/auth/logout', { refresh_token: localStorage.getItem('refresh_token') ?? '' })
          .then(async () => {
            await store.dispatch('auth/clearLocalStorage', null, { root: true })
            resolve()
          })
          .catch(async (e) => {
            await store.dispatch('auth/clearLocalStorage', null, { root: true })
            reject(e)
          })
      })
    },
    isAuthenticated(store, data) {
      // serve to prevent at the logout the change of LOGGED_IN which recreate the current components while the current route isn't properly set on login
      const option = {
        ERROR_SET_LOGGED_IN: data.ERROR_SET_LOGGED_IN ?? true
      }
      return new Promise((resolve, reject) => {
        axios.api.get('/auth/me').then(async (res) => {
          if (res.data) {
            const me = res.data
            store.commit('SET_ME', me)
            // prevent from must-have an independent call by the user on each main page
            if (me.role.name === store.rootState.constants.ROLES.INDEPENDENT) {
              await store.dispatch('independent/getOneIndependentByUser', me.id, { root: true })
            }
            store.commit('SET_LOGGED_IN', true)
            resolve(me)
          } else {
            reject(false)
          }
        }).catch((err) => {
          if (option.ERROR_SET_LOGGED_IN) {
            store.commit('SET_LOGGED_IN', false)
          }
          reject(err)
        })
      })
    }
  }
}
