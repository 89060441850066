import axios from '@/plugins/axios'
import { formatFromApi, formatToApi } from '@/utils/FacturefournisseurUtil'

export default {
  namespaced: true,

  state: {
    facturefournisseur: [],
    facturefournisseurs: []
  },
  getters: {
    getFacturefournisseurs (state) {
      return state.facturefournisseurs
    },
    getFacturefournisseur (state) {
      return state.facturefournisseur
    },
  },
  mutations: {
    SET_FACTUREFOURNISSEURS(state, value) {
      state.facturefournisseurs = value
    },
    SET_FACTUREFOURNISSEUR(state, value) {
      state.facturefournisseur = value
    }
  },
  actions: {
    // eslint-disable-next-line
    async getAllFacturefournisseursByIndependent({ commit }, independentId) {
      return new Promise((resolve, reject) => {
        axios.api.get('/facturefournisseur/independent/' + independentId).then(res => {
          let results = res.data.map((ndf) => formatFromApi(ndf))
          commit('SET_FACTUREFOURNISSEURS', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getAllFacturefournisseurs({ commit }) {
      return new Promise((resolve, reject) => {
        axios.api.get('/facturefournisseur').then(res => {
          let results = res.data.map((ndf) => formatFromApi(ndf))
          commit('SET_FACTUREFOURNISSEURS', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createFacturefournisseur({ commit }, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.post('/facturefournisseur', data).then(res => {
          let results = formatFromApi(res.data)
          commit('SET_FACTUREFOURNISSEUR', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteFacturefournisseur(_, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.delete('/facturefournisseur/' + data.id).then(res => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateFacturefournisseur({ commit }, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.put('/facturefournisseur/' + data.id, data).then(res => {
          let results = formatFromApi(res.data)
          commit('SET_FACTUREFOURNISSEUR', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
