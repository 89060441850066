import {FilterService} from "primevue/api";
// Register all the custom filter functions here

export const FilterMatchModeCustom = {
    EQUALS_EXACT: 'EQUALS_EXACT',
    DATE_AFTER_INCLUDED: 'DATE_AFTER_INCLUDED',
    DATE_BEFORE_INCLUDED: 'DATE_BEFORE_INCLUDED',
    NO_FILTER: 'NO_FILTER',
}

export const FilterMatchModeCustomOptions = [
    { value: FilterMatchModeCustom.DATE_AFTER_INCLUDED, label: 'Postérieur à' },
    { value: FilterMatchModeCustom.DATE_BEFORE_INCLUDED, label: 'Antérieur à' },
]

export const registerCustomFilters = () => {
    FilterService.register(FilterMatchModeCustom.DATE_AFTER_INCLUDED, (value, filter) => {
        if (filter === undefined || filter === null) {
            return true;
        }

        if (value === undefined || value === null) {
            return false;
        }

        return (((typeof value) === 'string' ? new Date(value) : value).getTime()) >= filter.getTime();
    });
    FilterService.register(FilterMatchModeCustom.DATE_BEFORE_INCLUDED, (value, filter) => {
        if (filter === undefined || filter === null) {
            return true;
        }

        if (value === undefined || value === null) {
            return false;
        }

        return (((typeof value) === 'string' ? new Date(value) : value).getTime()) <= filter.getTime();
    });
    FilterService.register(FilterMatchModeCustom.EQUALS_EXACT, (value, filter) => {
        // if (filter === undefined || filter === null) {
        //     return true;
        // }
        //
        // if (value === undefined || value === null) {
        //     return false;
        // }

        return value === filter;
    });
    FilterService.register(FilterMatchModeCustom.NO_FILTER, () => {
        return true
    });
}
