import {setDateWithoutHours} from "@/utils/Date";

export const formatFromApi = (depenseI) => {
    let depense = JSON.parse(JSON.stringify(depenseI))
    depense.date = new Date(depense.date)
    return depense
}

export const formatToApi = (depenseI) => {
    let depense = JSON.parse(JSON.stringify(depenseI))
    if (depense.ttc) {
        depense.date = setDateWithoutHours(depense.date)
        depense.ttc = Math.round(depense.ttc)
        depense.ht = Math.round(depense.ht)
        depense.tva = Math.round(depense.tva)
        depense.item?.forEach((i) => {
            i.ttc = Math.round(i.ttc)
            i.ht = Math.round(i.ht)
            i.tva = Math.round(i.tva)
        })
    }
    return depense
}
