import axios from '@/plugins/axios'
const FileSaver = require('file-saver')

export default {
    namespaced: true,

    state: {},
    mutations: {},
    actions: {
        // eslint-disable-next-line no-unused-vars
        generateContract (store, generateContractPayload) {
            const data = {
                electronicallySigned: generateContractPayload.electronicallySigned,
                rhSettings: generateContractPayload.rhSettings,
                header: generateContractPayload.header,
                footer: generateContractPayload.footer,
            }
            if (!generateContractPayload.preview) {
                data.emailPayload = generateContractPayload.emailPayload
            }
            return new Promise((resolve, reject) => {
                axios.api.post('/rh/generate-work-contract/' + generateContractPayload.id + (generateContractPayload.preview ? '?preview=true' : '') + (generateContractPayload.isAmendment ? `${ generateContractPayload.preview ? '&' : '?' }isAmendment=true` : ''), data, { responseType: 'arraybuffer' })
                    .then(async (res) => {
                        const blob = new Blob([res.data], {
                            type: 'application/pdf'
                        });
                        if (!generateContractPayload.preview || generateContractPayload.forceDownload) {
                            // not any special updates needed in the preview case
                            await store.dispatch('independent/getOneIndependentByUser', generateContractPayload.userId, { root: true })
                            FileSaver.saveAs(blob, 'contrat_de_travail.pdf')
                            resolve('SUCCESS')
                        }
                        if (generateContractPayload.preview) {
                            resolve(blob)
                        }
                    }).catch((e) => {
                        reject(e)
                    })
            })
        },

        // eslint-disable-next-line no-unused-vars
        async importContract ({ commit, dispatch }, { id, form, isAmendment, userId }) {
            return new Promise((resolve, reject) => {
                axios.api.post('/rh/import-contract/' + id + (isAmendment ? '?amendment=true' : ''), form, {})
                    .then(async (result) => {
                        await dispatch('independent/getOneIndependentByUser', userId, { root: true })
                        resolve(result)
                    }).catch((e) => {
                        reject(e)
                    })
            })
        },

        async cancelWorkContractSignature(store, payload) {
            return new Promise((resolve, reject) => {
                axios.api.post(`/rh/cancel-work-contract-signature/${payload.signatureRequestId}/document/${payload.documentId}`)
                    .then((res) => {
                        resolve(res.data.data)
                    }).catch((e) => {
                        console.log('cancelContractSignature', e)
                        reject(e)
                    })
            })
        },

        // eslint-disable-next-line no-unused-vars
        generateGeneralConditionsContract ({ commit }) {
            return new Promise((resolve, reject) => {
                axios.api.post('/rh/generate-contract-general-conditions', {}, { responseType: 'arraybuffer' }).then((res) => {
                    const blob = new Blob([res.data], {
                        type: 'application/pdf'
                    });
                    // FileSaver.saveAs(blob, 'amendment.pdf')
                    resolve(blob)
                }).catch((e) => {
                    reject(e)
                })
            })
        },

        // eslint-disable-next-line no-unused-vars
        generateParticularConditionsContract ({ commit }) {
            return new Promise((resolve, reject) => {
                axios.api.post('/rh/generate-contract-particular-conditions', {}, { responseType: 'arraybuffer' }).then((res) => {
                    const blob = new Blob([res.data], {
                        type: 'application/pdf'
                    });
                    // FileSaver.saveAs(blob, 'amendment.pdf')
                    resolve(blob)
                }).catch((e) => {
                    reject(e)
                })
            })
        },

        // eslint-disable-next-line no-unused-vars
        getIndependentUsingCreditCard (store) {
            return new Promise((resolve, reject) => {
                axios.api.get('/rh/independents/credit-card')
                    .then((res) => {
                        resolve(res.data)
                    }).catch((e) => {
                        reject(e)
                    })
            })
        },

        updateIndependentUsingCreditCard (store, { keys, independentPayload, rhSettings }) {
            // data contains settings restaurants tickets, and the independent
            return new Promise((resolve, reject) => {
                axios.api.patch('/rh/independents/credit-card', { keys, independentPayload, rhSettings })
                    .then((res) => {
                        resolve(res.data)
                    }).catch((e) => {
                    reject(e)
                })
            })
        },

        // eslint-disable-next-line no-unused-vars
        updateIndependentCreditCard (store, { independentId, creditCardFee }) {
            return new Promise((resolve, reject) => {
                axios.api.patch(`/rh/${independentId}/credit-card-supplier-invoice`, { creditCardFee })
                    .then((res) => {
                        resolve(res.data)
                    }).catch((e) => {
                    reject(e)
                })
            })
        },

        // eslint-disable-next-line no-unused-vars
        getIndependentUsingRestaurantsTickets (store) {
            return new Promise((resolve, reject) => {
                axios.api.get('/rh/independents/restaurants-tickets')
                    .then((res) => {
                        resolve(res.data)
                    }).catch((e) => {
                        reject(e)
                    })
            })
        },

        updateIndependentUsingRestaurantTickets (store, { keys, independentPayload, rhSettings }) {
            // data contains settings restaurants tickets, and the independent
            return new Promise((resolve, reject) => {
                axios.api.patch('/rh/independents/restaurants-tickets', { keys, independentPayload, rhSettings })
                    .then((res) => {
                        resolve(res.data)
                    }).catch((e) => {
                    reject(e)
                })
            })
        },

        sendRenunciationLetter (store, data) {
            // data contains settings restaurants tickets, and the independent
            return new Promise((resolve, reject) => {
                axios.api.post('/rh/send-renunciation-letter/' + data.id, {})
                    .then((res) => {
                        resolve(res.data)
                    }).catch((e) => {
                        reject(e)
                    })
            })
        }
    }
}
