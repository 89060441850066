const logoutMixin = {
    methods: {
        async logout () {
            try {
                await this.$store.dispatch('auth/logout')
            } catch (e) {
                console.log(e)
            } finally {
                await this.$router.push('/login')
                await this.$store.dispatch('auth/clearMe', null, { root: true })
            }
        }
    }
}

export default logoutMixin
