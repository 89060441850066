<template>
  <div class="text-900 font-bold text-xl mb-1" :class="overloadClass">
    {{ value }}
  </div>
</template>

<script>
export default {
  name: "OuterCardTitle",
  props: {
    overloadClass: {
      type: String,
      default: '',
      required: false
    },
    value: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>

