import axios from '@/plugins/axios'
import {formatDocFromApi} from "@/utils/IndependentUtil";

export default {
    namespaced: true,

    state: {},
    getters: {},
    mutations: {},
    actions: {
        // eslint-disable-next-line no-empty-pattern
        async getOneDocument({}, payload) {
            return new Promise((resolve, reject) => {
                axios.api.get(`/documents/${payload.id}`).then(res => {
                    resolve(res.data)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        // eslint-disable-next-line
        async getAllMissionsContractsByIndependent({ commit }, payload) {
            const config = { params: {} }
            if (payload.fields) config.params.fields = payload.fields
            if (payload.sorts) config.params.sorts = payload.sorts
            return new Promise((resolve, reject) => {
                axios.api.get(`/documents/independent/${payload.id}/missions`, config).then(res => {
                    resolve(res.data.data)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        // eslint-disable-next-line
        async getAllIndependentWorkContracts({ commit }, payload) {
            const config = { params: {} }
            if (payload.fields) config.params.fields = payload.fields
            if (payload.sorts) config.params.sorts = payload.sorts
            return new Promise((resolve, reject) => {
                axios.api.get('/documents/independent/' + payload.id + '/work-contracts', config).then(res => {
                    resolve(res.data.data)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        // eslint-disable-next-line
        async getAllIndependentDocument({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.api.get('/documents/independent/' + payload.id).then(res => {
                    resolve(res.data.data)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        // eslint-disable-next-line
        async getAllIndependentOtherDocument({ commit }, payload) {
            return new Promise((resolve, reject) => {
                axios.api.get('/documents/independent/others/' + payload.id).then(res => {
                    resolve(res.data.data)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        async getIndependentDocumentBySecuritySocialCode(_, data) {
            return new Promise((resolve, reject) => {
                axios.api.get('/documents/independent/security-social-code/' + data?.socialSecurityCode)
                    .then((res) => {
                        resolve(formatDocFromApi(res.data.data))
                    }).catch((e) => {
                        reject(e)
                    })
            })
        },
        async createDocument(store, payload) {
            return new Promise((resolve, reject) => {
                axios.api.post('/documents/independent/' + payload.id, payload.form, {})
                    .then((res) => {
                        resolve(res.data)
                    }).catch((e) => {
                        reject(e)
                    })
            })
        },
        async updateDocument(store, payload) {
            return new Promise((resolve, reject) => {
                axios.api.put('/documents/' + payload.id, payload.form, {})
                    .then((res) => {
                        resolve(res.data)
                    }).catch((e) => {
                        reject(e)
                    })
            })
        },
        async deleteDocument(store, payload) {
            return new Promise((resolve, reject) => {
                axios.api.delete('/documents/' + payload.id)
                    .then((res) => {
                        resolve(res.data.data)
                    }).catch((e) => {
                        reject(e)
                    })
            })
        },
        cancelContractSignature(store, payload) {
            return new Promise((resolve, reject) => {
                axios.api.post(`/documents/cancel-contract-signature/${payload.signatureRequestId}/document/${payload.documentId}`)
                    .then((res) => {
                        resolve(res.data.data)
                    }).catch((e) => {
                        console.log('cancelContractSignature', e)
                        reject(e)
                    })
            })
        }
    }
}
