export const availableLocale = [
    {
        text: "English",
        value: "en",
        img: require("@/assets/images/flags/united-states.svg"),
    },
    {
        text: "Français",
        value: "fr",
        img: require("@/assets/images/flags/france.svg"),
    },
];
export default {
    locale: "fr",
};
