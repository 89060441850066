import * as mutationTypes from '../mutation-types';
import locale from "@/config/locale";

import { initI18n } from '@/i18n/index';

const i18n = initI18n();

export const state = locale;
export const getters = {
    locale: state => state.locale,
};

export const mutations = {
    [mutationTypes.SET_LOCALE]: (state, payload) => {
        state.locale = payload !== undefined ? payload: 'fr';
        i18n.loadLanguageAsync(state.locale);
    }
};

export const actions = {
    setLocale: ({ commit }, payload) => {
        localStorage.setItem('lang', payload)
        commit(mutationTypes.SET_LOCALE, payload)
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
};
