<template>
  <div class="absolute bottom-0 flex flex-row w-full align-items-center justify-content-center px-0 m-0 gap-1 mb-2">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="flex justify-content-start align-items-center flex-row"
      :class="{
        'border-circle mb-2 shadow-1':item.accent,
        'shadow-3':menuSelected === item.name && item.accent,
        'bg-primary': menuSelected !== item.name && item.accent,
      }"
      :style="menuSelected === item.name && item.accent ? 'background-color: #2cc798' : ''"
      @click="selectMenu(item)">
      <a
          class="flex align-items-center cursor-pointer mx-0 justify-content-center w-4rem h-4rem
          text-white pointer-events-none"
          :class="{}"
      >
        <div
          class="flex text-2xl bg-"
          :class="{
            'text-primary': menuSelected === item.name && !item.accent,
            'text-white':item.accent,
            'w-3rem h-3rem border-circle bg-white align-items-center justify-content-center shadow-2': menuSelected === item.name && !item.accent
          }"
        >
          <i :class="item.icon"/>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import MobileNavigationItemsIndependent from "@/config/mobile-navigation-items-independent";
import {mapState} from "vuex";

export default {
  name: "AppBottomBar",
  computed: {
    ...mapState({
      menuSelected: state => state.sidebar.selected,
      constants: state => state.constants
    }),
    items () {
      return MobileNavigationItemsIndependent
    }
  },
  methods: {
    selectMenu (item) {
      if (!item.name) {
        return
      }
      if (this.menuSelected === item.name) {
        // return this.$router.go() // todo: no need to hard refresh too long
        this.$store.dispatch('misc/updateSameRouteClickedFlag', false)
        process.nextTick(() => {
          this.$store.dispatch('misc/updateSameRouteClickedFlag', true)
        })
        return
      }
      if (item.to) {
        this.$store.dispatch('sidebar/changeMenu', item.name)
        this.$store.dispatch('misc/updateSameRouteClickedFlag', false)
        this.$router.push({ name: item.to })
      }
    }
  }
}
</script>

<style scoped>

</style>
