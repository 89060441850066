import axios from '@/plugins/axios'
import { formatFromApi } from '@/utils/KlippaUtil'

export default {
  namespaced: true,

  actions: {
    async getOcr(_, data) {
      return new Promise((resolve, reject) => {
        axios.api.post('/ocr', data).then((res) => {
          let result = formatFromApi(res.data)
          resolve(result)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
