import { formatFromApi as serviceFormatFromApi } from '@/utils/ServiceUtil'
import {setDateWithoutHours} from "@/utils/Date";

export const formatFromApi = (invoiceI) => {
    let invoice = JSON.parse(JSON.stringify(invoiceI))

    if (invoice.ttc) {
        invoice.ttc = invoice.ttc / 100
    }

    invoice.date = new Date(invoice.date)
    invoice.moisFacturation = new Date(invoice.date.getFullYear(), invoice.date.getMonth())

    invoice.month = invoice.date.toLocaleDateString('fr-FR', {month: 'long'})
    invoice.year = invoice.date.getFullYear()

    if (invoice.payment_date) {
        invoice.payment_date = new Date(invoice.payment_date)
    }

    if (invoice.sending_date) {
        invoice.sending_date = new Date(invoice.sending_date)
    }

    if (invoice.last_notification_date) {
        invoice.last_notification_date = new Date(invoice.last_notification_date)
    }

    if (invoice.services) {
        invoice.services = invoice.services.map((s) => serviceFormatFromApi(s))
    }

    if (invoice.settlementPeriod) {
        invoice.settlementPeriod = new Date(invoice.settlementPeriod)
    }

    if (invoice.ttc !== null && invoice.tva !== null) {
        invoice.ht = invoice.ttc / (1 + invoice.tva)
    } else {
        invoice.ht = invoice.ttc
    }

    if (invoice.paid_amount) {
        invoice.paid_amount = invoice.paid_amount / 100
    }

    return invoice
}

export const formatToApi = (invoiceI) => {
    let invoice = JSON.parse(JSON.stringify(invoiceI))

    if (invoice.ttc) {
        invoice.ttc = Math.round(invoice.ttc * 100)
    }
    if (invoice.paid_amount) {
        invoice.paid_amount = Math.round(invoice.paid_amount * 100)
    }
    if (invoice.currency) {
        if (invoice.currency.code) {
            invoice.currency = invoice.currency.code
        }
    }
    if (invoice.date) {
        invoice.date = invoice.date ? setDateWithoutHours(invoice.date) : null
    }
    if (invoice.payment_date) {
        invoice.payment_date = invoice.payment_date ? setDateWithoutHours(invoice.payment_date) : null
    }
    if (invoice.settlementPeriod) {
        invoice.settlementPeriod = invoice.settlementPeriod ? setDateWithoutHours(invoice.settlementPeriod) : null
    }

    return invoice
}
