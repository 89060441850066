<template>
  <Dialog v-model:visible="dialogActive" class="p-4 documentDialog" content-class="dialog-content" close-on-escape closable modal @update:visible="resetFields">
    <template #header>
      <p></p>
    </template>
    <prospect-helper v-if="prospectMetadata !== null" :prospect-metadata="prospectMetadata"/>
    <div class="mt-2">
      <h3 class="mb-2">{{ $t('contactTitle') }}</h3>
      <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid p-2">
        <div>
<!--          <Dropdown v-model="type" :options="constants.data.contactOptions" :option-label="(val) => $t(val)"></Dropdown>-->

          <label for="firstName" :class="{'p-error':v$.firstName.$invalid && submitted}" class="block text-900 font-medium mb-2"> {{ $t('contactFirstName') }} *</label>
          <InputText id="firstName" v-model="v$.firstName.$model" type="text" :class="{'p-invalid':v$.firstName.$invalid && submitted}" class="w-full mb-3 p-3" />
          <div v-if="v$.firstName.$error && submitted" class="mb-2">
            <span id="firstName-error" v-for="(error, index) of v$.firstName.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
          </div>
          <label for="lastName" :class="{'p-error':v$.lastName.$invalid && submitted}" class="block text-900 font-medium mb-2"> {{ $t('contactLastName') }} *</label>
          <InputText id="lastName" v-model="v$.lastName.$model" type="text" :class="{'p-invalid':v$.lastName.$invalid && submitted}" class="w-full mb-3 p-3" />
          <div v-if="v$.lastName.$error && submitted" class="mb-2">
            <span id="lastName-error" v-for="(error, index) of v$.lastName.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
          </div>

          <label for="phone" class="block text-900 font-medium mb-2"> {{ $t('contactPhone') }}</label>
          <InputMask id="phone" mask="99-99-99-99-99" v-model="phone" class="w-full mb-3 p-3"/>

          <label for="email" :class="{'p-error':v$.email.$invalid && submitted}" class="block text-900 font-medium mb-2"> {{ $t('contactEmail') }} *</label>
          <InputText id="email" v-model="v$.email.$model" type="text" :class="{'p-invalid':v$.email.$invalid && submitted}" class="w-full mb-3 p-3" />
          <div v-if="v$.email.$error && submitted" class="mb-2">
            <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
              <InlineMessage>{{error.$message}}</InlineMessage>
            </span>
          </div>

          <!--  todo: might be available only for the signatory, if undefined should warn on the generation  -->
          <label for="position" class="block text-900 font-medium mb-2"> {{ $t('contactPosition') }}</label>
          <InputText id="position" v-model="position" type="text" class="w-full mb-3 p-3" />

        </div>
        <div class="flex flex-row-reverse mt-2">
          <div class="flex col-6 justify-content-between">
            <Button :label="$t('save')" class="mr-2 p-button-outlined" @click="saveCreatedClient"/>
            <Button :label="$t('validate')" type="submit" class="w-full p-3"></Button>
          </div>
        </div>
      </form>
    </div>
  </Dialog>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import Alert from "@/utils/Alert";
import ProspectHelper from "@/components/Client/ProspectHelper";
import {REGEX_VALIDATORS} from "@/utils/Misc";
import {mapState} from "vuex";
import RequestErrorManager from "@/utils/RequestErrorManager";

export default {
  components: {ProspectHelper},
  emits: ["closeDialog", "submitContact", 'saved'],
  setup: () => ({ v$: useVuelidate() }),
  name: "DialogCrudContact",
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
    prospectMetadata: {
      type: Object,
      default: null,
    },
    clientId: {
      type: String,
      default: null,
    },
    clientData: {
      type: Object,
      default: null,
    },
    contactType: {
      type: String,
      required: true
    }
  },
  watch: {
    isActive (value) {
      // dialog is created at the same time of the parent component, created or mounted are not called if changing hide
      if (value) {
        this.initFields()
      }
      this.dialogActive = value
    }
  },
  data () {
    return {
      dialogActive: false,
      formValidated: false,

      id: null,
      type: '',
      firstName: '',
      lastName: '',
      phone: null,
      email: '',
      position: null,

      submitted: false
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants
    })
  },
  validations() {
    return {
      firstName: {
        required: helpers.withMessage(this.$t('documentEmailSubjectRequired'), required),
      },
      lastName: {
        required: helpers.withMessage(this.$t('documentEmailSubjectRequired'), required),
      },
      email: {
        required: helpers.withMessage(this.$t('documentEmailSubjectRequired'), required),
      }
    }
  },
  methods: {
    initFields () {
      this.resetFields()
      if (this.clientData !== null) {
        this.id = this.clientData.id
        this.type = this.clientData.type
        this.firstName = this.clientData.first_name
        this.lastName = this.clientData.last_name
        this.email = this.clientData.email
        this.phone = this.clientData.phone
        this.position = this.clientData.position
      }
    },
    closeDialog () {
      this.resetFields()
      this.$emit('closeDialog')
    },
    async saveCreatedClient () {
      try {
        const clientSavedPayload = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          phone: this.phone,
          position: this.position,
          client: this.clientId
        }
        if (this.id) {
          clientSavedPayload.id = this.id
        }
        let clientSaved = {}
        clientSaved = await this.$store.dispatch('contact/saveContact', clientSavedPayload)
        if (clientSaved.id) {
          this.id = clientSaved.id
        }
        this.$emit('saved', clientSavedPayload)
        return clientSaved
      } catch (e) {
        RequestErrorManager.error(this, e)
        return null
      }
    },
    async handleSubmit (isFormValid) {
      this.submitted = true

      if (!(REGEX_VALIDATORS.EMAIL.test(this.email))) {
        Alert.errorMessage(this, 'invalidEmail')
        return
      }

      if (!isFormValid) {
        Alert.errorMessage(this, 'fieldRequired', {symbole: '(*)'})
        return
      }

      const clientSaved = await this.saveCreatedClient()
      // send the id back in all cases
      const payload = {
        type: this.contactType,
        clientData: clientSaved
      }
      this.$emit('submitContact', payload)
    },
    resetFields () {
      this.id = null
      this.type = ''
      this.firstName = ''
      this.lastName = ''
      this.email = ''
      this.phone = null
      this.position = null

      this.submitted = false
    }
  }
}
</script>

<style>
.documentDialog {
  width: 800px;
  background-color: white;
}
.documentDialog .p-dialog-header {
  background-color: var(--surface-ground);
}
</style>
