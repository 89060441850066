export default {
    namespaced: true,
    // todo: add all the hard coded formed array of options that can be initialized in App.vue
    state: {
        SYNTEC_STATUS: [],
        independentOptions: [],
        payOptions: [],
        ndfOptions: [],
        subscriptionTransportPeriodicityOptions: [],
        facturationOptions: [],
        contractTypeOptions: [],
        documentOptions: [],
        personalSituationOptions: [],
        medicalVisitOptions: [],
        mutualOptions: [],
        IBANOptions: [],
        contractOption: [],
        defaultBaseOptions: [],
        baseOptions: [],
        allDocuments: [],
        contactOptions: [],
        negotiatedRateUnitOptions: [],
        renewalOptions: [],
        serviceOptions: [],
        ndfTypeOptions: [],
    },
    mutations: {},
    actions: {},
}
