import axios from '@/plugins/axios'
import { formatFromApi, formatToApi } from '@/utils/PayUtil'
import {formatQueryArray} from "@/utils/Misc";
import {formatCsvFile} from "@/utils/ExportFileType";

export default {
  namespaced: true,

  state: {
    pay: undefined,
    pays: [],
  },
  getters: {
    getPay (state) {
      return state.pay
    },
    getSmLunch (state) {
      return state.pay.jsonPay.smLunch
    },
    getLgLunch (state) {
      return state.pay.jsonPay.lgLunch
    }
  },
  mutations: {
    SET_PAY_SM_LUNCH (state, value) {
      state.pay.jsonPay.smLunch = value
    },
    SET_PAY_LG_LUNCH (state, value) {
      state.pay.jsonPay.lgLunch = value
    },
    SET_PAY(state, value) {
      state.pay = value
    },
    SET_PAYS(state, value) {
      state.pays = value
    },
    UPDATE_PAY (state, value) {
      let index = state.pays.findIndex((pay) => { return pay.id === value.id })
      if (index !== -1) {
        const previousValue = state.pays[index]
        state.pays.splice(index, 1, {
          ...previousValue,
          ...value
        })
      }
    },
    REPLACE_SOME_PAYS(state, payValues) {
      let payIndex = -1
      payValues.forEach((payValue) => {
        payIndex = state.pays.findIndex((pay) => pay.id === payValue.id)
        if (payIndex !== -1) {
          state.pays.splice(payIndex, 1, payValue)
        }
      })
    },
    UPDATE_TOTAL_RESTAURANT_TICKETS(state, { restaurantTickets, restaurantTicketsReduction }) {
      let restaurantTicketsCount = restaurantTickets
      // restaurant tickets acquired
      state.pay.jsonInfo.obtainedTickets = restaurantTicketsCount

      restaurantTicketsCount -= restaurantTicketsReduction
      if (state.pay && state.pay.jsonPay.smLunch) {
        restaurantTicketsCount -= state.pay.jsonPay.smLunch
      }
      if (state.pay && state.pay.jsonPay.lgLunch) {
        restaurantTicketsCount -= state.pay.jsonPay.lgLunch
      }
      if (restaurantTicketsCount < 0) {
        restaurantTicketsCount = 0
      }
      // restaurant tickets total
      state.pay.jsonInfo.ticket = restaurantTicketsCount
    },
    UPDATE_FEE(state, value) {
      let fee = value
      if (fee < 0) {
        fee = 0
      }
      state.pay.jsonInfo.forfait = fee
    },
    SET_TIME_SHEET_VALUE (state, data) {
      const payCraIndex = state.pay.cras.findIndex(cra => cra.mission === data.missionId)
      let timesheetValue = 0
      if (payCraIndex !== -1) {
        timesheetValue = state.pay.cras[payCraIndex].timesheet[0].value[data.indexDay]
        state.pay.cras[payCraIndex].timesheet[0].value.splice(data.indexDay, 1, timesheetValue ? (timesheetValue + 1) % 4 : 1)
      }
    }
  },
  actions: {
    async getAllPays({ commit }, { status }) {
      const query = formatQueryArray('status', status)
      return new Promise((resolve, reject) => {
        axios.api.get(`/pay${query.length > 0 ? `?${query}` : ''}`).then((res) => {
          let results = res.data.map((m) => formatFromApi(m))
          commit('SET_PAYS', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getAllPaysByIndependent({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get(`/pay/independent/${data}`).then((res) => {
          let results = res.data.map((m) => formatFromApi(m))
          commit('SET_PAYS', results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getOnePay({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get('/pay/' + data.idIndep + '/' + data.year + '/' + data.month).then((res) => {
          const result = formatFromApi(res.data)
          commit('SET_PAY', result)
          resolve(result)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    async getLastInvalidatePays({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.get('/pay/invalidates/' + data.independentId + '/' + data.month + '/' + data.year).then((res) => {
          const result = res.data
          resolve(result)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createPay({ commit }, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.post('/pay/', data).then((res) => {
          const result = formatFromApi(res.data)
          commit('SET_PAY', result)
          resolve(result)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updatePay({ commit }, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.put('/pay/' + data.id, data).then((res) => {
          const result = formatFromApi(res.data)
          commit('SET_PAY', result)
          resolve(result)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    async isImportPayEnabled ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.post('/pay/import-pay-enable', data).then((res) => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    async checkIndependentPay ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.post('/pay/check-independent-pay', data).then((res) => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    async getDeclaration ({ commit }, { year, month, socialSecurityCode }) {
      return new Promise((resolve, reject) => {
        axios.api.get(`/pay/declaration?year=${year}&month=${month}&socialSecurityCode=${socialSecurityCode}`).then((res) => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    async importIndependentPay ({ commit }, data) {
      data = formatToApi(data)
      return new Promise((resolve, reject) => {
        axios.api.post('/pay/import-independent-pay', data).then((res) => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    async forcePaysDeclaration ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.api.post('/pay/pay-declaration').then((res) => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    async importPayrollsTemplate ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.api.post('/pay/download-payrolls-import-template')
          .then(async (res) => {
            await formatCsvFile(res.data.data.data)
            resolve('SUCCESS')
          }).catch((e) => {
            reject(e)
          })
      })
    },
    async notifyIndependent ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.api.post(`/pay/notify-independent/${data.independentId}/${data.payDeclarationId}`).then((res) => {
          const result = formatFromApi(res.data)
          commit('UPDATE_PAY', result)
          resolve(result)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    setSmLunch (store, payload) {
      store.commit('SET_PAY_SM_LUNCH', payload)
      store.commit('UPDATE_TOTAL_RESTAURANT_TICKETS', {
        restaurantTickets: store.state.pay.jsonInfo.obtainedTickets,
        restaurantTicketsReduction: store.rootState.depense?.depenses?.length
      })
    },
    setLgLunch (store, payload) {
      store.commit('SET_PAY_LG_LUNCH', payload)
      store.commit('UPDATE_TOTAL_RESTAURANT_TICKETS', {
        restaurantTickets: store.state.pay.jsonInfo.obtainedTickets,
        restaurantTicketsReduction: store.rootState.depense?.depenses?.length
      })
    },
    async updateTotalRestaurantTickets (store, payload) {
      store.commit('UPDATE_TOTAL_RESTAURANT_TICKETS', {
        restaurantTickets: payload,
        restaurantTicketsReduction: store.rootState.depense?.depenses?.length
      })
    },
    async updateForfait (store, payload) {
      store.commit('UPDATE_FEE', payload)
    },
    setPay ({ commit }, pay) {
      commit('SET_PAY', pay)
    },
    setTimeSheetValue ({ commit }, data) {
      commit('SET_TIME_SHEET_VALUE', data)
    },
    replaceSomePays ({ commit }, data) {
      commit('REPLACE_SOME_PAYS', data)
    }
  },
}
