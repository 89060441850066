import {formatFromApi as contactFormatFromApi} from '@/utils/ContactUtil'
import {formatFromApi as clientFormatFromApi} from '@/utils/ClientUtil'
import {setDateWithoutHours} from "@/utils/Date";
// , formatFromApi as independentFormatFromApi

export const formatFromApi = (missionI) => {
    if (typeof missionI !== 'object') return missionI
    let mission = JSON.parse(JSON.stringify(missionI))

    if (mission.jsonMission) {
        mission.jsonMission.startingDate = mission.jsonMission.startingDate ? new Date(mission.jsonMission.startingDate) : null
        mission.jsonMission.initialEndingDate = mission.jsonMission.initialEndingDate ? new Date(mission.jsonMission.initialEndingDate) : null
    }

    if (mission.jsonCost) {
        mission.jsonCost.cap = typeof mission.jsonCost.cap === 'number' ? mission.jsonCost.cap / 100 : null
        mission.jsonCost.teleworkingRate = mission.jsonCost.teleworkingRate ? mission.jsonCost.teleworkingRate / 100 : null
        if (mission.jsonCost.negotiatedRate && mission.jsonCost.negotiatedRate.value) {
            mission.jsonCost.negotiatedRate.value = mission.jsonCost.negotiatedRate.value / 100
        }
        if (mission.jsonCost.negotiatedRateValues) {
            mission.jsonCost.negotiatedRateValues = mission.jsonCost.negotiatedRateValues
                .map((negotiatedRate) => {
                    return {
                        ...negotiatedRate,
                        effectiveDate: new Date(negotiatedRate.effectiveDate),
                        costOnSite: isNaN(negotiatedRate.costOnSite) ? negotiatedRate.costOnSite : negotiatedRate.costOnSite / 100,
                        costOnRemote: isNaN(negotiatedRate.costOnRemote) ? negotiatedRate.costOnRemote : negotiatedRate.costOnRemote / 100
                    }
                })
        }
        if (mission.jsonCost.managementFeeValues) {
            mission.jsonCost.managementFeeValues = mission.jsonCost.managementFeeValues
                .map((negotiatedRate) => {
                    return {
                        ...negotiatedRate,
                        effectiveDate: new Date(negotiatedRate.effectiveDate),
                        value: isNaN(negotiatedRate.value) ? negotiatedRate.value : negotiatedRate.value,
                        cap: typeof negotiatedRate.cap === 'number' ? negotiatedRate.cap / 100 : null
                    }
                })
        }
    }

    if (mission.contact) {
        mission.contact = contactFormatFromApi(mission.contact)
    }
    if (mission.signatory) {
        mission.signatory = contactFormatFromApi(mission.signatory)
    }
    if (mission.billingContact) {
        mission.billingContact = contactFormatFromApi(mission.billingContact)
    }

    if (mission.clientContract) {
        mission.clientContract = clientFormatFromApi(mission.clientContract)
    }

    if (mission.commercial) {
        mission.commercial.fullName = mission.commercial.first_name + ' ' + mission.commercial.last_name
    }

    return mission
}

export const formatToApi = (missionI) => {
    let mission = JSON.parse(JSON.stringify(missionI))
    if (mission.jsonCost) {
        if (typeof mission.jsonCost.cap === 'number') {
            mission.jsonCost.cap = Math.round(mission.jsonCost.cap * 100)
        } else {
            mission.jsonCost.cap = null
        }
        if (mission.jsonCost.teleworkingRate) {
            mission.jsonCost.teleworkingRate = Math.round(mission.jsonCost.teleworkingRate * 100)
        }
        if (mission.jsonCost.negotiatedRate && mission.jsonCost.negotiatedRate.value) {
            mission.jsonCost.negotiatedRate.value = Math.round(mission.jsonCost.negotiatedRate.value * 100)
        }
        if (mission.jsonCost.negotiatedRateValues) {
            mission.jsonCost.negotiatedRateValues = mission.jsonCost.negotiatedRateValues
                .map((negotiatedRate) => {
                    return {
                        ...negotiatedRate,
                        effectiveDate: setDateWithoutHours(new Date(negotiatedRate.effectiveDate)),
                        costOnSite: isNaN(negotiatedRate.costOnSite) ? negotiatedRate.costOnSite : negotiatedRate.costOnSite * 100,
                        costOnRemote: isNaN(negotiatedRate.costOnRemote) ? negotiatedRate.costOnRemote : negotiatedRate.costOnRemote * 100
                    }
                })
        }

        if (mission.jsonCost.managementFeeValues) {
            mission.jsonCost.managementFeeValues = mission.jsonCost.managementFeeValues
                .map((negotiatedRate) => {
                    return {
                        ...negotiatedRate,
                        effectiveDate: setDateWithoutHours(new Date(negotiatedRate.effectiveDate)),
                        value: isNaN(negotiatedRate.value) ? negotiatedRate.value : negotiatedRate.value,
                        cap: typeof negotiatedRate.cap === 'number' ? negotiatedRate.cap * 100 : null
                    }
                })
        }
    }

    if (mission.jsonMission) {
        mission.jsonMission.startingDate = mission.jsonMission.startingDate ? setDateWithoutHours(mission.jsonMission.startingDate) : null
        mission.jsonMission.initialEndingDate = mission.jsonMission.initialEndingDate ? setDateWithoutHours(mission.jsonMission.initialEndingDate) : null
        mission.jsonMission.initialRenewalStartingDate = mission.jsonMission.initialRenewalStartingDate ? setDateWithoutHours(mission.jsonMission.initialRenewalStartingDate) : null
        mission.jsonMission.renewalEndingDate = mission.jsonMission.renewalEndingDate ? setDateWithoutHours(mission.jsonMission.renewalEndingDate) : null
    }

    if (mission.contact) {
        mission.contact = mission.contact.id
    }
    if (mission.signatory) {
        mission.signatory = mission.signatory.id
    }
    if (mission.clientContract) {
        mission.clientContract = mission.clientContract.id
    }

    // prevent from updating all the independent fields, should be in the back
    if (mission.independent) {
        mission.independent = {
            id: mission.independent.id,
            competencesDetails: mission?.independent?.competencesDetails,
            profil: mission?.independent?.profil
        }
    }

    return mission
}
