<template>
  <Dialog
      v-model:visible="dialogActive"
      :breakpoints="{'960px': '75vw', '640px': '90vw'}"
      :style="{width: '50vw'}"
      closable
      :draggable="false"
      modal
      @hide="closeDialog"
  >
    <template #header>
      <div>
        <h3 class="text-black-alpha-90">
          {{
            'Gestion des ' + (mission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE
                ? 'tarifs journaliers' : (mission.jsonCost.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE
                    ? 'tarifs forfaitaires' : 'tarifs horaires'))
          }}
        </h3>
      </div>
    </template>
    <div v-if="!loading">
      <div class="flex flex-row align-items-center justify-content-center mb-3">
        <p class="mb-0 mr-2 w-3 font-bold">Date de mise en effet</p>
        <p class="mb-0 mr-2 w-3 font-bold">
          {{
            mission?.jsonCost?.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE ? 'Tarif de la mission' : 'Tarif sur site'
          }}
        </p>
        <p v-if="mission?.jsonCost?.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.DAILY_AVERAGE_PRICE || mission?.jsonCost?.negotiatedRateUnit === constants.NEGOTIATED_RATE_UNITS.HOURLY_PRICE"
           class="mb-0 w-3 mr-2 font-bold">
          Tarif en télétravail
        </p>
        <p class="w-1">{{ '' }}</p>
      </div>
      <!--   todo: Is the independent can see this ?   -->
      <!--   todo: Bug on the index that is not the index we checked on the save   -->
      <div v-for="(negotiatedRateValue) in getSortedNegotiatedRateValues" :key="negotiatedRateValue.id" class="flex flex-column">
        <negotiated-rate-inputs ref="negotiatedRateInputs" :negotiated-rate-value="negotiatedRateValue" :mission="mission" class="mb-2"
                                :invalid-months="invalidMonths"
                                @deleteNegotiatedRateValue="removeNegotiatedRateValue" @updateNegotiatedRateValue="updateNegotiatedRateValue"
                                @add-invalid-month="addInvalidMonth" @delete-invalid-month="deleteInvalidMonth"/>
      </div>
    </div>
    <div v-else class="overflow-hidden flex">
      <ProgressSpinner></ProgressSpinner>
    </div>
    <template #footer>
      <!--      <Button label="Fermer" class="p-button-text" @click="closeDialog"/>-->
      <Button label="Nouveau tarif" icon="pi pi-plus" :disabled="!mission.jsonMission.startingDate || mission.jsonCost.negotiatedRateValues?.length === 0" @click="addNegotiatedRate"/>
      <Button label="Enregistrer" @click="saveNegotiatedRateValues"/>
    </template>
  </Dialog>
</template>

<script>
import {mapState} from "vuex";
import UtilsDate from "@/utils/Date";
import NegotiatedRateInputs from "@/components/NegotiatedRateInputs.vue";
import {invalidMonthFormat} from "@/utils/NegotiatedRateValueUtils";

export default {
  name: "DialogNegotiatedRateValues",
  components: {NegotiatedRateInputs},
  emits: ['closeDialog', 'updateNegotiatedRateValues'],
  props: {
    isActive: {
      type: Boolean,
      default: false,
      required: true
    },
    mission: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogActive: false,
      loading: true,

      localNegotiatedRateValues: [],
      invalidMonths: []
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants
    }),
    getSortedNegotiatedRateValues() {
      if (this.localNegotiatedRateValues.length === 0) return []
      const computedLocalNegotiatedRateValues = JSON.parse(JSON.stringify(this.localNegotiatedRateValues))
      // sort asc on effectiveDate
      const sortedComputedLocalNegotiatedRateValues = computedLocalNegotiatedRateValues
          .filter(negotiatedRate => negotiatedRate.unit === this.mission.jsonCost.negotiatedRateUnit)
          .sort((a, b) => a.effectiveDate > b.effectiveDate ? -1 : a.effectiveDate < b.effectiveDate ? 1 : 0)
          .map((negotiatedRate) => {
            return {
              ...negotiatedRate,
              effectiveDate: negotiatedRate.effectiveDate ? UtilsDate.getDate(new Date(negotiatedRate.effectiveDate)) : null
            }
          })
      return sortedComputedLocalNegotiatedRateValues
    }
  },
  watch: {
    async isActive(value) {
      if (value) {
        await this.init()
      }
      this.dialogActive = value
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    async init() {
      this.loading = true
      this.invalidMonths = []
      const missionStartingMonth = new Date(this.mission.jsonMission.startingDate).getMonth() + 1
      const missionStartingYear = new Date(this.mission.jsonMission.startingDate).getFullYear()
      if (this.mission.id && missionStartingMonth && missionStartingYear) {
        // consider that the first date is the starting date of the mission
        await this.$store.dispatch('service/getAllServicesByMissionSinceDate', {
          mission: this.mission.id,
          month: missionStartingMonth,
          year: missionStartingYear,
        })
      }

      let id = 1
      if (this.mission.jsonCost?.negotiatedRateValues?.length && this.mission.jsonCost?.negotiatedRateValues?.length > 0) {
        this.localNegotiatedRateValues = JSON.parse(JSON.stringify(this.mission.jsonCost.negotiatedRateValues))
            .map((negotiatedRate) => {
              negotiatedRate.id = id
              ++id
              return negotiatedRate
            })
      }
      this.initInvalidMonths()
      this.loading = false
    },
    initInvalidMonths () {
      if (this.mission.jsonCost.negotiatedRateUnit !== this.constants.NEGOTIATED_RATE_UNITS.FIXED_PRICE
          && this.mission.jsonCost.forfaitFacturationMode !== this.constants.INVOICE_TYPE.MONTHLY) {
        this.invalidMonths = []
        return
      }

      for (let index = 0; index < this.getSortedNegotiatedRateValues.length; index++) {
        if (this.getSortedNegotiatedRateValues[index].effectiveDate === null) continue
        // todo: need to test if the forceAdd condition is "valid"
        this.addInvalidMonth(this.getSortedNegotiatedRateValues[index], this.getSortedNegotiatedRateValues.length === 0)
      }
    },
    addInvalidMonth (negotiatedRateValue, forceAdd = false) {
      if (!negotiatedRateValue.effectiveDate) return
      const effectiveDateMonth = invalidMonthFormat(negotiatedRateValue.effectiveDate)
      if (this.invalidMonths.findIndex(invalidMonth => invalidMonth.monthFormat === effectiveDateMonth) === -1 || forceAdd) {
        this.invalidMonths.push({ id: negotiatedRateValue.id, monthFormat: effectiveDateMonth })
      }
    },
    deleteInvalidMonth (negotiatedRateValueId) {
      // delete the invalidMonth if exist
      if (!negotiatedRateValueId) return
      const foundIndex = this.invalidMonths.findIndex(invalidMonth => invalidMonth.id === negotiatedRateValueId)
      if (foundIndex !== -1) {
        this.invalidMonths.splice(foundIndex, 1)
      }
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
    async saveNegotiatedRateValues() {
      let index = 0
      let isFormCorrect = false
      // eslint-disable-next-line no-unused-vars
      for (const localNegotiatedRateValue in this.getSortedNegotiatedRateValues) {
        // the negotiated values are filtered in the computed get, so we need to exclude them as in the computed to get only the components that are existing
        isFormCorrect = await this.$refs.negotiatedRateInputs[index].onSubmit()
        // if (this.localNegotiatedRateValues[index].unit === this.mission.jsonCost.negotiatedRateUnit) {
        // } else {
        //   isFormCorrect = true
        // }
        // todo: see if it's better to submit all the negotiatedRateInputs ref or only one is ok
        if (!isFormCorrect) {
          return false
        }
        ++index
      }
      // should return all the date as ISO string format
      const payload = this.localNegotiatedRateValues
          .map((negotiatedRateValue) => {
            // eslint-disable-next-line no-unused-vars
            const {id, ...value} = negotiatedRateValue
            return {...value, effectiveDate: negotiatedRateValue.effectiveDate}
          })
      this.$emit('updateNegotiatedRateValues', payload)
    },
    addNegotiatedRate() {
      let lastId = 0
      this.localNegotiatedRateValues.forEach((negotiatedRate) => {
        if (negotiatedRate.id > lastId) {
          lastId = negotiatedRate.id
        }
      })
      ++lastId
      this.localNegotiatedRateValues.push({
        id: lastId,
        effectiveDate: null,
        costOnSite: null,
        costOnRemote: null,
        unit: this.mission.jsonCost.negotiatedRateUnit
      })
    },
    removeNegotiatedRateValue(negotiatedRateId) {
      const foundIndex = this.localNegotiatedRateValues.findIndex((localNegotiatedRate) => localNegotiatedRate.id === negotiatedRateId)
      if (foundIndex === -1) return
      this.localNegotiatedRateValues.splice(foundIndex, 1)
    },
    updateNegotiatedRateValue(negotiatedRateValue) {
      const foundIndex = this.localNegotiatedRateValues.findIndex((localNegotiatedRate) => localNegotiatedRate.id === negotiatedRateValue.id)
      if (foundIndex === -1) return
      this.localNegotiatedRateValues.splice(foundIndex, 1, negotiatedRateValue)
    }
  }
}
</script>

<style scoped>

</style>
