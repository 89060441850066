import { createStore } from 'vuex'

// MODULES
import sidebar from './modules/sidebar'
import auth from './modules/auth'
import mission from './modules/mission'
import pay from './modules/pay'
import commercial from './modules/commercial'
import client from './modules/client'
import contact from './modules/contact'
import independent from './modules/independent'
import simulation from './modules/simulation'
import service from './modules/service'
import invoice from './modules/invoice'
import file from './modules/file'
import translation from './modules/translations'
import ndf from './modules/ndf'
import depense from './modules/depense'
import ikm from './modules/ikm'
import misc from './modules/misc'
import apigouv from "@/store/modules/apigouv"
import vehicle from "@/store/modules/vehicle"
import regularisation from "@/store/modules/regularisation"
import trajet from "@/store/modules/trajet"
import facturefournisseur from "@/store/modules/facturefournisseur";
import bankaccount from "@/store/modules/bankaccount";
import rh from './modules/rh'
import cra from './modules/cra'
import declarationFacturation from "@/store/modules/declarationFacturation";
import klippa from "@/store/modules/klippa";
import constants from "@/store/modules/constants";
import documents from "@/store/modules/documents";
import directusUsers from "@/store/modules/directusUsers";
import publicHolidays from "@/store/modules/publicHolidays";

export const store = createStore({
  modules: {
    sidebar,
    auth,
    mission,
    pay,
    commercial,
    client,
    contact,
    independent,
    simulation,
    service,
    invoice,
    file,
    translation,
    rh,
    ndf,
    depense,
    ikm,
    apigouv,
    vehicle,
    trajet,
    misc,
    facturefournisseur,
    cra,
    declarationFacturation,
    klippa,
    constants,
    bankaccount,
    documents,
    regularisation,
    directusUsers,
    publicHolidays
  },
})
